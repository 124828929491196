<form autocomplete="noAutocomplete" [formGroup]="form">
  <h3 class="fonts-col__heading-3 m-0 mb-20">
    Upload Method <span> (2/{{ getNumberOfSteps() }})</span>
  </h3>
  <ey-radio-group
    class="data-purpose"
    [horizontal]="true"
    [meta]="meta.uploadMethod"
    [values]="values"
    formControlName="uploadMethod"
  ></ey-radio-group>
</form>
