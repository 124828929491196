import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { EyFormBaseComponent } from '../../../shared/components/ey-form-base/ey-base-form-control';
import { ExtensionTypes, FileTypes } from '../../../shared/components/ey-file-upload/ey-file-upload.service';
import { Observable } from 'rxjs';
import { FileUploadResult, FileUploadService } from '../../../core/file-upload.service';
import { EyFileUploadComponent, FileListItem } from '../../../shared/components/ey-file-upload/ey-file-upload.component';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { UploadMethods } from '../upload-method/upload-method.component';
import { saveAs as importedSaveAs } from 'file-saver';
import { BulkUploadService } from '../bulk-upload.service';
import { DataPurposes } from '../data-purpose/data-purpose.component';
import { BulkUploadModalService } from '../bulk-upload-modal.service';

export const TEMPLATE_FILE_NAME = 'Empowerment Bulk Upload template - %PROJ_NAME%.xlsx';
@Component({
  selector: 'app-upload-your-data',
  templateUrl: './upload-your-data.component.html',
  styleUrls: ['./upload-your-data.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadYourDataComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UploadYourDataComponent),
      multi: true,
    },
  ],
})
export class UploadYourDataComponent extends EyFormBaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() selectedUploadMethod: UploadMethods;
  @Input() projectID: string;
  @Input() projectName: string;
  @Input() hasError = false;
  dataPurpose: DataPurposes;
  useOurTemplate = UploadMethods.UseOurTemplate;
  docType: ExtensionTypes = ExtensionTypes.bulkUploadFile;
  fileExtension = [FileTypes.excel];
  form: UntypedFormGroup;
  destroy$ = new Observable<boolean>();
  uploadedFiles: FileUploadResult[] = [];
  @ViewChild(EyFileUploadComponent) fileUploadCmp: EyFileUploadComponent;

  constructor(
    private fb: UntypedFormBuilder,
    private el: ElementRef,
    private fileUploadService: FileUploadService,
    private bulkUploadService: BulkUploadService,
    private bulkUploadModalService: BulkUploadModalService,
  ) {
    super();
  }
  getNumberOfSteps(): number {
    return this.selectedUploadMethod === UploadMethods.UseMapping ? 4 : 3;
  }

  ngOnInit(): void {
    this.bulkUploadModalService.dataPurpose.subscribe((dataPurpose) => {
      this.dataPurpose = dataPurpose.dataPurpose;
    });
    this.form = this.fb.group({}, { updateOn: 'blur' });
  }

  writeValue(val: any): void {
    if (val) {
      this.form.patchValue(val, { emitEvent: false });
    }
  }

  public onTouched: () => void = () => {};
  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { subformerror: 'Upload Your Data Form Error!' };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.form.statusChanges.subscribe(fn);
  }

  markAsTouched(): void {
    this.form.markAllAsTouched();
    this.focusOnError(this.form, this.el);
  }

  ngOnDestroy(): void {}

  upload(file: FileListItem): void {
    const fd = this.fileUploadService.createFormData(file.file, file.fileId);
    this.fileUploadService
      .postFormData(fd)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (fupResult) => {
          fupResult.visible = true;
          this.uploadedFiles.push(fupResult);
          this.fileUploadCmp.markFileAsUploaded(fupResult);
          this.hasError = false;
        },
        (error) => {
          console.error(error);
        },
      );
  }

  deleteFile(file: FileListItem): void {
    this.uploadedFiles = this.uploadedFiles.filter((f) => f.tempId !== file.fileId);
    this.fileUploadCmp.delete(file);
  }

  setFocusOnTheFirstFormElement(): void {
    // this.setFocusFirstFormElement(this.form, this.el);
  }

  downloadTemplate(): void {
    this.bulkUploadService
      .getTemplate(this.projectID, this.dataPurpose)
      .pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        importedSaveAs(x.body, TEMPLATE_FILE_NAME.replace('%PROJ_NAME%', this.projectName));
      });
  }
}
