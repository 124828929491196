import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { IFieldMeta } from '../components/ey-base-form-control/field-meta.model';

@Directive({
  selector: 'label[fieldMetaTitle]',
})
export class FieldMetaTitleDirective implements OnChanges {
  @Input() fieldMetaTitle: IFieldMeta;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fieldMetaTitle && this.fieldMetaTitle) {
      this.renderer.setAttribute(this.el.nativeElement, 'title', this.getElementTitle());
    }
  }

  private getElementTitle(): string {
    if (!this.fieldMetaTitle.showLabelAsPopup) {
      return '';
    }

    if (this.fieldMetaTitle.required) {
      return `${this.fieldMetaTitle.title} (required)`;
    }

    return this.fieldMetaTitle.title;
  }
}
