import { Property } from '../../../shared/directives/dynamic-control.directive';

export type Shape = RectangularShape | DecisionShape | ConnectionShape;

export interface RectangularShape {
  readonly id: string;
  readonly type: string;
  readonly class: string;
  readonly name: string;
  readonly properties: Property[];
  readonly visual: { readonly x: number; readonly y: number };
}

export interface ConnectionMeta {
  connectionId: string;
  label: string;
}

export interface DecisionShape extends RectangularShape {
  readonly connectionMeta: Array<ConnectionMeta>;
}

export interface YesNoShape extends RectangularShape {
  readonly connectionMeta: { yes: ConnectionMeta; no: ConnectionMeta };
}

export interface ConnectionShape {
  readonly id: string;
  readonly class: string;
  readonly sourceId: string;
  readonly targetId: string;
  readonly label?: string;
  readonly visual: {
    readonly sourceX: number;
    readonly sourceY: number;
    readonly sourceReceptorId: string;
    readonly targetX: number;
    readonly targetY: number;
    readonly targetReceptorId: string;
  };
}

export function shapeIsRectangular(shape: Shape): shape is RectangularShape {
  return (shape as RectangularShape).type !== undefined;
}

export function shapeIsConnection(shape: Shape): shape is ConnectionShape {
  return (shape as ConnectionShape).sourceId !== undefined;
}
