import { EyTabDirective } from './ey-tab.directive';
import { AfterContentChecked, Component, ContentChildren, EventEmitter, Input, Output, QueryList, ViewEncapsulation } from '@angular/core';
import { EyTabsetConfig } from './ey-tabset.config';

/**
 * The payload of the change event fired right before the tab change.
 *
 * @deprecated 6.0.0 Please use NgbNav instead
 */
export interface EyTabChangeEvent {
  /**
   * The id of the currently active tab.
   */
  activeId: string;

  /**
   * The id of the newly selected tab.
   */
  nextId: string;

  /**
   * Calling this function will prevent tab switching.
   */
  preventDefault: () => void;
}

/**
 * A component that makes it easy to create tabbed interface.
 */
@Component({
  selector: 'ey-tabset',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './ey-tabset.component.html',
  styleUrls: ['./ey-tabset.component.scss'],
})
export class EyTabset implements AfterContentChecked {
  static ngAcceptInputType_justify: string;
  static ngAcceptInputType_orientation: string;
  @Input() showBottomBorder: boolean = false;
  justifyClass: string;

  @ContentChildren(EyTabDirective) tabs: QueryList<EyTabDirective>;

  /**
   * The identifier of the tab that should be opened **initially**.
   * For subsequent tab switches use the `.select()` method and the `(tabChange)` event.
   */
  @Input() activeId: string;

  /**
   * If `true`, non-visible tabs content will be removed from DOM. Otherwise it will just be hidden.
   */
  @Input() destroyOnHide = true;

  /**
   * The horizontal alignment of the tabs with flexbox utilities.
   */
  @Input()
  set justify(className: 'start' | 'center' | 'end' | 'fill' | 'justified') {
    if (className === 'fill' || className === 'justified') {
      this.justifyClass = `nav-${className}`;
    } else {
      this.justifyClass = `justify-content-${className}`;
    }
  }

  /**
   * The orientation of the tabset.
   */
  @Input() orientation: 'horizontal' | 'vertical';

  /**
   * Type of navigation to be used for tabs.
   */
  @Input() type: 'tabs' | 'pills' | string;

  @Input() tabsetStyle: 'tetrary-reverse' | string;

  /**
   * A tab change event emitted right before the tab change happens.
   */
  @Output() tabChange: EventEmitter<EyTabChangeEvent> = new EventEmitter<EyTabChangeEvent>();

  constructor(config: EyTabsetConfig) {
    this.type = config.type;
    this.justify = config.justify;
    this.orientation = config.orientation;
  }

  /**
   * Selects the tab with the given id and shows its associated content panel.
   * Any other tab that was previously selected becomes unselected and its associated pane is removed from DOM or
   * hidden depending on the `destroyOnHide` value.
   */
  select(tabId: string): void {
    const selectedTab = this._getTabById(tabId);
    if (selectedTab && !selectedTab.disabled && this.activeId !== selectedTab.id) {
      let defaultPrevented = false;

      this.tabChange.emit({
        activeId: this.activeId,
        nextId: selectedTab.id,
        preventDefault: () => {
          defaultPrevented = true;
        },
      });

      if (!defaultPrevented) {
        this.activeId = selectedTab.id;
      }
    }
  }

  ngAfterContentChecked(): void {
    // auto-correct activeId that might have been set incorrectly as input
    const activeTab = this._getTabById(this.activeId);

    this.activeId = activeTab ? activeTab.id : this.tabs.length ? this.tabs.first.id : this.activeId || <any>null;
  }

  private _getTabById(id: string): EyTabDirective {
    const tabsWithId: EyTabDirective[] = this.tabs.filter((tab) => tab.id === id);
    return tabsWithId.length ? tabsWithId[0] : <any>null;
  }
}
