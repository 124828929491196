import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.scss'],
})
export class LogoutPageComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute) {}
  auto = false;
  ngOnInit(): void {
    sessionStorage.clear();
    this.auto = 'true' === this.activatedRoute.snapshot.queryParamMap.get('auto');
  }
}
