import { ComponentFactoryResolver, Directive, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SpecificPart } from '../../designer/workflow-designer/specific-parts/specific-part';
import { PropertyPlacement, PropertyType } from '../../designer/workflow-designer/domain/property';
import { Subject } from 'rxjs/internal/Subject';
import { RichTextControlComponent } from '../../designer/workflow-designer/properties-editor/dynamic-controls/rich-text-control/rich-text-control.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

export interface Property {
  readonly type: PropertyType;
  readonly isStandalone?: boolean;
  readonly label: string;
  readonly name: string;
  readonly placement: PropertyPlacement;
  readonly options?: any;
}

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[dynamicControl]',
  exportAs: 'dynamicControl',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicControlDirective),
      multi: true,
    },
  ],
})
export class DynamicControlDirective implements OnInit, ControlValueAccessor, OnChanges, OnDestroy {
  @Input() property: Property;
  @Input() standalone = false;
  @Input() controlsMap = new Map<PropertyType, any>();
  @Input() formBuilderControl: any = null;
  @Input() mapFields: any = null;
  @Input() specificPart: SpecificPart = null;
  @Input() requiredFieldCrossValidationError = false;
  @Input() updateFroalaConfig: Subject<any>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  private component: any;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.specificPart?.currentValue && this.component) {
      this.component.specificPart = changes.specificPart.currentValue;
    }
  }

  writeValue(obj: any): void {
    if (!this.standalone && this.component) {
      this.component.writeValue(obj);
    }
  }
  registerOnChange(fn: any): void {
    if (!this.standalone && this.component) {
      this.component.registerOnChange(fn);
    }
  }
  registerOnTouched(fn: any): void {
    if (!this.standalone && this.component) {
      this.component.registerOnTouched(fn);
    }
  }
  setDisabledState?(isDisabled: boolean): void {
    if (!this.standalone && this.component && this.component.setDisabledState) {
      this.component.setDisabledState(isDisabled);
    }
  }

  ngOnInit(): void {
    this.loadComponent();
  }

  loadComponent(type: number = null): void {
    if (type == null) {
      type = this.property.type;
    }
    const control = this.controlsMap.get(type);
    if (!control) {
      return;
    }
    this.viewContainerRef.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory<any>(control);
    const componentRef = this.viewContainerRef.createComponent(factory);
    this.component = componentRef.instance;
    this.component.property = this.property;
    this.component.requiredFieldCrossValidationError = this.requiredFieldCrossValidationError;

    if (type === PropertyType.RichText && this.updateFroalaConfig) {
      (this.updateFroalaConfig as Observable<any>).pipe(takeUntil(this.destroy$)).subscribe((config) => {
        this.component.froalaConfig = config;
        (this.component as RichTextControlComponent).refreshFroalaOptions();
      });
    }

    /* note(MK) at the moment used only in table builder to pass mapping fields consider moving this to control itself */
    if ('mapFields' in this.component) {
      this.component.mapFields = this.mapFields;
    }

    if ('specificPart' in this.component) {
      this.component.specificPart = this.specificPart;
    }

    if (this.formBuilderControl) {
      this.component.control = this.formBuilderControl;
    }
  }

  clearView(): void {
    this.viewContainerRef.clear();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
