<app-fluid-footer-template class="d-flex flex-fill" [hintPresent]="page?.moduleFlowPage?.hint" [selectedViewType]="selectedViewType">
  <ng-container appPageContent>
    <div *ngIf="isHidden && isMailNotTriggered" class="d-flex align-items-center automation-warning p-10 mt-10 mb-10">
      <app-hide-from-end-users
        [text]="'This page will be hidden from end-users. Mail was not triggered based on condition defined.'"
      ></app-hide-from-end-users>
    </div>
    <div *ngIf="isHidden && !isMailNotTriggered" class="d-flex align-items-center automation-warning p-10 mt-10 mb-10">
      <app-hide-from-end-users [text]="'This page will be hidden from end-users.'"></app-hide-from-end-users>
    </div>
    <div *ngIf="!isHidden && isMailNotTriggered" class="d-flex align-items-center automation-warning p-10 mt-10 mb-10">
      <app-hide-from-end-users [hideIcon]="true" [text]="'Mail was not triggered based on condition defined.'"></app-hide-from-end-users>
    </div>
    <ey-mail-summary [mailSections]="mailSections" class="d-block pb-20"></ey-mail-summary>
    <ey-froala-content-wrapper class="d-block mb-20" *ngFor="let block of htmlBlocks" [editorContent]="block.content"> </ey-froala-content-wrapper>
  </ng-container>
  <ng-container appPageFooter>
    <div>
      <div class="d-flex">
        <ey-button class="ms-auto me-20" [btnClass]="btnClass.btnPrev" text="Prev" (click)="onPrev()"></ey-button>
        <ey-button [btnClass]="btnClass.btnNext" text="Next" (click)="onNext()"></ey-button>
      </div>
      <div *ngIf="lastTechReviewDate && !isMobileBreakpoint">
        <span class="reviewDate spacing">Last Technical Review: {{ lastTechReviewDate }}</span>
      </div>
    </div>
  </ng-container>
</app-fluid-footer-template>
