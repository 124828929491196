import { Component, Input, OnInit } from '@angular/core';
import { ProgressBarStep } from './progress-bar-step.model';

@Component({
  selector: 'ey-form-progress-bar',
  templateUrl: './ey-form-progress-bar.component.html',
  styleUrls: ['./ey-form-progress-bar.component.scss'],
})
export class EyFormProgressBarComponent implements OnInit {
  @Input() steps: ProgressBarStep[] = [];
  constructor() {}

  ngOnInit(): void {}
}
