import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnrollmentStatus } from 'src/app/core/interceptors/enrollment-status';
import { environment } from '../../../environments/environment';

const enrolmentToNotice = new Map<EnrollmentStatus, string>([
  [EnrollmentStatus.FailedDueToMissingDetails, 'Too little data from SSO provider'],
  [EnrollmentStatus.FailedDueToUnknownOrigin, 'Failed to determine origin of the user'],
  [EnrollmentStatus.FailedDueToExternalUserOrigin, "We couldn't find your account. Please contact your EY Engagement Manager."],
  [EnrollmentStatus.FailedDueToNotAllowedDomain, 'Contact support to get more information at empowerment.support@au.ey.com'],
  [EnrollmentStatus.FailedDueToUserIsBlocked, "User's account is blocked. Please contact your EY Engagement Manager."],
  [EnrollmentStatus.FailedDueToSessionTermination, 'Session was terminated due to another instance is running'],
]);

@Component({
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent {
  noticeText = `Contact support to get more information at ${environment.supportEmail}`;
  isExternalUserWithoutAccount = false;
  isUserBlocked = false;

  constructor(activatedRoute: ActivatedRoute) {
    activatedRoute.paramMap.subscribe((p) => {
      const enrolmentStatus = p.get('enrolmentStatus');

      if (enrolmentStatus) {
        const enrolmentStatusNumber = Number(enrolmentStatus);

        const noticeText = enrolmentToNotice.get(enrolmentStatusNumber);
        if (noticeText) {
          this.noticeText = noticeText;
        }

        if (enrolmentStatusNumber === EnrollmentStatus.FailedDueToExternalUserOrigin) {
          this.isExternalUserWithoutAccount = true;
        } else if (enrolmentStatusNumber === EnrollmentStatus.FailedDueToUserIsBlocked) {
          this.isUserBlocked = true;
        }
      }
    });
  }
}
