import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalResult } from '../../shared/components/ey-modal-template/ey-modal-result.enum';

@Component({
  selector: 'app-multiple-sessions-active-modal',
  templateUrl: './multiple-sessions-active-modal.component.html',
  styleUrls: ['./multiple-sessions-active-modal.component.scss'],
})
export class MultipleSessionsActiveModalComponent implements OnInit {
  @Input() browser: string;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  keepGoing(): void {
    this.activeModal.close(ModalResult.dismiss);
  }

  logout(): void {
    this.activeModal.close(ModalResult.submit);
  }
}
