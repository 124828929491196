import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ey-filter-pills',
  templateUrl: './ey-filter-pills.component.html',
  styleUrls: ['./ey-filter-pills.component.scss'],
})
export class EyFilterPillsComponent {
  @Input() selectedValues: any = [];
  @Input() showClearAllOption = false;
  @Input() isDisabled: false;
  @Input() labelName = 'name';
  @Input() showLargeBadges = false;

  @Output() removeItemClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearAllSelected: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  removeItem(item: any): void {
    this.removeItemClick.next(item);
  }
  onClearAll(): void {
    this.clearAllSelected.next();
  }
}
