<div *ngIf="isHidden" class="d-flex align-items-center automation-warning p-10 mt-10 mb-10">
  <i class="icon-ico_eye_off automated-icon top-message me-2"></i>
  This page was hidden from end-users.
</div>
<div class="d-flex flex-fill ps-0 pe-0 pt-20 pb-20 m-0 form-answer-block" *ngFor="let item of this.formParts; let i = index">
  <ng-container [ngSwitch]="item.type" class="d-flex flex-fill" *ngIf="getPropertyVisibility(item)">
    <span *ngSwitchCase="controlType.description" class="d-block w-100">
      <ey-froala-content-wrapper [editorContent]="item.content"></ey-froala-content-wrapper>
    </span>
    <span *ngSwitchCase="controlType.radioButton" class="d-flex flex-fill flex-column">
      <ng-container *ngTemplateOutlet="fieldDes; context: { item: item }"></ng-container>
      <ul class="ps-0">
        <li *ngFor="let op of item.options" [ngClass]="{ selected: op.selected }"><i class="icon-ico_check emp-icon"></i>{{ op.text }}</li>
      </ul>
      <span class="answer">{{ item.value }}</span>
    </span>
    <span *ngSwitchCase="controlType.checkboxGroup" class="d-flex flex-fill flex-column">
      <ng-container *ngTemplateOutlet="fieldDes; context: { item: item }"></ng-container>
      <ul class="ps-0">
        <li *ngFor="let op of item.options" [ngClass]="{ selected: op.selected }"><i class="icon-ico_check emp-icon"></i>{{ op.text }}</li>
      </ul>
      <span class="answer">{{ item.value }}</span>
    </span>
    <span *ngSwitchCase="controlType.date" class="d-flex flex-fill flex-column">
      <ng-container *ngTemplateOutlet="fieldDes; context: { item: item }"></ng-container>
      <span class="answer">{{ item.value && item.value?.length > 0 ? (item.value | date: 'dd MMMM yyyy' : 'UTC') : '- Not Entered -' }}</span>
    </span>
    <span *ngSwitchCase="controlType.eyEmailLookup" class="d-flex flex-fill flex-column">
      <ng-container *ngTemplateOutlet="fieldDes; context: { item: item }"></ng-container>
      <span class="answer" *ngFor="let email of item.value">{{ email }}&#59;</span>
      <span class="answer" *ngIf="item.value?.length < 1">- Not Entered -</span>
    </span>
    <span *ngSwitchCase="controlType.divider" class="d-flex flex-fill flex-column">
      <hr class="divider-color" [ngStyle]="{ height: getHrStyle(item.style) }" />
    </span>
    <span *ngSwitchDefault class="d-flex flex-fill flex-column">
      <ng-container *ngTemplateOutlet="fieldDes; context: { item: item }"></ng-container>
      <span class="answer">{{ item.value && item.value?.length > 0 ? item.value : '- Not Entered -' }}</span>
    </span>
  </ng-container>
</div>

<ng-template #fieldDes let-item="item">
  <p class="question mb-0 me-1">{{ item.title }} <span *ngIf="item.isRequired" class="required eyfontlight">*</span></p>
  <p class="eyfontlight hint mb-10">{{ item.hint }}</p>
</ng-template>
