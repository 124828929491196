import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/models/user/user.model';
import { shareReplay } from 'rxjs/operators';
import { TableData } from '../../shared/components/ey-grid/models/table-data.model';
import { GridState } from '../../shared/components/ey-grid/models/grid-state.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) {}

  initCurrentUserSession(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.baseUrl}/user/init`);
  }

  getCurrentUser(): Observable<User> {
    return this.httpClient.get<User>(`${this.baseUrl}/user/me`).pipe(shareReplay(1));
  }

  updateProfile(updateProfile: any): Observable<any> {
    return this.httpClient.patch<any>(`${this.baseUrl}/user/me/profile`, updateProfile);
  }

  getEyUsers(gridState: GridState): Observable<TableData<User>> {
    return this.httpClient.get<TableData<User>>(`${this.baseUrl}/user/listey-user`, {
      params: {
        pageSize: gridState.pageSize.toString(),
        sortBy: gridState.sortBy.toString(),
        sortDirection: gridState.sortDirection.toString(),
        pageNumber: gridState.pageNumber.toString(),
        search: (gridState.search ?? '').toString(),
      },
    });
  }

  getClientUsers(gridState: GridState): Observable<TableData<User>> {
    return this.httpClient.get<TableData<User>>(`${this.baseUrl}/user/listclient-user`, {
      params: {
        pageSize: gridState.pageSize.toString(),
        sortBy: gridState.sortBy.toString(),
        sortDirection: gridState.sortDirection.toString(),
        pageNumber: gridState.pageNumber.toString(),
        search: (gridState.search ?? '').toString(),
      },
    });
  }

  deleteUser(id: string): Observable<User> {
    return this.httpClient.delete<any>(`${this.baseUrl}/user/${id}`);
  }

  getUser(id: string): Observable<User> {
    return this.httpClient.get<any>(`${this.baseUrl}/user/${id}`);
  }
}
