import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { DropDownParentViewTypes } from '../../ey-drop-down/ey-drop-down.enum';
import { EyGridMenuService } from 'src/app/shared/components/ey-grid/ey-grid-menu/ey-grid-menu.service';

export const ITEM_HEIGHT_PX = 45;

@Component({
  selector: 'ey-grid-menu',
  templateUrl: './ey-grid-menu.component.html',
  styleUrls: ['./ey-grid-menu.component.scss'],
})
export class EyGridMenuComponent implements OnInit {
  @Input() menuWidth = 176;
  @Input() light: false;
  @Input() relativePositioning: false;
  @ViewChild('ElementRefName') element: ElementRef;
  @ViewChild('menuHolder') menuHolder: ElementRef;
  @ViewChild('link') link: ElementRef;
  @Input() numberOfItems = 5;
  @Input() parentViewFlag: DropDownParentViewTypes = DropDownParentViewTypes.grid;

  get dropDownHeight(): number {
    /* it would be much better to use the height below however this will not provide correct results if element is not rendered
    this.elem.nativeElement.querySelectorAll('.dropdown-menu')[0].getBoundingClientRect().height
    the solution below will work in 90% of cases for all other it will be required to provide exact number of elements */
    return this.numberOfItems * ITEM_HEIGHT_PX;
  }

  offsetTop: number;
  offsetLeft: number;
  expanded = false;
  gridMenuTableScrollOffset = 0;
  styles = {
    top: 'unset',
    left: '0px',
    bottom: 'unset',
  };

  @HostListener('window:resize', ['$event'])
  sizeChange(): void {
    // todo(MK) this is workaround for resize for now if the solution is not acceptable then we can get back and rewrite component
    if (this.expanded) {
      this.expanded = false;
    }
  }
  @HostListener('document:click', ['$event'])
  clickOut(event): void {
    if (this.expanded && !this.link.nativeElement.contains(event.target)) {
      this.expanded = false;
    }
  }
  @HostListener('document:scroll', ['$event'])
  scroll(event): void {
    if (this.expanded && !this.link.nativeElement.contains(event.target)) {
      this.expanded = false;
    }
  }

  constructor(
    private elem: ElementRef,
    private eyGridMenuService: EyGridMenuService,
  ) {}

  ngOnInit(): void {
    this.updateMenuPositionOnTableScroll();
  }

  updateMenuPositionOnTableScroll(): void {
    this.eyGridMenuService.gridMenuScrollLeftOffset.subscribe((tableScrollOffset) => {
      this.gridMenuTableScrollOffset = tableScrollOffset;
    });
  }

  getPosition(event: any): void {
    if (this.parentViewFlag === 1) {
      this.relativePositioning = false;
    }

    let offsetLeft = 0;
    let offsetTop = 0;
    let OffsetBottom_TABLE = 0;
    let offsetTop_TD = 0;
    let OffsetBottom_BODY = 0;
    let offsetTop_DIV = 0;
    let divHeight = 0;

    let el = this.link.nativeElement;
    while (el) {
      offsetLeft += el.offsetLeft;
      offsetTop += el.offsetTop;
      if (this.parentViewFlag === 1) {
        if (el.tagName === 'DIV') {
          offsetTop_DIV = el.offsetTop;
          divHeight = el.offsetHeight;
        }

        if (el.tagName === 'BODY') {
          OffsetBottom_BODY = el.getBoundingClientRect().height;
        }
      } else {
        if (el.tagName === 'TD') {
          offsetTop_TD = el.offsetTop;
        }

        if (el.tagName === 'TABLE') {
          OffsetBottom_TABLE = el.getBoundingClientRect().height;
        }
      }

      el = el.offsetParent;
      if (this.relativePositioning) {
        break;
      }
    }
    /* todo(MK ): size should be calculated instead of setting fixed width
        however the width is not know until it's menu rendered (expanded)
        -- this.menuHolder.nativeElement.clientWidth;
        if this gets logged as a bug then the component should be rewritten
        solution: absolute menu dynamicly inserted into document
    */
    const width = this.menuWidth;
    this.offsetTop = offsetTop;
    this.offsetLeft = offsetLeft;
    this.expanded = !this.expanded;

    if (!this.relativePositioning && this.parentViewFlag === 0) {
      if (offsetTop_TD + this.dropDownHeight > OffsetBottom_TABLE) {
        this.styles = { ...this.styles, bottom: window.innerHeight - event.clientY + 'px', top: 'unset' };
      } else {
        this.styles = { ...this.styles, top: event.clientY + 'px', bottom: 'unset' };
      }
    }
    if (this.parentViewFlag === 1) {
      if (offsetTop_DIV + this.dropDownHeight + divHeight > OffsetBottom_BODY) {
        this.styles = { ...this.styles, bottom: window.innerHeight - event.clientY + 'px', top: 'unset' };
      } else {
        this.styles = { ...this.styles, top: event.clientY + 'px', bottom: 'unset' };
      }
    }

    const tableElement = document.getElementById('table-container');
    if (tableElement) {
      this.styles = { ...this.styles, left: this.offsetLeft - width - this.gridMenuTableScrollOffset + 'px' };
    } else {
      this.styles = { ...this.styles, left: this.offsetLeft - width + 'px' };
    }
  }
}
