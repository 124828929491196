import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { EyFormBaseComponent } from '../../../shared/components/ey-form-base/ey-base-form-control';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { BulkUploadService } from '../bulk-upload.service';
import { forkJoin, of } from 'rxjs';
import { EyAppSpinnerService } from '../../../shared/components/ey-app-spinner/ey-app-spinner.service';
import { Subject } from 'rxjs/internal/Subject';
import { IMapping, IMappingTemplate } from '../bulk-upload.model';
import { ToolTipHolderOptions } from '../../../shared/components/constants';
import { UserService } from '../../../core/services/user-service';
import { User } from '../../../core/models/user/user.model';
import { BulkUploadModalService } from '../bulk-upload-modal.service';
import { DataPurposes } from '../data-purpose/data-purpose.component';
import { EyDropdownLightWeightComponent } from '../../../shared/components/ey-dropdown-light-weight/ey-dropdown-light-weight.component';

const RESPONSE_ID = 'Response ID';
const RESPONSE_CREATED_BY = 'Response::CreatedBy';
export const META = {
  mappingTemplate: {
    title: 'Existing Mapping',
  },
  mappingField: {
    title: '',
  },
  excelColumn: {
    title: '',
    errorMsg: '',
  },
  saveTemplate: {
    title: 'Save your mapping as a Template for future use',
  },
  templateName: {
    title: 'Template Name',
    errorMsg: 'Please enter a valid data mapping name (between 1-150 characters)',
    required: true,
  },
  templateDescription: {
    title: 'Description',
    errorMsg: 'Please enter a valid data mapping description (between 1-500 characters)',
    required: true,
  },
  search: {
    title: 'Search Module Fields',
  },
};

export interface IExcelColumn {
  name: string;
  id: string;
}

@Component({
  selector: 'app-data-mapping',
  templateUrl: './data-mapping.component.html',
  styleUrls: ['./data-mapping.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DataMappingComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DataMappingComponent),
      multi: true,
    },
  ],
})
export class DataMappingComponent extends EyFormBaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChildren('excelColumnDropdownContainer') excelColumnDropdownContainer: QueryList<EyDropdownLightWeightComponent>;
  @Input() projectID: string;
  destroy$: Subject<void> = new Subject<void>();
  meta = META;
  mappingTemplates: IMappingTemplate[] = [];
  moduleFields: string[];
  excelColumns: IExcelColumn[] = [];
  form: UntypedFormGroup = this.initFormGroup();
  toolTipHolderOptionsHidden = ToolTipHolderOptions.hidden;
  disableSaveMappings = true;
  currentUser: User;
  dataPurpose: DataPurposes;

  hiddenModuleFields = [];
  validationRequired = false;
  constructor(
    private fb: UntypedFormBuilder,
    private el: ElementRef,
    private bulkUploadService: BulkUploadService,
    private spinnerService: EyAppSpinnerService,
    private userService: UserService,
    private bulkUploadModalService: BulkUploadModalService,
  ) {
    super();
  }
  onSearch(st: string): void {
    this.hiddenModuleFields = [];
    if (st && st.length > 1) {
      this.moduleFields.forEach((mf) => {
        if (!mf.toLowerCase().includes(st.toLowerCase())) {
          this.hiddenModuleFields[mf] = { hidden: true };
        }
      });
    }
  }
  ngOnInit(): void {
    this.bulkUploadModalService.dataPurpose.subscribe((dataPurpose) => {
      this.dataPurpose = dataPurpose.dataPurpose;
    });
  }

  initFormGroup(): UntypedFormGroup {
    return this.fb.group(
      {
        mappingTemplates: [,],
        mappings: this.fb.array([]),
        saveTemplate: [, { updateOn: 'change' }],
        templateName: [,],
        templateDescription: [,],
        search: [, { updateOn: 'change' }],
      },
      { updateOn: 'blur' },
    );
  }

  initLoad(uploadedFiles: string[]): void {
    const requests$ = forkJoin([
      this.bulkUploadService.getMappingTemplates(this.projectID),
      this.bulkUploadService.getModuleFields(this.projectID, this.dataPurpose),
      this.bulkUploadService.getExcelColumns(this.projectID, uploadedFiles),
      this.userService.getCurrentUser(),
    ]);

    this.spinnerService.withLoadingIndicator(requests$, this.destroy$).subscribe(([mappingTemplates, moduleFields, excelColumns, currentUser]) => {
      this.mappingTemplates = mappingTemplates;
      this.moduleFields = moduleFields;
      this.excelColumns = excelColumns.map((t) => {
        return { name: t, id: t };
      });
      this.currentUser = currentUser;
      this.populateMappingsFormArray();
      this.crateSubscriptions();
      this.disableSaveMappings = true;
    });
  }

  dropdownClickFn(index: number): void {
    this.excelColumnDropdownContainer.toArray()[index].options = this.excelColumns;
  }

  populateMappingsFormArray(): void {
    this.form.reset();
    const mappingsFormArray = this.form.get('mappings') as UntypedFormArray;
    mappingsFormArray.clear();
    this.moduleFields.forEach((mf, index) => {
      if (mf.includes(RESPONSE_ID)) {
        mappingsFormArray.push(this.getCtrlGroup(mf, false));
        this.meta.excelColumn.errorMsg = 'Please set mapping for this field';
      } else if (mf.includes(RESPONSE_CREATED_BY) && this.dataPurpose === DataPurposes.PartialResponseData) {
        mappingsFormArray.push(this.getCtrlGroup(mf, true));
        this.meta.excelColumn.errorMsg = 'Please set mapping for this field';
      } else {
        mappingsFormArray.push(this.getCtrlGroup(mf, false));
        this.meta.excelColumn.errorMsg = '';
      }
    });
  }

  crateSubscriptions(): void {
    this.form.get('mappingTemplates').valueChanges.subscribe((t: IMappingTemplate) => {
      if (t) {
        this.patchExcelColumnsFromTemplate(t.mappings, this.excelColumns);
      }
    });
    this.form.get('search').valueChanges.subscribe((search) => this.onSearch(search));
    this.form.get('mappings').valueChanges.subscribe((search) => (this.disableSaveMappings = false));
    this.form.get('saveTemplate').valueChanges.subscribe((st) => this.onSaveTemplateChange(st));
  }

  onSaveTemplateChange(saveTemplate: boolean): void {
    const tempName = this.form.get('templateName');
    const desc = this.form.get('templateDescription');

    if (saveTemplate) {
      tempName.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(150)]);
      tempName.patchValue(this.currentUser.name + ' data mapping ' + (this.mappingTemplates.length + 1), { emitEvent: false });
      desc.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(500)]);
      desc.patchValue('', { emitEvent: false });
    } else {
      desc.setValidators([]);
      tempName.setValidators([]);
      desc.patchValue('', { emitEvent: false });
      tempName.patchValue('', { emitEvent: false });
      // tempName.clearValidators();
      // desc.clearValidators();
    }
  }

  patchExcelColumnsFromTemplate(templateMappings: IMapping[], excelColumns: IExcelColumn[]): void {
    this.clearAllMappings();
    const mappingsFormArray = this.form.get('mappings') as UntypedFormArray;

    mappingsFormArray.controls.forEach((c, index) => {
      const ctrlValue = c.value;
      const mapping = templateMappings.find((tm) => tm.mappingField === ctrlValue.mappingField);
      if (mapping !== undefined) {
        const excColumn = excelColumns.find((t) => t.name === mapping.excelColumn);
        if (excColumn !== undefined) {
          this.excelColumnDropdownContainer.toArray()[index].options = this.excelColumns;
          c.patchValue({ ...ctrlValue, excelColumn: excColumn }, { emitEvent: false });
        }
      }
    });
    this.disableSaveMappings = true;
  }

  getCtrlGroup(name, validationRequired = false): UntypedFormGroup {
    if (validationRequired) {
      this.validationRequired = true;
      return this.fb.group({
        mappingField: [name, []],
        excelColumn: [, Validators.required],
      });
    } else {
      return this.fb.group({
        mappingField: [name, []],
        excelColumn: [,],
      });
    }
  }

  writeValue(val: any): void {
    if (val) {
      this.form.patchValue(val, { emitEvent: false });
    }
  }
  public onTouched: () => void = () => {};

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { subformerror: 'DataMappingComponent Form Error!' };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.form.statusChanges.subscribe(fn);
  }
  markAsTouched(): void {
    this.form.markAllAsTouched();
    if (!this.validationRequired) {
      this.focusOnError(this.form, this.el);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setFocusOnTheFirstFormElement(): void {
    // this.setFocusFirstFormElement(this.form, this.el);
  }

  clearAllMappings(): void {
    this.disableSaveMappings = true;
    this.form.patchValue({ saveTemplate: false }, { emitEvent: false });
    const mappingsFormArray = this.form.get('mappings') as UntypedFormArray;
    mappingsFormArray.controls.forEach((c) => c.patchValue({ ...c.value, excelColumn: null }, { emitEvent: false }));
  }
}
