import { AfterContentChecked, AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'ey-froala-content-wrapper',
  templateUrl: './ey-froala-content-wrapper.component.html',
  styleUrls: ['./ey-froala-content-wrapper.component.scss'],
})
export class EyFroalaContentWrapperComponent implements AfterViewInit, AfterContentChecked {
  @Input() editorContent: string = null;

  @ViewChild('froalaContentContainer') froalaContentContainer: ElementRef;
  @ViewChild('froalaContent') froalaContent: ElementRef;
  width: number;
  private hasResized = false;

  constructor() {}

  @HostListener('window:resize')
  windowResize(): void {
    this.hasResized = false;
  }

  ngAfterViewInit(): void {
    if (this.froalaContentContainer == null) {
      return;
    }

    this.resizeFroalaContent();
  }

  ngAfterContentChecked(): void {
    if (this.froalaContentContainer == null) {
      return;
    }
    const froalaConentContainer = this.froalaContentContainer?.nativeElement as HTMLElement;

    if (froalaConentContainer.offsetWidth > 0 && !this.hasResized) {
      this.resizeFroalaContent();

      this.hasResized = true;
    }
  }

  resizeFroalaContent(): void {
    const froalaContent = this.froalaContent?.nativeElement as HTMLElement;
    const froalaContentContainer = this.froalaContentContainer?.nativeElement as HTMLElement;

    if (!(froalaContent && froalaContentContainer)) {
      return;
    }

    if (this.width !== froalaContentContainer.offsetWidth) {
      // MK(15-02-2020): code below generates layout issues (when switching from regular preview to tablet/mobile)
      // not sure why this code is needed however if we get any issues reported which could be related.
      // we may need to find alternative solution.
      // this.width = froalaContentContainer.offsetWidth;
      // froalaContent.style.display = 'none';
      // froalaContent.style.width = `${this.width}px`;
      // froalaContent.style.overflow = 'hidden';
      // froalaContent.style.display = 'block';
    }
  }
}
