export class SimpleUser {
  id: string;
  name: string;
  email: string;
}

export class User extends SimpleUser {
  isPrivacyNoticeAccepted: boolean;
  isUserProfileCompleted: boolean;
  areaId: number;
  countryId: number;
  serviceLineId: number;
  subServiceLineId: number;
  isExternal: boolean;
  isActive: boolean;
  isBlocked: boolean;
  status: string;
  roles: string[];
  permissions: string[];
}
