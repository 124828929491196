import { Component, OnInit } from '@angular/core';
import { SummarySectionBaseComponent } from '../summary-section-base.component';

@Component({
  selector: 'app-list-summary',
  templateUrl: './list-summary.component.html',
  styleUrls: ['./list-summary.component.scss'],
})
export class ListSummaryComponent extends SummarySectionBaseComponent implements OnInit {
  listValues: string[];
  body: string;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.body = this.page?.moduleFlowPage.body;
    this.listValues = JSON.parse(this.page.moduleFlowPage.properties[0].value);
  }
}
