import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  private baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) {}

  getFile(fileId: string, fileDescriptor: string = null): Observable<HttpResponse<Blob>> {
    return fileDescriptor
      ? this.httpClient.get(`${this.baseUrl}/file/${fileId}/${fileDescriptor}`, { observe: 'response', responseType: 'blob' })
      : this.httpClient.get(`${this.baseUrl}/file/${fileId}`, { observe: 'response', responseType: 'blob' });
  }
}
