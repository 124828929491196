import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReviewResponseService {
  private baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) {}

  openReviewResponseTab(projectId: string, responseId: string): void {
    window.open(this.getReviewResponseLink(projectId, responseId), '_blank');
  }

  openReviewDraftResponseTab(projectId: string, responseId: string): void {
    window.open(this.getDraftReviewResponseLink(projectId, responseId), '_blank');
  }

  getDraftReviewResponseLink(projectId: string, responseId: string): string {
    return `/design-module/${projectId}/review-draft-response/${responseId}`;
  }

  getReviewResponseLink(projectId: string, responseId: string): string {
    return `/design-module/${projectId}/review-response/${responseId}`;
  }

  getResponseRelatedDataOnReview(sessionId: string): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/reviewflow/${sessionId}/responseInfo`);
  }

  getResponseRelatedDataOnDraftReview(sessionId: string): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/reviewdraftflow/${sessionId}/responseInfo`);
  }
}
