import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { UserOtherPermissions, UserProjectPermissions } from 'src/app/core/user-permissions.model';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';
import { EyTabset } from 'src/app/shared/components/ey-tabset/ey-tabset.component';
import { ProjectActionsPermissions } from '../project-permissions.const';
import { ProjectPermissionsService } from '../project-permissions.service';
import { ProjectStatuses, ProjectTabs, UNKNOWN_PROJECT_STATUS, ForbiddenProjectStatusesForGetWebLink } from '../project.const';
import { ProjectsService } from '../projects.service';
import { ProjectInfo } from './project-info.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalVerticalSizeWindowClass, ModalWidth } from '../../shared/components/ey-modal-template/ey-modal-result.enum';
import { GetWebLinkComponent } from '../get-web-link/get-web-link.component';

export const PARENT_ACTIVE_TAB_ID = 'patid';

export enum MgrProjectTabs {
  ProjectResponses,
  ProjectInfo,
  TeamMembers,
  DocumentAutomation,
  BulkUploadResponses,
  BulkDownloadResponses,
}

export const PROJECT_TAB_QUERY_PARAM_NAME = 'activeTab';

@Component({
  selector: 'app-manage-project',
  templateUrl: './manage-project.component.html',
  styleUrls: ['./manage-project.component.scss'],
})
export class ManageProjectComponent implements OnInit {
  @ViewChild(EyTabset) tabSet: EyTabset;

  destroy$: Subject<void> = new Subject<void>();
  projectTabs = MgrProjectTabs;
  projectMgr = ProjectTabs.mgrProjects;
  activeTabId = MgrProjectTabs.ProjectResponses;
  projectId = '';
  parentActiveTabId: number = null;
  project: ProjectInfo;
  readProject: boolean;
  projectParticipantsUpdate: boolean;
  globalWebLinkPermission: boolean;
  projectPermissions: UserProjectPermissions;
  otherUserPermissions: string[] = [];
  projectUserPermissions: string[] = [];
  updateProject: boolean;
  isGetWebLinkAvailable: boolean;
  isBulkUploadAvailable: boolean;

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectsService,
    private spinnerService: EyAppSpinnerService,
    private projectPermissionsService: ProjectPermissionsService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.spinnerService.withLoadingIndicator(this.projectService.getProjectInfo(this.projectId), this.destroy$).subscribe((p) => {
      this.project = p;
      this.isGetWebLinkAvailable = !ForbiddenProjectStatusesForGetWebLink.includes(p.status);
      this.projectParticipantsUpdate = this.projectPermissionsService.hasPermission(
        [ProjectActionsPermissions.updateProjectParticipants],
        this.project.userPermissions,
      );
      this.readProject = this.projectPermissionsService.hasPermission([ProjectActionsPermissions.readProject], this.project.userPermissions);
      this.updateProject = this.projectPermissionsService.hasPermission([ProjectActionsPermissions.updateProject], this.project.userPermissions);
      this.isBulkUploadAvailable = this.projectPermissionsService.hasPermission(
        [ProjectActionsPermissions.createResponses],
        this.project.userPermissions,
      );
      const activeTab = this.route.snapshot.queryParamMap.get(PROJECT_TAB_QUERY_PARAM_NAME);
      // this is to make sure that all the tabs are rendered before setting the active tabId
      if (activeTab) {
        of(true)
          .pipe(delay(100))
          .subscribe(() => {
            this.activeTabId = +activeTab;
          });
      }
    });

    this.getProjectStatus(this.project?.status);
    this.parentActiveTabId = +this.route.snapshot.queryParamMap.get(PARENT_ACTIVE_TAB_ID);
    this.projectPermissionsService
      .getPermissions()
      .pipe(takeUntil(this.destroy$))
      .subscribe((up) => {
        const otherPermissions = new UserOtherPermissions();
        up.other.ReadAllResponses ? this.otherUserPermissions.push(otherPermissions.ReadAllResponses) : null;
        up.other.DeleteAllResponses ? this.otherUserPermissions.push(otherPermissions.DeleteAllResponses) : null;
        up.other.ReadAllResponsesFiles ? this.otherUserPermissions.push(otherPermissions.ReadAllResponsesFiles) : null;
        this.projectPermissions = up.project;
        const projectPermissions = new UserProjectPermissions();
        up.project.UpdateAllProjectsParticipiants ? this.projectUserPermissions.push(projectPermissions.UpdateAllProjectsParticipiants) : null;
        up.project.ReadMyProjects ? this.projectUserPermissions.push(projectPermissions.ReadMyProjects) : null;
        this.globalWebLinkPermission = this.projectPermissionsService.hasPermission(
          [new UserProjectPermissions().UpdateAllProjectsParticipiants, new UserProjectPermissions().ReadMyProjects],
          this.projectUserPermissions,
        );
      });
  }

  getWebLink(projectId: string): void {
    const modalOptions: NgbModalOptions = {
      animation: false,
      backdrop: 'static',
      size: ModalWidth.MonstrouslyBig,
      windowClass: ModalVerticalSizeWindowClass.contentHeavyDoubleHeader,
    };

    const modalRef = this.modalService.open(GetWebLinkComponent, {
      ...modalOptions,
      windowClass: ModalVerticalSizeWindowClass.default,
      size: ModalWidth.Large,
    });

    modalRef.componentInstance.projectId = projectId;

    modalRef.result.then(
      (result) => {
        return;
      },
      () => {},
    );
  }

  getProjectStatus(statusId: number): any {
    const status = ProjectStatuses.find((s) => s.id === statusId);
    return status !== undefined ? status : UNKNOWN_PROJECT_STATUS;
  }

  initTab(tabChangeEventArgs: { nextId: MgrProjectTabs }): void {
    switch (tabChangeEventArgs.nextId) {
      case MgrProjectTabs.ProjectResponses:
        break;
      case MgrProjectTabs.TeamMembers:
        break;
      case MgrProjectTabs.ProjectInfo:
        break;
      default:
        console.log('Module Tab Mgr: Other options are not implemented yet');
    }
    this.activeTabId = tabChangeEventArgs.nextId;
  }
}
