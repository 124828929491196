<div class="d-flex fix-height">
  <div class="d-flex fix-height" [class]="{ 'flex-grow-1': cardView }">
    <div
      *ngFor="let a of actions | gridActionPrimary"
      (click)="onActionClick(a.actionType)"
      tabindex="0"
      role="button"
      [attr.aria-label]="a.actionType | gridActionToString"
    >
      <span [title]="a.toolTip ? a.toolTip : a.actionType.toString()">
        <i [ngClass]="a.icon" class="me-10 emp-icon"></i>
        <label class="ico_label ps-0 pe-10 m-0" *ngIf="cardView">{{ a.actionType | gridActionLabel }}</label>
      </span>
    </div>
  </div>
  <div>
    <ey-grid-menu
      *ngIf="(actions | gridActionSecondary)?.length > 0"
      [parentViewFlag]="parentViewFlag"
      [light]="lightTable"
      [relativePositioning]="cardView"
      [class]="{ 'flex-grow-1': !cardView }"
    >
      <div dropDownItems>
        <button
          [title]="a.toolTip ? a.toolTip : ''"
          *ngFor="let a of actions | gridActionSecondary"
          class="dropdown-item"
          (click)="onActionClick(a.actionType)"
        >
          <i [ngClass]="a.icon" class="emp-icon emp-icon-confident-grey"></i> {{ a.actionType | gridActionToString }}
        </button>
      </div>
    </ey-grid-menu>
  </div>
</div>
