import { Component, forwardRef, Input } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EyBaseToggleControlComponent } from '../ey-base-form-control/ey-base-toggle-control';

@Component({
  selector: 'ey-form-toggle',
  templateUrl: './ey-form-toggle.component.html',
  styleUrls: ['./ey-form-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyFormToggleComponent),
      multi: true,
    },
  ],
})
export class EyFormToggleComponent extends EyBaseToggleControlComponent {
  @Input() displayCustomLabels = false;
  @Input() boldText = false;
  @Input() showBorderTop = true;
  @Input() showBottomBorder = false;
  @Input() isDisabled = false;
  @Input() showDisabledTooltipText = true;
  constructor(controlContainer: ControlContainer) {
    super(controlContainer);
  }
}
