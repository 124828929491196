import { Pipe, PipeTransform } from '@angular/core';
import { UNKNOWN_VERSION_STATUS, VersionStatus, VersionStatuses } from '../../modules/version/version.const';
import { VersionPermissionsService } from '../../modules/version/version-permissions.service';
import { UserModulePermissions } from '../user-permissions.model';
import { IAction } from '../../modules/grid-actions/action.model';
import { ModuleTabs } from '../../modules/module.const';

@Pipe({
  name: 'getVersionAction',
})
export class GetVersionActionPipe implements PipeTransform {
  constructor(public versionPermissionsService: VersionPermissionsService) {}

  transform(
    versionStatusId: number,
    globalModulePermission: any,
    userModulePermission: string[],
    modulePermission: string[],
    moduleTab: ModuleTabs,
    isExternal: boolean,
  ): IAction[] {
    const versionStatus: VersionStatus = this.getVersionStatus(versionStatusId).label as VersionStatus;

    const userModulePermissions = new UserModulePermissions();
    globalModulePermission.CreateAllModulesFlow ? userModulePermission.push(userModulePermissions.CreateAllModulesFlow) : null;
    globalModulePermission.ReadAllModulesFlow ? userModulePermission.push(userModulePermissions.ReadAllModulesFlow) : null;
    globalModulePermission.UpdateAllModulesFlow ? userModulePermission.push(userModulePermissions.UpdateAllModulesFlow) : null;
    globalModulePermission.DeleteAllModulesFlow ? userModulePermission.push(userModulePermissions.DeleteAllModulesFlow) : null;

    const permissionList = modulePermission && userModulePermission ? modulePermission.concat(userModulePermission) : null;
    return this.versionPermissionsService.getVersionActionNavElements(versionStatus, moduleTab, permissionList, isExternal);
  }

  getVersionStatus(statusId: number): any {
    const status = VersionStatuses.find((s) => s.id === statusId);
    return status !== undefined ? status : UNKNOWN_VERSION_STATUS;
  }
}
