<ng-template #responseActions let-data>
  <app-grid-actions
    class="d-block w-100"
    [showLabelText]="true"
    [lightTable]="true"
    [id]="data.id"
    [actions]="getResponseActionNavElements()"
    (actionSelect)="onActionSelect($event, data)"
  ></app-grid-actions>
</ng-template>

<ey-modal-template [showDraftsPopup]="showDraftsPopup">
  <div header>
    <h2 class="modal__title" i18n="Submit Response Modal| Header Text">Submit Response</h2>
  </div>
  <div body>
    <h4 *ngIf="createNewResponseEnabled; else createNewResponseDisabled" class="fonts-col__subtitle-2 mb-3">
      You have {{ numberOfDraftResponses }} {{ numberOfDraftResponses == 1 ? 'draft' : 'drafts' }} pending. Continue with a draft or create a new
      response.
    </h4>
    <ng-template #createNewResponseDisabled>
      <h4 class="fonts-col__subtitle-2 mb-3">
        You can add only one response. You have a draft of the response, continue to work with the draft or delete the draft and create a new
        response.
      </h4>
    </ng-template>
    <ey-grid
      [tableBordersNone]="true"
      (totalDataCount)="displayNumberOfDraftResponses($event)"
      [lightTable]="true"
      [getData]="getData()"
      [showSearch]="false"
      [useCustomFiltering]="false"
      [gridState]="state"
      [hasSubTable]="false"
      [enablePaging]="false"
    >
      <ng-container header>
        <tr>
          <th class="col-3"><span class="eyfontbold">Respondent Name</span></th>
          <th class="col-4"><span class="eyfontbold">Response Date</span></th>
          <th class="col-3"><span class="eyfontbold">Response ID</span></th>
          <th class="col-2"><span class="eyfontbold">Actions</span></th>
        </tr>
      </ng-container>
      <ng-template eyTemplate="body" let-data>
        <td>
          <span *ngIf="data?.uploadId" class="me-10"
            ><i
              class="icon-ico_responses_upload emp-icon align-middle light-upload-icon"
              title="This is prepopulated response &#013;generated for you by &#013;EY project team."
            ></i
          ></span>
          <span class="eyfontlight">{{ data.respondentName }}</span>
        </td>
        <td>
          <span class="eyfontlight">{{ formatDate(data.responseDate) }}</span>
        </td>
        <td *ngIf="data.responseId; else noResponseId">
          <span class="eyfontlight">{{ data.responseId }}</span>
        </td>
        <ng-template #noResponseId>
          <td>
            <span class="eyfontlight">{{ data.uploadResponseId || '-' }}</span>
          </td>
        </ng-template>
        <td>
          <ng-container *ngTemplateOutlet="responseActions; context: { $implicit: data }"></ng-container>
        </td>
      </ng-template>
    </ey-grid>
  </div>
  <div footer class="d-flex w-100">
    <div class="d-flex w-100">
      <button *ngIf="enableCreateNewResponse()" class="btn me-20 btn--primary btn-border-color" type="button" (click)="onCreateNewResponse()">
        Create New Response
      </button>
    </div>
  </div>
</ey-modal-template>
