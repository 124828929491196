import { Injectable } from '@angular/core';

import { IFormPart } from './form-summary.model';
import { NumberFormatService } from '../../../../../builders/table-builder/table/number-format.service';
import { CATEGORY_PERCENT, SeparatorEnum } from '../../../../../builders/table-builder/controls/controls.const';
import { FormBuilderControlTypes } from '../../../../../builders/form-builder/form-builder-control.types';
export const NOT_SELECTED_TEXT = '- Not Selected -';

@Injectable({
  providedIn: 'root',
})
export class FormSummaryService {
  constructor(private numberFormatService: NumberFormatService) {}

  mapEmail(fp: IFormPart): string {
    const emails = JSON.parse(fp.value);
    return emails?.map((e) => e.email);
  }

  mapDropDown(fp: IFormPart): string {
    if (fp.value == null) {
      return NOT_SELECTED_TEXT;
    } else {
      return fp.options.find((t) => t.id === fp.value)?.text;
    }
  }

  mapCheckBox(fp: IFormPart): string {
    return fp.value === 'true' ? 'Checked' : 'Unchecked';
  }

  mapRadio(fp: IFormPart): IFormPart {
    let val = '';
    const selectedOption = fp.options.find((o) => o.id === fp.value);
    if (selectedOption !== undefined) {
      selectedOption.selected = true;
      val = '';
    } else {
      val = NOT_SELECTED_TEXT;
    }
    return { options: fp.options, value: val };
  }

  mapCheckBoxGroup(fp: IFormPart): IFormPart {
    let val = '';
    const selectedOptions = fp.options.filter((o) => fp.selectedItems.includes(o.id));
    if (selectedOptions.length > 0) {
      selectedOptions.forEach((o) => (o.selected = true));
      val = '';
    } else {
      val = NOT_SELECTED_TEXT;
    }
    return { options: fp.options, value: val };
  }

  mapNumberInput(fp: any): string {
    const fieldProperties = {
      ...fp,
      category: fp.category ?? fp.isPercentage ? CATEGORY_PERCENT : '',
      useThousandsSeparator: fp.thousandSeparator != null ? true : false,
      thousandsSeparator: SeparatorEnum[fp.thousandSeparator],
      decimalSeparator: SeparatorEnum[fp.decimalSeparator],
    };
    return this.numberFormatService.formatNumber(fp.value, fieldProperties, true);
  }

  mapFormPartsToView(formParts: IFormPart[]): IFormPart[] {
    return formParts?.map((fp) => {
      switch (fp.type) {
        case FormBuilderControlTypes.numberInput:
          fp.value = this.mapNumberInput(fp);
          break;
        case FormBuilderControlTypes.eyEmailLookup:
          fp.value = this.mapEmail(fp);
          break;
        case FormBuilderControlTypes.dropDown:
          fp.value = this.mapDropDown(fp);
          break;
        case FormBuilderControlTypes.checkbox:
          fp.value = this.mapCheckBox(fp);
          break;
        case FormBuilderControlTypes.radioButton:
          const mr = this.mapRadio(fp);
          fp.value = mr.value;
          fp.options = mr.options;
          break;
        case FormBuilderControlTypes.checkboxGroup:
          const mcr = this.mapCheckBoxGroup(fp);
          fp.value = mcr.value;
          fp.options = mcr.options;
          break;
        default:
          break;
      }
      return { ...fp };
    });
  }
}
