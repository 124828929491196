import { Component, OnInit } from '@angular/core';
import { SummarySectionBaseComponent } from '../summary-section-base.component';

@Component({
  selector: 'app-letter-summary',
  templateUrl: './letter-summary.component.html',
  styleUrls: ['./letter-summary.component.scss'],
})
export class LetterSummaryComponent extends SummarySectionBaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}

  get isHidden(): boolean {
    return this.page.moduleFlowPage?.isHidden || false;
  }
}
