import { MappingField, MappingFieldType } from '../../../modules/version/version-mapping-data.model';

export const IS_SELECTED = 'IsSelected';
export const EMAIL_LOOKUP = 'EmailLookup';

export enum ToolNames {
  Decision = 'decision',
  Upload = 'upload',
  Table = 'table',
  Form = 'form',
  YesNo = 'yes-no',
  List = 'list',
  Mail = 'mail',
  Letter = 'letter',
}

export enum FormToolControlMapper {
  Mail = '10',
  Radio = '7',
}

export enum ConditionTarget {
  Custom,
  Empty,
  Flow,
}

export interface Condition {
  condition: string;
  sourceControl: string;
  target: ConditionTarget;
  targetControl: string;
  targetControlType: string;
}

export enum Conditions {
  equals = 'equals',
  notEquals = 'not equals',
  lessThan = 'less than',
  greaterThan = 'greater than',
  lessThanOrEqual = 'less than or equal',
  greaterThanOrEqual = 'greater than or equal',
  isEmpty = 'is empty',
  isNotEmpty = 'is not empty',
  selected = 'selected',
  unselected = 'unselected',
}

export interface ConditionDropdownItem {
  name: string;
}

export const CONDITION_OPTIONS: {
  conditionGeneric: Array<ConditionDropdownItem>;
  conditionText: Array<ConditionDropdownItem>;
  conditionCheckbox: Array<ConditionDropdownItem>;
  conditionCheckboxGroup: Array<ConditionDropdownItem>;
} = {
  conditionGeneric: [
    { name: Conditions.equals },
    { name: Conditions.notEquals },
    { name: Conditions.lessThan },
    { name: Conditions.greaterThan },
    { name: Conditions.lessThanOrEqual },
    { name: Conditions.greaterThanOrEqual },
    { name: Conditions.isEmpty },
    { name: Conditions.isNotEmpty },
  ],
  conditionText: [{ name: Conditions.equals }, { name: Conditions.notEquals }, { name: Conditions.isEmpty }, { name: Conditions.isNotEmpty }],
  conditionCheckbox: [{ name: Conditions.equals }, { name: Conditions.notEquals }, { name: Conditions.selected }, { name: Conditions.unselected }],
  conditionCheckboxGroup: [{ name: Conditions.equals }, { name: Conditions.notEquals }],
};

export const CONDITIONS_REQUIRING_THAN_TITLE = [
  Conditions.greaterThan,
  Conditions.greaterThanOrEqual,
  Conditions.lessThan,
  Conditions.lessThanOrEqual,
];

const CUSTOM_DATE_TITLE = 'Custom Date';
const CUSTOM_NUMBER_TITLE = 'Custom Number';
const CUSTOM_TEXT_TITLE = 'Custom Text';

export const CUSTOM_TEXT: MappingField = { name: CUSTOM_TEXT_TITLE, type: MappingFieldType.Text };
export const CUSTOM_NUMBER: MappingField = { name: CUSTOM_NUMBER_TITLE, type: MappingFieldType.Number };
export const CUSTOM_DATE: MappingField = { name: CUSTOM_DATE_TITLE, type: MappingFieldType.Date };

export const TARGET_HIDDEN_CONDITIONS = [Conditions.isEmpty, Conditions.isNotEmpty, Conditions.selected, Conditions.unselected];

export const CONDITION_META = {
  source: {
    title: 'If (source)',
    errorMsg: "Please fill the name of option, it can't be empty.",
    required: false,
  },
  condition: {
    title: 'Is (condition)',
    errorMsg: "Please fill the name of option, it can't be empty.",
    required: false,
  },
  target: {
    to: {
      title: 'To (compare with value)',
      required: false,
    },
    than: {
      title: 'Than (compare with value)',
      required: false,
    },
  },
};

export const CUSTOM_VALUE_META = {
  text: {
    title: CUSTOM_TEXT_TITLE,
  },
  number: {
    title: CUSTOM_NUMBER_TITLE,
  },
  date: {
    title: CUSTOM_DATE_TITLE,
    errorMsg: 'Please enter a valid date',
  },
};
