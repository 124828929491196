import { PillType } from '../shared/components/ey-pill/ey-pill.component';

// Response Status
export const DRAFT_RESPONSE_STATUS = 2;
export const COMPLETED_RESPONSE_STATUS = 3;
export const INPROGRESS_RESPONSE_STATUS = 1;

export const TEST_STATUS_ID = 1;
export const TEST_INACTIVE_STATUS_ID = 2;
export const TEST_CLOSED_STATUS_ID = 3;
export const TEST_EXPIRED_STATUS_ID = 4;
export const LIVE_APPROVAL_REQUIRED_STATUS_ID = 5;
export const LIVE_IN_APPROVAL_STATUS_ID = 6;
export const LIVE_STATUS_ID = 7;
export const LIVE_INACTIVE_STATUS_ID = 8;
export const LIVE_CLOSED_STATUS_ID = 9;
export const LIVE_EXPIRED_STATUS_ID = 10;

export enum ProjectStatus {
  Test = 'Test',
  TestInactive = 'TestInactive',
  TestClosed = 'TestClosed',
  TestExpired = 'TestExpired',
  LiveApprovalRequired = 'LiveApprovalRequired',
  LiveInApproval = 'LiveInApproval',
  Live = 'Live',
  LiveInactive = 'LiveInactive',
  LiveClosed = 'LiveClosed',
  LiveExpired = 'LiveExpired',
}

export enum ProjectTabs {
  myProjects,
  mgrProjects,
}

export interface IProjectStatus {
  pillType: string;
  label: ProjectStatus;
  labelText?: string;
  toolTip: string;
  id: number;
}

export interface IAllProjectsStatus {
  name: string;
  id: number;
}

export interface IProjectApprovalStatus {
  pillType: string;
  label: ProjectApprovalStatus;
  labelText: string;
  id: number;
}

export const UNKNOWN_PROJECT_STATUS = { color: PillType.red, label: 'UNKNOWN', toolTip: '', id: 11 };

export const ProjectStatuses: IProjectStatus[] = [
  {
    pillType: PillType.orange,
    label: ProjectStatus.Test,
    labelText: 'Test',
    toolTip: 'Test Project is Open, people you have authorized using "Share Project" can respond',
    id: TEST_STATUS_ID,
  },
  {
    pillType: PillType.orange,
    label: ProjectStatus.TestInactive,
    labelText: 'Test: Inactive',
    toolTip: 'No proper version of Module connected. People can\'t respond to the project until you "Edit Project" to select a proper Module Version',
    id: TEST_INACTIVE_STATUS_ID,
  },
  {
    pillType: PillType.orange,
    label: ProjectStatus.TestClosed,
    labelText: 'Test: Closed',
    toolTip:
      "People can't respond to your project. If they try to access the project link, they see closed message. If required, you may reopen the Project.",
    id: TEST_CLOSED_STATUS_ID,
  },
  {
    pillType: PillType.red,
    label: ProjectStatus.TestExpired,
    labelText: 'Test: Expired',
    toolTip: "Module connected with Project was deleted. People can't respond to your project after expiry date",
    id: TEST_EXPIRED_STATUS_ID,
  },
  {
    pillType: PillType.green,
    label: ProjectStatus.LiveApprovalRequired,
    labelText: 'Live: Approval Required',
    toolTip: 'Project created, please initiate Approval to proceed',
    id: LIVE_APPROVAL_REQUIRED_STATUS_ID,
  },
  {
    pillType: PillType.green,
    label: ProjectStatus.LiveInApproval,
    labelText: 'Live: In Approval',
    toolTip: 'Project created, please complete Approval to proceed',
    id: LIVE_IN_APPROVAL_STATUS_ID,
  },
  {
    pillType: PillType.green,
    label: ProjectStatus.Live,
    labelText: 'Live',
    toolTip: 'Live Project is Open, people you have authorized can respond to project',
    id: LIVE_STATUS_ID,
  },
  {
    pillType: PillType.red,
    label: ProjectStatus.LiveInactive,
    labelText: 'Live: Inactive',
    toolTip: 'No proper version of Module connected. People can\'t respond to the project until you "Edit Project" to select a proper Module Version',
    id: LIVE_INACTIVE_STATUS_ID,
  },
  {
    pillType: PillType.red,
    label: ProjectStatus.LiveClosed,
    labelText: 'Live: Closed',
    toolTip:
      "People can't respond to your project. If they try to access the project link, they see closed message. If required, you may reopen the Project.",
    id: LIVE_CLOSED_STATUS_ID,
  },
  {
    pillType: PillType.red,
    label: ProjectStatus.LiveExpired,
    labelText: 'Live: Expired',
    toolTip: "Module connected with Project was deleted. People can't respond to your project after expiry date",
    id: LIVE_EXPIRED_STATUS_ID,
  },
];

export const AllProjectsStatus: IAllProjectsStatus[] = [
  {
    name: 'Test',
    id: TEST_STATUS_ID,
  },
  {
    name: 'Test: Inactive',
    id: TEST_INACTIVE_STATUS_ID,
  },
  {
    name: 'Test: Closed',
    id: TEST_CLOSED_STATUS_ID,
  },
  {
    name: 'Test: Expired',
    id: TEST_EXPIRED_STATUS_ID,
  },
  {
    name: 'Live: Approval Required',
    id: LIVE_APPROVAL_REQUIRED_STATUS_ID,
  },
  {
    name: 'Live: In Approval',
    id: LIVE_IN_APPROVAL_STATUS_ID,
  },
  {
    name: 'Live',
    id: LIVE_STATUS_ID,
  },
  {
    name: 'Live: Inactive',
    id: LIVE_INACTIVE_STATUS_ID,
  },
  {
    name: 'Live: Closed',
    id: LIVE_CLOSED_STATUS_ID,
  },
  {
    name: 'Live: Expired',
    id: LIVE_EXPIRED_STATUS_ID,
  },
];

export enum TargetAudience {
  internal = 1,
  external,
}

export enum TabActive {
  first = 0,
  second = 1,
  third = 2,
  fourth = 3,
  fifth = 4,
  last = 5,
}

export enum Parent {
  project = 0,
  module = 1,
}

export enum ProjectType {
  test = 1,
  live,
}

export enum ProjectTypeText {
  test = 'Test Project',
  live = 'Live Project',
}

export enum TargetAudienceText {
  internal = 'EY Only',
  external = 'Client and EY',
}

export const PROJECT_ACTION_CREATE_FOR_VERSION = 1;

export enum ProjectFormPatchType {
  none,
  createFromDesigner,
}

export enum ProjectApprovalStatus {
  ApprovalNotDone = 'ApprovalNotDone',
  ApprovalInProgress = 'ApprovalInProgress',
  ApprovalCompleted = 'ApprovalCompleted',
}

export enum NavPlacement {
  primary,
  secondary,
  none,
}

export const PROJECT_MSGS = {
  DELETE_CONFIRMATION_MSG: 'This project will be deleted along with corresponding data, including responses gathered from end users.',
  DELETE_DISMISS_MSG_IN_USE:
    'Projects in use cannot be deleted. In order to delete this project, you need to close it first. "Close Project" option can be found in the ellipsis menu (<i class="icon-ico_more"></i>)',
  CLOSE_MSG:
    'This project will be temporarily closed and adding responses won\'t be possible. You can always reopen the project using the "Reopen Project" option (<i class="icon-ico_lock material-icons mi-125rem"></i>).',
  REOPEN_MSG: 'Project will be available for end users to add responses.',
};

export enum CloseReopenOptions {
  Close = 'Close',
  Reopen = 'Reopen',
}

export enum PublishLiveStatus {
  PublishLiveNotDone = 'ApprovalNotDone',
  PublishLiveInProgress = 'ApprovalInProgress',
  PublishLiveCompleted = 'ApprovallCompleted',
}

export interface IPublishLiveStatus {
  pillType: string;
  label: PublishLiveStatus;
  labelText: string;
  id: number;
}

export const PUBLISH_LIVE_NOT_DONE_ID = 1;
export const PUBLISH_LIVE_IN_PROGRESS = 2;
export const PUBLISH_LIVE_COMPLETED = 3;

export const PublishLiveStatuses: IPublishLiveStatus[] = [
  {
    pillType: PillType.orange,
    label: PublishLiveStatus.PublishLiveNotDone,
    labelText: 'Approval: Not Done',
    id: PUBLISH_LIVE_NOT_DONE_ID,
  },
  {
    pillType: PillType.orange,
    label: PublishLiveStatus.PublishLiveInProgress,
    labelText: 'Approval: In Progress',
    id: PUBLISH_LIVE_IN_PROGRESS,
  },
  {
    pillType: PillType.green,
    label: PublishLiveStatus.PublishLiveCompleted,
    labelText: 'Approval: Completed',
    id: PUBLISH_LIVE_COMPLETED,
  },
];

export const ForbiddenProjectStatusesForGetWebLink: number[] = [
  TEST_EXPIRED_STATUS_ID,
  LIVE_APPROVAL_REQUIRED_STATUS_ID,
  LIVE_IN_APPROVAL_STATUS_ID,
  LIVE_EXPIRED_STATUS_ID,
];
