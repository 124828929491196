import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalResult } from 'src/app/shared/components/ey-modal-template/ey-modal-result.enum';
import { ModuleVersionVideo } from '../module-version-video.model';
import { VideoSourceService } from '../video-source.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ExtensionTypes, FileTypes } from '../../../shared/components/ey-file-upload/ey-file-upload.service';
import { EyFileUploadComponent, FileListItem } from '../../../shared/components/ey-file-upload/ey-file-upload.component';
import { FileDescriptor, FileUploadResult } from '../../file-upload.service';
import { EyAppSpinnerService } from '../../../shared/components/ey-app-spinner/ey-app-spinner.service';
import { EyInfoDialogService } from 'src/app/shared/components/ey-info-dialog/ey-info-dialog.service';
import { InfoDialogTypes } from 'src/app/shared/components/ey-info-dialog/info-dialog-types.enum';

@Component({
  selector: 'app-video-manager-modal',
  templateUrl: './video-manager.component.html',
  styleUrls: ['./video-manager.component.scss'],
})
export class VideoManagerModalComponent implements OnInit, OnDestroy {
  @ViewChild('addButton') addButton!: ElementRef;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @Input() moduleId: string;
  @Input() versionId: string;
  @ViewChild(EyFileUploadComponent) fileUploadCmp: EyFileUploadComponent;

  acceptedFormats = '.webm,.mp4';
  warningText = 'Note: Please ensure that any externally-facing video has been approved by SCORE review process';
  fileExtension = [FileTypes.video];
  files: FileListItem[] = [];
  docType = ExtensionTypes.videoFile;
  uploadedFiles: FileUploadResult[] = [];
  items: any[] = [];

  isControls = true;
  isAutoplay = false;

  destroy$: Subject<void> = new Subject<void>();

  selectedVideoId: string;
  versionVideos: ModuleVersionVideo[] = [];

  // file: File;
  selectedTab = 1;
  video: FileListItem;
  uploadedFileId: any;
  result: string;
  dataLoaded = true;

  constructor(
    public activeModal: NgbActiveModal,
    private videoService: VideoSourceService,
    private dialogService: EyInfoDialogService,
    private spinnerService: EyAppSpinnerService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  selectTab(tabNumber: number): void {
    this.selectedTab = tabNumber;
  }

  loadData(): void {
    this.spinnerService.withLoadingIndicator(this.videoService.getVideosForModule(this.moduleId), this.destroy$).subscribe((v) => {
      this.versionVideos = v;
      this.dataLoaded = true;
    });
  }

  deleteFile(file: any): void {
    const deletedItem = this.uploadedFiles.find((f) => f.tempId === file.fileId);
    this.items = this.items.filter((f) => f.tempId !== file.fileId);
    this.uploadedFiles = this.uploadedFiles.filter((f) => f.tempId !== file.fileId);
    this.fileUploadCmp.delete(file);
    if (deletedItem) {
      this.deleteVideo(deletedItem.fileId);
    }
  }

  onInsertVideoFromGallery(video: any): void {
    const videoToInsert = this.versionVideos.find((videos) => videos.id === video.id);
    this.activeModal.close({ video: videoToInsert, isControls: this.isControls, isAutoplay: this.isAutoplay });
  }

  onDeleteVideoFromGallery(video: any): void {
    this.deleteVideo(video.id);
  }

  deleteVideo(videoId: string): void {
    this.videoService
      .deleteVideo(videoId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((v) => {
        if (!v.isDeleted) {
          const title = "<p>Video can't be deleted due to being used in following Tools:</p>";
          const formattedRefs = `<ul>${v.existingVideoRefs?.map(({ versionName, shapeName }) => `<li>${versionName} / ${shapeName}</li>`).join('')}</ul>`;

          this.dialogService.openInfoDialog(title + formattedRefs, 'Video not deleted', InfoDialogTypes.dismiss);
        } else {
          this.loadData();
        }

        this.uploadedFileId = null;
      });
  }

  insertVideo(): void {
    if (this.uploadedFileId) {
      const videoToInsert = this.versionVideos.find((videos) => videos.id === this.uploadedFileId);
      this.activeModal.close({ video: videoToInsert, isControls: this.isControls, isAutoplay: this.isAutoplay });
    } else {
      this.activeModal.close(ModalResult.dismiss);
    }
  }

  cancel(): void {
    this.activeModal.close(ModalResult.dismiss);
  }

  async upload(video: FileListItem) {
    this.dataLoaded = false;
    this.video = video;
    if (!this.video.file) {
      return;
    }
    const isVideoFileUnique = await this.videoService.isVideoUnique(this.moduleId, this.video.file.name).pipe(takeUntil(this.destroy$)).toPromise();
    if (!isVideoFileUnique) {
      this.fileUploadCmp.setError(this.video.fileId, 'Video already exists in Gallery. Please select from Video Gallery.');
      return;
    }

    const fileUploadChunkSize = 4 * 1024 * 1024; // BE accepts 4 Mb max as one chunk
    const chunks = Math.ceil(this.video.file.size / fileUploadChunkSize);

    for (let i = 0; i < chunks; i++) {
      const start = i * fileUploadChunkSize;
      const end = (i + 1) * fileUploadChunkSize >= this.video.file.size ? this.video.file.size : (i + 1) * fileUploadChunkSize;

      const chunk = this.video.file.slice(start, end);

      const formData = new FormData();
      formData.append('uploadFileId', this.video.fileId);
      formData.append('fileTotalSize', `${this.video.file.size}`);

      formData.append('file', chunk, `${this.video.file.name}`);
      formData.append('totalChunks', chunks.toString());
      formData.append('chunkIndex', i.toString());
      const result =
        (this.result =
        this.uploadedFileId =
          await this.videoService.uploadVideo(this.moduleId, this.versionId, formData).pipe(takeUntil(this.destroy$)).toPromise());
    }
    this.uploadedFiles.push({ fileId: this.video.fileId, tempId: this.video.fileId, fileDescriptor: FileDescriptor.VideoContainerName });
    this.fileUploadCmp.markFileAsUploaded({
      fileId: this.video.fileId,
      tempId: this.video.fileId,
      fileDescriptor: FileDescriptor.VideoContainerName,
      fileName: this.result,
    });
    this.items.push({ fileId: this.video.fileId, name: this.video.file.name, type: this.video.file.type, tempId: this.result });
    this.loadData();
  }
}
