import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Area } from '../models/dictionaries/area.model';
import { Country } from '../models/dictionaries/country.model';
import { ServiceLine } from '../models/dictionaries/serviceline.model';
import { SubServiceLine } from '../models/dictionaries/subserviceline.model';
import { MarketSegment } from '../models/dictionaries/marketsegment.model';
import { Purpose } from '../models/dictionaries/purpose.model';
import { TaxType } from '../models/dictionaries/taxtype.model';
import { Instance } from '../models/dictionaries/instance.model';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  private baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) {}

  getArea(): Observable<Area[]> {
    return this.httpClient.get<Area[]>(`${this.baseUrl}/dictionary/area`);
  }

  getCountry(areaId?: number): Observable<Country[]> {
    if (areaId) {
      return this.httpClient.get<Country[]>(`${this.baseUrl}/dictionary/country?areaId=${areaId}`);
    } else {
      return this.httpClient.get<Country[]>(`${this.baseUrl}/dictionary/country`);
    }
  }

  getServiceLine(): Observable<ServiceLine[]> {
    return this.httpClient.get<ServiceLine[]>(`${this.baseUrl}/dictionary/service-line`);
  }

  getSubServiceLine(serviceLineId?: number): Observable<SubServiceLine[]> {
    if (serviceLineId) {
      return this.httpClient.get<SubServiceLine[]>(`${this.baseUrl}/dictionary/sub-service-line?serviceLineId=${serviceLineId}`);
    } else {
      return this.httpClient.get<SubServiceLine[]>(`${this.baseUrl}/dictionary/sub-service-line`);
    }
  }

  getMarketSegment(areaId?: number): Observable<MarketSegment[]> {
    if (areaId) {
      return this.httpClient.get<MarketSegment[]>(`${this.baseUrl}/dictionary/market-segment?areaId=${areaId}`);
    } else {
      return this.httpClient.get<MarketSegment[]>(`${this.baseUrl}/dictionary/market-segment`);
    }
  }

  getPurposes(): Observable<Purpose[]> {
    return this.httpClient.get<Purpose[]>(`${this.baseUrl}/dictionary/purpose`);
  }

  getTaxTypes(): Observable<TaxType[]> {
    return this.httpClient.get<TaxType[]>(`${this.baseUrl}/dictionary/tax-type`);
  }

  getCountriesDataResidencyType(areaId?: number): Observable<Country[]> {
    if (areaId) {
      return this.httpClient.get<Country[]>(`${this.baseUrl}/dictionary/country-data-residency-type?areaId=${areaId}`);
    } else {
      return this.httpClient.get<Country[]>(`${this.baseUrl}/dictionary/country-data-residency-type`);
    }
  }

  getCountriesFromAreas(areaIds: Array<number>): Observable<Country[]> {
    return this.httpClient.post<Country[]>(`${this.baseUrl}/dictionary/countries-from-areas`, areaIds);
  }

  getSubServiceLinesFromServiceLines(serviceLineIds: Array<number>): Observable<SubServiceLine[]> {
    return this.httpClient.post<SubServiceLine[]>(`${this.baseUrl}/dictionary/sub-service-lines-from-service-lines`, serviceLineIds);
  }

  getMarketSegmentsFromAreas(areaIds: Array<number>): Observable<MarketSegment[]> {
    return this.httpClient.get<MarketSegment[]>(`${this.baseUrl}/dictionary/market-segments-from-areas?areaIds=${areaIds.toString()}`);
  }

  getEmpowermentInstances(): Observable<Instance[]> {
    return this.httpClient.get<Instance[]>(`${this.baseUrl}/dictionary/instances`);
  }
}
