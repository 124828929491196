<div class="d-flex justify-content-start">
  <div class="d-inline-flex flex-column text-left">
    <label class="checkbox mb-10" [for]="'chkbox_' + ctrl_id">
      <input
        class="checkbox__input"
        type="checkbox"
        [class]="{ error: showError }"
        [id]="'chkbox_' + ctrl_id"
        (change)="onCheckboxChange($event)"
        [checked]="selectedValue"
        [disabled]="isDisabled"
      />
      <span class="checkbox__checkmark"></span>
      <p class="checkbox__label checkbox-title">{{ value.title }}</p>
      <p *ngIf="value.hint" [innerHtml]="value.hint | sanitizeHtml" class="ms-10 eyfontlight checkbox-hint mb-0"></p>
    </label>
  </div>
</div>
<label *ngIf="showError" class="checkbox__errmsg mb-20">
  <i class="err_icon icon-ico_error_toast"></i>
  <span class="">{{ meta.errorMsg }}</span>
</label>
