<form [formGroup]="form">
  <div class="d-flex flex-fill flex-column">
    <p class="eyfontbold mb-0">Entering Dates</p>
    <div class="d-flex flex-fill flex-column">
      <p class="fonts-col__body-copy mb-0">
        Enter your date in <span class="eyfontbold">{{ currentBrowserDateFormat }} </span>format. You always should use this format to provide date in
        Table tool. Below you are able to set how dates will be presented to end users.
      </p>
    </div>
  </div>

  <div class="d-flex flex-fill flex-column mt-30">
    <p class="eyfontbold mb-0">Displaying Dates</p>
    <div class="d-flex flex-fill flex-column">
      <p class="fonts-col__body-copy mb-10">You can select how dates will be presented to end users</p>
      <ey-drop-down
        formControlName="dateFormat"
        [meta]="meta.format"
        [values]="dateFormats"
        [defaultValue]="defDateFormatNumber"
        noInfo="true"
        class="mb-20"
      ></ey-drop-down>
    </div>
  </div>
</form>

<div *ngIf="showFormulaSection">
  <hr />
  <p class="eyfontbold mb-10">Formulas</p>
  <p class="fonts-col__body-copy mb-0">You can use formulas to operations on dates, ie.:</p>
  <ul class="p-0 mb-10">
    <li class="fonts-col__body-copy">- Date addition "=dateadd(A1,5)"</li>
    <li class="fonts-col__body-copy">- Date substraction "=datesub(D1,2)"</li>
  </ul>

  <a class="preview-link mt-10" target="_blank" href="https://sites.ey.com/sites/Empowerment/Empowerment%20Wiki/Formulas%20on%20Table%20tool.aspx"
    ><i class="emp-icon icon-ico_new_window"></i> <span class="ms-0">Learn more</span></a
  >
  <div></div>
</div>
