import { Component, Input } from '@angular/core';
import { Breadcrumb } from './breadcrumb';

@Component({
  selector: 'ey-breadcrumbs',
  templateUrl: './ey-breadcrumb.component.html',
  styleUrls: ['./ey-breadcrumb.component.scss'],
})
export class EyBreadcrumbComponent {
  @Input() breadcrumbs: Breadcrumb[] = [];
}
