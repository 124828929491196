import { UserOtherPermissions } from 'src/app/core/user-permissions.model';
import { IAction } from '../../grid-actions/action.model';
import { NavPlacement } from '../../project.const';
import { ResponseActionsPermissions } from '../../responses/response-permissions.const';

export enum BulkDownloadActionTypes {
  Download = 'Download',
  Retry = 'Retry',
}
export const BULK_DOWNLOAD_RESPONSE_ACTIONS: IAction[] = [
  {
    actionType: BulkDownloadActionTypes.Download,
    navPlacement: NavPlacement.primary,
    icon: 'icon-ico_responses_download',
    requiredPermissions: [ResponseActionsPermissions.readResponses, new UserOtherPermissions().ReadAllResponses],
    useActionTypeAsDescription: true,
  },
  {
    actionType: BulkDownloadActionTypes.Retry,
    navPlacement: NavPlacement.primary,
    icon: 'icon-ico_refresh',
    requiredPermissions: [ResponseActionsPermissions.readResponses, new UserOtherPermissions().ReadAllResponses],
    useActionTypeAsDescription: true,
  },
];
