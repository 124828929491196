import { ErrorHandler, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CommonModule } from '@angular/common';
import { ModalPopupComponent } from './components/modal-popup/modal-popup.component';
import { PrivacyNoticeComponent } from './components/privacy-notice/privacy-notice.component';
import { EnvironmentComponent } from './components/environment/environment.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { UserPermissionsService } from './user-permissions.service';
import { UserProfileComponent } from './components/topbar/user-profile/user-profile.component';
import { ToolBarComponent } from './components/tool-bar/tool-bar.component';
import { SaveComponent } from './save/save.component';
import { UnsavedChangesComponent } from './save/unsaved-changes/unsaved-changes.component';
import { ComponentsModule } from '../shared/components/components.module';
import { FormsModule } from '@angular/forms';
import { MultiselectFilterComponent } from './components/multiselect-filter/multiselect-filter.component';
import { LocalStorageService } from './local-storage.service';
import { PageTitleService } from './services/page-title.service';
import { AutoLogoutPopupComponent } from './components/auto-logout-popup/auto-logout-popup.component';
import { NumberRankPipe, NumberSystemPipe } from './pipes/number-rank.pipe';
import { GetVersionActionPipe } from './pipes/get-version-action.pipe';
import { GridActionPrimaryPipe } from './pipes/grid-action-primary.pipe';
import { GridActionSecondaryPipe } from './pipes/grid-action-secondary.pipe';
import { GridActionLabelPipe } from './pipes/grid-action-label.pipe';
import { GridActionToStringPipe } from './pipes/grid-action-to-string.pipe';
import { PropertyToTemplatePipe } from './pipes/property-to-template.pipe';
import { VideoManagerModalComponent } from './video/videoManagerComponent/video-manager.component';

const declarations = [
  ModalPopupComponent,
  PrivacyNoticeComponent,
  EnvironmentComponent,
  TopbarComponent,
  NavbarComponent,
  ToolBarComponent,
  SaveComponent,
  UnsavedChangesComponent,
  UserProfileComponent,
  MultiselectFilterComponent,
  NumberRankPipe,
  NumberSystemPipe,
  GetVersionActionPipe,
  GridActionPrimaryPipe,
  GridActionSecondaryPipe,
  GridActionLabelPipe,
  GridActionToStringPipe,
  PropertyToTemplatePipe,
  VideoManagerModalComponent,
];

@NgModule({
  imports: [NgbModule, CommonModule, ComponentsModule, FormsModule, RouterModule],
  declarations: [...declarations, AutoLogoutPopupComponent],
  providers: [UserPermissionsService, LocalStorageService, PageTitleService],
  exports: [...declarations],
})
export class CoreModule {}
