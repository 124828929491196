import { Directive, HostListener } from '@angular/core';

// directive for support of hieroglyphic text input (japanese, chinese, etc.) from Input Method Editors (IME)
@Directive({
  selector: '[hieroglyphicInputSupport]',
})
export class HieroglyphicInputSupportDirective {
  @HostListener('compositionstart', ['$event'])
  compositionStart(event) {
    event.target.isComposing = true;
  }

  @HostListener('compositionend', ['$event'])
  compositionEnd(event) {
    event.target.isComposing = false;
    event.target.dispatchEvent(new Event('input'));
  }
}
