import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VersionStatus } from 'src/app/designer/workflow-designer/actions/actions.meta';
import { SAVE_DISABLED_STATUSES } from './save-disabled-statuses.meta';
import { saveToggleAutoSave } from './save.actions';
import { Save } from './saving-state';

@Component({
  selector: 'app-save',
  templateUrl: './save.component.html',
  styleUrls: ['./save.component.scss'],
})
export class SaveComponent implements OnInit, OnDestroy {
  @Input() versionStatus: VersionStatus;
  @Input() readOnlyState = false;

  @Output() save = new EventEmitter<void>();

  isAutoSave = false;

  private destroy$ = new Subject<void>();

  constructor(private store: Store<{ save: Save }>) {}

  get showAutoSave(): boolean {
    return this.versionStatus && !SAVE_DISABLED_STATUSES.includes(this.versionStatus);
  }

  get saveDisabled(): boolean {
    return this.isAutoSave || !this.showAutoSave;
  }

  ngOnInit(): void {
    this.store
      .select('save')
      .pipe(takeUntil(this.destroy$))
      .subscribe((s) => (this.isAutoSave = s.autoSave));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onChanges(val: boolean): void {
    this.store.dispatch(saveToggleAutoSave({ autoSave: val }));
  }

  onSaveClick(): void {
    if (!this.saveDisabled) {
      this.save.next();
    }
  }
}
