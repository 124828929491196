<div class="pagination-wrapper mt-0 pt-0 d-flex justify-content-end pb-2">
  <ul class="pagination-block d-flex justify-content-end" [class.light-background]="lightPaginationPanel">
    <li class="me-1">
      <form>
        <ey-drop-down
          [lightBackgroundPaginationDropdown]="lightPaginationPanel"
          class="d-block"
          [ngModelOptions]="{ standalone: true }"
          [dark]="!lightPaginationPanel"
          [dropUp]="true"
          labelName="text"
          [values]="pageSizes"
          [(ngModel)]="currentPageSize"
          (ngModelChange)="onFilterItemClick($event)"
          [meta]="{ title: '' }"
        >
        </ey-drop-down>
      </form>
    </li>
    <li class="pagination-block__page pagination-block__page--first" [ngClass]="{ 'paging--item__disabled': this.leftDisabled }">
      <button arial-label="go to first page" [class.darkBtn]="lightPaginationPanel" [disabled]="this.leftDisabled" (click)="start()">
        <i class="material-icons">first_page</i>
      </button>
    </li>
    <li class="pagination-block__page pagination-block__page--previous" [ngClass]="{ 'paging--item__disabled': this.leftDisabled }">
      <button arial-label="go to previous page" [class.darkBtn]="lightPaginationPanel" [disabled]="this.leftDisabled" (click)="previous()">
        <i class="material-icons">chevron_left</i>
      </button>
    </li>
    <li class="pagination-block__page pagination-block__page--numeration border-0">
      <input
        arial-label="go to page"
        type="number"
        class="form-control"
        [class.light-background]="lightPaginationPanel"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="setPageNumber($event)"
        [(ngModel)]="currentPage"
      />
      <span class="pagination-block__max-page" [class.darkBtn]="lightPaginationPanel">/ {{ totalPages }}</span>
    </li>
    <li class="pagination-block__page pagination-block__page--next" [ngClass]="{ 'paging--item__disabled': this.rightDisabled }">
      <button arial-label="go to next page" [class.darkBtn]="lightPaginationPanel" [disabled]="this.rightDisabled" (click)="next()">
        <i class="material-icons">chevron_right</i>
      </button>
    </li>
    <li class="pagination-block__page pagination-block__page--last" [ngClass]="{ 'paging--item__disabled': this.rightDisabled }">
      <button arial-label="go to last page" [class.darkBtn]="lightPaginationPanel" [disabled]="this.rightDisabled" (click)="end()">
        <i class="material-icons">last_page</i>
      </button>
    </li>
  </ul>
</div>
