<ng-template #responseActions let-data>
  <app-grid-actions
    class="d-block w-100"
    [showLabelText]="true"
    [lightTable]="false"
    [id]="data.id"
    [actions]="getResponseActionNavElements(data)"
    (actionSelect)="onActionSelect($event, data)"
  ></app-grid-actions>
</ng-template>

<ng-template #noCount>
  <span class="document-count"></span>
</ng-template>

<div class="d-flex w-100 pt-3 mb-3">
  <a class="back-button d-flex m-0 p-0" [routerLink]="'/'">
    <i class="emp-icon icon-ico_arrow_left me-10"></i>
    <span class="back-button--text pe-20">Back</span>
  </a>
  <div class="flex-grow-1 text-white ms-20" i18n="Dashboard| My Responses"><h1 class="fonts-col__heading-1">My Responses</h1></div>
</div>
<ey-grid [showSearch]="false" [useCustomFiltering]="true" [gridState]="gridState" [getData]="getData" [showFilter]="false" [enablePaging]="true">
  <ng-container filter>
    <form class="col col-12">
      <ey-multi-select
        [showDarkScroll]="true"
        [floatingLabels]="true"
        [showClearAllOption]="true"
        [ngModel]="incomingFilterValues"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="onSearchValueChange($event)"
        [meta]="meta.responseFilter"
        limit="1"
        title="Filter by Project"
        class="d-block fluid-table-component_search"
        [darkMode]="true"
        [toolTipVisible]="false"
        [isDisabled]="false"
        [placeHolderValue]="placeholderValue"
        [values]="filterValues"
        [labelName]="'projectName'"
      >
      </ey-multi-select>
    </form>
  </ng-container>
  <ng-container header>
    <tr>
      <th scope="col">
        <span class="eyfontbold">Project Name</span><ey-grid-sort column="projectName" toolTipContent="Sort by Name"></ey-grid-sort>
      </th>
      <th scope="col">
        <span class="eyfontbold">Response Status</span><ey-grid-sort column="responseStatus" toolTipContent="Sort by State"></ey-grid-sort>
      </th>
      <th scope="col"><span class="eyfontbold">Response ID</span><ey-grid-sort column="responseId"></ey-grid-sort></th>
      <th scope="col">
        <span class="eyfontbold">Response Date</span><ey-grid-sort column="responseDate" toolTipContent="Sort by Date"></ey-grid-sort>
      </th>
      <th scope="col"><span class="eyfontbold">Generated Files</span></th>
      <th scope="col"><span class="eyfontbold">Actions</span></th>
    </tr>
  </ng-container>

  <ng-template eyTemplate="body" let-data>
    <td [class.d-flex]="data?.isCollaborationLocked !== null || (data?.uploadId && data?.isCollaborationLocked === null)">
      <span *ngIf="data?.isCollaborationLocked !== null" class="me-10"
        ><i
          (click)="onCollaborationModeListOfRespondents(data.projectId, data.id)"
          class="icon-ico_mult_users emp-icon align-middle"
          title="Collaboration mode &#013;Check who else is working on this response."
        ></i
      ></span>
      <span *ngIf="data?.uploadId && data?.isCollaborationLocked === null" class="me-10"
        ><i
          class="icon-ico_responses_upload emp-icon align-middle"
          title="This is prepopulated response &#013;generated for you by &#013;EY project team."
        ></i
      ></span>
      <ey-text-expander-auto
        [class.margin-top]="data?.isCollaborationLocked !== null || (data?.uploadId && data?.isCollaborationLocked === null)"
        [text]="data.projectName"
        [lines]="1"
      ></ey-text-expander-auto>
    </td>
    <td>
      <ey-pill [pillType]="data.statusPills.pillType">{{ data.statusPills.label }}</ey-pill>
    </td>
    <td>
      <ey-text-expander [charLimit]="150" [text]="data.uploadResponseId || '-'"></ey-text-expander>
    </td>
    <td>
      {{ data.responseDate | date: 'dd MMMM yyyy' }}
    </td>
    <td>
      <div class="d-flex" *ngIf="showDocumentsDownloadSection(data)">
        <div
          *ngIf="data.excelCount > 0"
          (click)="
            openDocumentDownloadModal(
              data.excelCount,
              data.projectId,
              data.id,
              data.respondentName,
              templateFileTypesEnum.Excel,
              data.responseDate,
              data.responseStatus
            )
          "
        >
          <span [title]="data.responseStatus === 2 ? 'Download Draft' : 'Download'"
            ><i [class.icon-ico_Excel_Plain_Draft]="data.responseStatus === 2" class="icon-ico_Excel_Plain emp-icon"></i
          ></span>
          <span [class.draft-document-count]="data.responseStatus === 2" *ngIf="data.excelCount > 1; else noCount" class="document-count">{{
            data.excelCount
          }}</span>
        </div>
        <div
          *ngIf="data.powerPointCount > 0"
          (click)="
            openDocumentDownloadModal(
              data.powerPointCount,
              data.projectId,
              data.id,
              data.respondentName,
              templateFileTypesEnum.PowerPoint,
              data.responseDate,
              data.responseStatus
            )
          "
        >
          <span [title]="data.responseStatus === 2 ? 'Download Draft' : 'Download'"
            ><i [class.icon-ico_PPoint_Plain_Draft]="data.responseStatus === 2" class="icon-ico_PPoint_Plain emp-icon"></i
          ></span>
          <span [class.draft-document-count]="data.responseStatus === 2" *ngIf="data.powerPointCount > 1; else noCount" class="document-count">{{
            data.powerPointCount
          }}</span>
        </div>
        <div
          *ngIf="data.wordCount > 0"
          (click)="
            openDocumentDownloadModal(
              data.wordCount,
              data.projectId,
              data.id,
              data.respondentName,
              templateFileTypesEnum.Word,
              data.responseDate,
              data.responseStatus
            )
          "
        >
          <span [title]="data.responseStatus === 2 ? 'Download Draft' : 'Download'"
            ><i [class.icon-ico_Word_Plain_Draft]="data.responseStatus === 2" class="icon-ico_Word_Plain emp-icon"></i
          ></span>
          <span [class.draft-document-count]="data.responseStatus === 2" *ngIf="data.wordCount > 1; else noCount" class="document-count">{{
            data.wordCount
          }}</span>
        </div>
        <div *ngIf="data.excelCount === 0 && data.wordCount === 0 && data.powerPointCount === 0">
          <span class="no-document">-</span>
        </div>
      </div>
    </td>
    <td>
      <ng-container *ngTemplateOutlet="responseActions; context: { $implicit: data }"></ng-container>
    </td>
  </ng-template>
  <ng-template eyTemplate="noContent">
    <div class="d-flex flex-column align-items-center no-content-template">
      <p class="mb-0">This project has no responses</p>
    </div>
  </ng-template>
</ey-grid>
