<form [formGroup]="conditionGroup">
  <div class="paddingCustom border-bottom d-block w-100">
    <div
      [ngClass]="{ 'border-bottom': i !== criterias.controls.length - 1 }"
      formArrayName="criterias"
      *ngFor="let group of criterias.controls; let i = index"
    >
      <div class="w-100 m-0 p-10 d-flex">
        <i
          (click)="toggleConditionPanel(group)"
          [ngClass]="{ 'icon-ico_shevron_down': isVisible(group), 'icon-ico_shevron_right': !isVisible(group) }"
          class="emp-icon accordion-header-icon"
        ></i>
        <label class="w-100 mt-0 mb-0">Condition {{ i + 1 }}</label>
        <i
          *ngIf="showDeleteConditionOption()"
          role="button"
          class="emp-icon icon-ico_delete"
          title="Delete Condition"
          (click)="removeConditionBlock(i)"
        ></i>
      </div>
      <div [formGroupName]="i" *ngIf="isVisible(group)">
        <ey-condition [sourceValues]="sourceValues" formControlName="criteria"></ey-condition>
        <ey-radio-group-plain
          *ngIf="i !== criterias.length - 1"
          horizontal="true"
          narrowOptions="true"
          [isDisabled]="i > 0"
          [(ngModel)]="selectedOption"
          (ngModelChange)="checkConditionChange()"
          [ngModelOptions]="{ standalone: true }"
          [values]="options"
          [optionPosition]="optionPosition"
        >
        </ey-radio-group-plain>
      </div>
    </div>
    <div class="mt-20 clearfix">
      <a class="add--option" (click)="addCondition()">
        <i class="emp-icon icon-ico_plus"></i>
        <span class="mb-auto mt-auto">Add Condition</span>
      </a>
    </div>
  </div>
</form>
