import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertyToTemplate',
})
export class PropertyToTemplatePipe implements PipeTransform {
  transform(value: any): string {
    return value;
  }
}
