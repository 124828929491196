<ey-modal-template>
  <div header>
    <h2 class="modal__title">Multiple Empowerment Instances</h2>
  </div>
  <div body>
    <div>
      <h6 class="fonts-col__heading-6--bold"></h6>
      <p>
        We have detected, that you already have another instance of Empowerment running on <b>{{ browser }}</b
        >.
      </p>
      <p>Do you want to continue using this instance instead?</p>
    </div>
  </div>
  <div footer class="d-flex w-100">
    <button class="btn me-20 btn--primary btn-border-color d-flex justify-content-center" type="button" (click)="keepGoing()">Yes</button>
    <button class="btn btn--secondary ms-0" type="button" (click)="logout()">No</button>
  </div>
</ey-modal-template>
