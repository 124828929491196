import { Component, OnInit } from '@angular/core';
import { SummarySectionBaseComponent } from '../summary-section-base.component';
import { ButtonClass } from '../../../../../../shared/components/ey-button/ey-button.component';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { FlowType, ModuleFlowService } from 'src/app/designer/preview/module-flow.service';
import { IExcelAIPage } from 'src/app/designer/preview/page.model';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';

export interface IUploadedFile {
  fileId: string;
  name: string;
}

@Component({
  selector: 'app-excelai-summary',
  templateUrl: './excelai-summary.component.html',
  styleUrls: ['./excelai-summary.component.scss'],
})
export class ExcelAISummaryComponent extends SummarySectionBaseComponent implements OnInit {
  flowPage: IExcelAIPage;
  body: string;
  uploadedFiles: IUploadedFile[];

  constructor(
    private spinnerService: EyAppSpinnerService,
    private flowService: ModuleFlowService,
    private datePipe: DatePipe,
  ) {
    super();
  }
  btnClass = ButtonClass;

  ngOnInit(): void {
    this.body = this.page?.moduleFlowPage.body;
    this.flowPage = this.page.moduleFlowPage as IExcelAIPage;

    this.uploadedFiles = this.page.moduleFlowPage.properties[0].uploadedFiles?.map((f) => {
      return {
        fileId: f.fileId,
        name: f.name,
      };
    });
  }

  downloadUploadedExcelFile(file: IUploadedFile): void {
    this.spinnerService
      .withLoadingIndicator(this.flowService.downloadFileFromSummaryExcelAIPage(this.page.sessionId, file.fileId), this.destroy$)
      .subscribe((response) => {
        importedSaveAs(response.body, file.name);
      });
  }

  downloadOutFile() {
    const pageWithFileId = this.flowPage.connectedInputPageId || this.flowPage.id;

    this.spinnerService
      .withLoadingIndicator(
        this.flowService.downloadPopulatedExcelFile(FlowType.response, this.page.sessionId, this.flowPage.id, pageWithFileId),
        this.destroy$,
      )
      .subscribe((response: HttpResponse<Blob>) => {
        const headers: HttpHeaders = response.headers;

        const contentDisposition = headers.get('content-disposition');

        let fileName = 'populated.xlsx';
        if (contentDisposition) {
          fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].replace(/"/g, '').trim();
          const currentDate = this.datePipe.transform(new Date(), 'ddMMyyyy');
          fileName = fileName.replace(/(.*)\./, `$1_${currentDate}.`);
        }

        importedSaveAs(response.body, fileName);
      });
  }
}
