import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTING_PATHS } from '../../app-routing.module';
import { ModalResult, ModalVerticalSizeWindowClass, ModalWidth } from '../../shared/components/ey-modal-template/ey-modal-result.enum';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { MultipleSessionsActiveModalComponent } from './multiple-sessions-active-modal.component';
import { IMultipleInstance } from './multiple-instance.model';
import { MsalTokenHelper } from 'src/app/core/authentication/msal-token-helper';

@Injectable({
  providedIn: 'root',
})
export class MultipleBrowserInstancesService {
  isLogin = false;
  dialogActive = false;
  private baseUrl = environment.baseUrl;
  constructor(
    private tokenHelper: MsalTokenHelper,
    private location: Location,
    private router: Router,
    private modalService: NgbModal,
    private httpClient: HttpClient,
  ) {}

  check(): void {
    setTimeout(() => {
      if (this.location.path().includes('access-denied') || this.location.path().includes('logout')) {
        return;
      }

      if (this.isUserLoggedIn()) {
        this.isLogin = true;
      } else {
        return;
      }
      this.getInstances().subscribe((t) => {
        if (t.length > 1) {
          const otherInstance = t.filter((t) => !t.isCurrent)[0];
          const currentInstance = t.filter((t) => t.isCurrent)[0];
          this.showDialog(otherInstance.browser, currentInstance.instanceId);
        }
      });
    }, 2000);
  }

  showDialog(browserName: string, currentInstanceId: string): void {
    this.dialogActive = true;
    const modalOptions: NgbModalOptions = {
      animation: false,
      backdrop: 'static',
      size: ModalWidth.default,
      windowClass: ModalVerticalSizeWindowClass.auto,
    };

    const modalRef = this.modalService.open(MultipleSessionsActiveModalComponent, modalOptions);
    modalRef.componentInstance.browser = browserName;
    modalRef.result.then(
      (result) => {
        this.dialogActive = false;
        if (result === ModalResult.submit) {
          this.router.navigate([ROUTING_PATHS.logout]);
        } else {
          this.terimnateInstance(currentInstanceId).subscribe();
          return;
        }
      },
      () => {},
    );
  }

  getInstances(): Observable<IMultipleInstance[]> {
    return this.httpClient.get<IMultipleInstance[]>(`${this.baseUrl}/user/browser-instances`);
  }

  terimnateInstance(currentInstanceId: string): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.baseUrl}/user/terminate-instance/${currentInstanceId}`, {});
  }

  isUserLoggedIn(): string {
    return this.tokenHelper.getAccessToken();
  }
}
