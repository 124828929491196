import { createReducer, on } from '@ngrx/store';
import { addNameToCounterCollection, clearNameCounters, initNameCounters } from './actions';
import * as lodash from 'lodash';

const regex = /([a-zA-Z]+).*?(\d+)/;

interface NameCount {
  readonly name: string;
  readonly count: number;
}

export const nameCountsReducer = createReducer(
  {},
  on(initNameCounters, (_, action) => {
    const tools = action.tools;

    const names = tools.filter((t) => t.name && regex.test(t.name)).map((t) => t.name) as string[];
    const nameCounts = names.map((n) => {
      const match = n.match(regex);

      const nameCount: NameCount = {
        name: match[1],
        count: Number(match[2]),
      };

      return nameCount;
    });

    const grouppedNameCounts = lodash.groupBy(nameCounts, 'name');
    return Object.entries(grouppedNameCounts).reduce((prev, [key, value]) => {
      prev[key] = lodash.maxBy(value, 'count').count;
      return prev;
    }, {});
  }),
  on(addNameToCounterCollection, (state, action) => {
    const newState = { ...state };

    const count = newState[action.alias] || 0;
    newState[action.alias] = count + 1;

    return newState;
  }),
  on(clearNameCounters, (state) => ({})),
);
