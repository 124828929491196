import { Component, Input } from '@angular/core';
import { ControlContainer, ControlValueAccessor } from '@angular/forms';
import { EyBaseFormControlComponent } from './ey-base-form-control';

@Component({
  template: '',
})
export class EyBaseToggleControlComponent extends EyBaseFormControlComponent implements ControlValueAccessor {
  selectedValue: boolean;
  @Input() slim = true;

  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  constructor(controlContainer: ControlContainer) {
    super(controlContainer);
  }

  onSelectedChanged(selected: boolean): void {
    this.selectedValue = selected;
    this.onChange(selected);
    this.onTouched(selected);
  }

  writeValue(initValue: boolean): void {
    this.selectedValue = initValue;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
