<div
  role="button"
  *ngFor="let b of breadcrumbs"
  class="emp-breadcrumb"
  [ngClass]="{ disabled: !b.active }"
  [attr.aria-disabled]="!b.active"
  [routerLink]="b.url"
  [attr.disabled]="!b.active"
>
  {{ b.title }}
</div>
