import { createReducer, on } from '@ngrx/store';
import { saveToggleAutoSave, updateSaveStatus } from './save.actions';
import { Save } from './saving-state';
import { SaveStatus } from '../../designer/builders/form-builder/form-builder.state';

export const TOOL_NAME_TABLE_BUILDER = 'TableBuilder';

const initState: Save = {
  autoSave: true,
  tableBuilderSaveStatus: SaveStatus.saved,
};

export const saveReducer = createReducer(
  initState,
  on(updateSaveStatus, (state, action) => {
    if (action.toolName === TOOL_NAME_TABLE_BUILDER) {
      return { ...state, tableBuilderSaveStatus: action.saveStatus };
    } else {
      return { ...state };
    }
  }),
  on(saveToggleAutoSave, (state, action) => {
    return { ...state, autoSave: action.autoSave };
  }),
);
