<ey-froala-content-wrapper [editorContent]="body"></ey-froala-content-wrapper>

<span *ngIf="flowPage.mode === 1 && uploadedFiles?.length < 1">- Not Uploaded -</span>
<span *ngIf="flowPage.mode === 2 && uploadedFiles?.length < 1">There is no document for you to download</span>
<span *ngIf="flowPage.mode === 2 && uploadedFiles?.length === 1">There is one document for you to download</span>

<ul class="ps-0 mt-10">
  <li class="segments d-flex" *ngFor="let f of uploadedFiles">
    <ng-container [ngSwitch]="flowPage.mode">
      <ng-container *ngSwitchCase="2">
        <span class="flex-grow-1">Download your customized Excel file here</span>
        <i title="Download File" role="button" (click)="downloadOutFile()" class="icon-ico_download emp-icon"></i
      ></ng-container>
      <ng-container *ngSwitchDefault>
        <span class="flex-grow-1">{{ f.name }}</span>
        <i title="Download File" role="button" (click)="downloadUploadedExcelFile(f)" class="icon-ico_download emp-icon"></i>
      </ng-container>
    </ng-container>
  </li>
</ul>
<p *ngIf="!!flowPage.connectedTemplate?.prepopulatedTemplate">
  Used prepopulated template <b>{{ flowPage.connectedTemplate?.prepopulatedTemplate.name }}</b>
</p>
