import { Component, OnInit } from '@angular/core';
import { SummarySectionBaseComponent } from '../summary-section-base.component';
import { IMailBarSection } from '../../../../../builders/letter-builder/mail-bar/mail-bar-section/mail-bar-section.model';

@Component({
  selector: 'app-response-mail-summary',
  templateUrl: './mail-summary.component.html',
  styleUrls: ['./mail-summary.component.scss'],
})
export class MailSummaryComponent extends SummarySectionBaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}

  get mailSections(): Array<IMailBarSection> {
    return this.page.moduleFlowPage?.properties[0]?.mailSections || [];
  }

  get htmlBlocks(): Array<string> {
    return this.page.moduleFlowPage?.properties[0]?.reportBlocksProperty || [];
  }

  get isHidden(): boolean {
    return this.page.moduleFlowPage?.properties[0]?.isHidden || false;
  }
}
