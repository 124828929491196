import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ButtonClass } from 'src/app/shared/components/ey-button/ey-button.component';
import { FlowType, ModuleFlowService } from '../../module-flow.service';
import { BasePageContent } from '../base-page-content';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { debounceTime, delay, switchMap, takeUntil } from 'rxjs/operators';
import { PropertyInput } from '../../property-input.model';
import { Subject, of } from 'rxjs';
import { FormatOnBlur } from './input-focus.directive';
import { NgbDateCustomParserFormatter } from '../../../../shared/components/ey-date-picker/date.formatter';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToolTipHolderOptions } from '../../../../shared/components/constants';
import { IEyRadioGroupModel } from '../../../../shared/components/ey-radio-group/ey-radio-group.model';
import { DIVIDER_STYLE_HELPER } from '../../../builders/form-builder/controls/divider/divider-editor/divider-editor.component';
import { SELECTION_LIMITS_NAMES } from '../../../../shared/components/ey-range-selector/ey-range-selector.component';
import { SaveNotificationServiceEndUserForm } from '../../save-notification-service-end-user-form.service';
import { UserService } from '../../../../core/services/user-service';
import { User } from '../../../../core/models/user/user.model';
import { GraphService } from '../../../../core/services/graph.service';
import { FormBuilderControlTypes } from '../../../builders/form-builder/form-builder-control.types';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';
import { Page } from '../../page.model';
import { NumberNormalizationService } from 'src/app/core/services/number-normalization.service';
import { numberValidator } from 'src/app/core/validators/number.validator';

export const VAL_MSG_CHGR_MIN = 'Please select a minimum of %N% options';
export const VAL_MSG_CHGR_MAX = 'Please select a maximum of %N% options';
export const VAL_MSG_CHGR_BTW = 'Please select between %N% and %N2% options';
export const VAL_MSG_CHGR_EXACT = 'Please select %N% options';
export const VAL_MSG_CHGR_REQUIRED = 'Please select one of the options';
export const EY_EMAIL_LOOKUP_PLACEHOLDER = 'Search for user…';

export interface ICheckBoxGroupConditions {
  min: string;
  max: string;
  minRange: string;
  maxRange: string;
  exact: string;
  selectedCondition: string;
}

@Component({
  selector: 'app-form-page.d-flex.flex-fill',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }],
})
export class FormPageComponent extends BasePageContent implements OnInit, AfterViewInit {
  emailLookUpPlaceHolder: string = EY_EMAIL_LOOKUP_PLACEHOLDER;
  @ViewChildren('formatOnBlur') formatOnBlurDirective: QueryList<FormatOnBlur>;
  btnClass = ButtonClass;
  form: UntypedFormArray;
  controlType = FormBuilderControlTypes;
  isRequiresCtr = 0;
  toolTipHolderOptionHidden: ToolTipHolderOptions = ToolTipHolderOptions.hidden;
  showSpinnerPM = false;
  currentUser: User;

  inputValueSubject = new Subject<void>();

  constructor(
    flowService: ModuleFlowService,
    protected spinnerService: EyAppSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private dateParser: NgbDateCustomParserFormatter,
    private graphService: GraphService,
    private saveNotification: SaveNotificationServiceEndUserForm,
    private userSrv: UserService,
    private domElementRef: ElementRef,
    private numberNormalizationService: NumberNormalizationService,
  ) {
    super(flowService, spinnerService);
  }

  get formParts(): Array<any> {
    return this.page.moduleFlowPage.properties;
  }

  ngOnInit(): void {
    this.flowService.responseHeaderMobileViewChange.pipe(takeUntil(this.destroy$)).subscribe((mobileViewChange) => {
      this.isMobileBreakpoint = mobileViewChange;
    });

    this.userSrv.getCurrentUser().subscribe((u) => {
      this.currentUser = u;
    });

    // TODO SO: refactor this quick fix to a normal operation
    if (this.flowType === FlowType.response && this.saveNotification.saveCurrentProperties$.observers.length === 0) {
      this.saveNotification.saveCurrentProperties$
        .pipe(
          switchMap(() => this.flowService.saveCurrent(this.page.moduleFlowPage.id, this.getValues(true))),
          takeUntil(this.destroy$),
        )
        .subscribe((response) => {
          this.recalculateQuestionsVisibility(response);
        });
    }

    this.inputValueSubject.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe(() => {
      this.recalculatePage();
    });

    this.form = this.formBuilder.array(
      this.formParts.map((fp) => {
        fp.meta = { name: '', errorMsg: this.getErrorMsg(fp) };
        if (fp.isRequired) {
          this.isRequiresCtr++;
        }

        this.coercePropertyValue(fp);
        const validators = this.initializeValidators(fp);
        return new UntypedFormControl(fp.value, validators);
      }),
    );
    this.onFormValueChange();
  }

  coercePropertyValue(property: any): void {
    const type: FormBuilderControlTypes = property.type as FormBuilderControlTypes;

    if (type === FormBuilderControlTypes.numberInput) {
      property.value = property.value === null ? '' : property.value;
    }
    if (type === FormBuilderControlTypes.checkboxGroup) {
      property.value = property.selectedItems ? property.selectedItems.map((id) => ({ id })) : null;
    }
    if (type === FormBuilderControlTypes.checkbox) {
      property.value = property.value === 'true' ? true : false;
    }
  }

  onFormValueChange(): void {
    this.form.valueChanges.pipe(debounceTime(2000)).subscribe(() => {
      this.saveNotification.dispatchNotSaved();
      if (this.flowType === FlowType.response && this.isAutoSave) {
        this.saveNotification.saveCurrentProperties$.next();
      }
    });
  }

  onInputValueChange(): void {
    this.inputValueSubject.next();
  }

  onInputBlur(): void {
    this.recalculatePage();
  }

  onInputChange(): void {
    this.recalculatePage();
  }

  recalculatePage(): void {
    if (this.formParts.filter((fp) => fp.isCriteriaSet).length > 0) {
      this.flowService
        .recalculateCurrent(this.page.moduleFlowPage.id, this.getValues(true), this.flowType)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.recalculateQuestionsVisibility(response);
        });
    }
  }

  recalculateQuestionsVisibility(page: Page): void {
    page.moduleFlowPage.properties.forEach((property: any, index: number) => {
      const part = this.formParts.find((fp) => fp.id === property.id);
      if (part) {
        part.isCriteriaSatisfied = property.isCriteriaSatisfied;
      }

      const controlByName = this.form.get(index.toString());
      const validators = this.initializeValidators(part);
      controlByName.setValidators(validators);
      controlByName.updateValueAndValidity({ emitEvent: false });
    });
  }

  initializeValidators(property: any): any[] {
    let validators = [];
    const isPropertyVisible = this.getPropertyVisibility(property);
    if (isPropertyVisible) {
      const type: FormBuilderControlTypes = property.type as FormBuilderControlTypes;
      if (property.isRequired) {
        if (type === FormBuilderControlTypes.textInput || type === FormBuilderControlTypes.numberInput) {
          validators.push(this.requiredValidator());
        } else if (type === FormBuilderControlTypes.checkbox) {
          validators.push(Validators.requiredTrue);
        } else {
          validators.push(Validators.required);
        }
      }
      if (type === FormBuilderControlTypes.numberInput) {
        validators.push(numberValidator());
      }
      if (type === FormBuilderControlTypes.checkboxGroup) {
        validators.push(this.checkBoxValidator(property.conditions, property, property.isRequired));
      }
    }
    return validators;
  }

  getPropertyVisibility(property: any): boolean {
    if (property.isCriteriaSet) {
      return property.isCriteriaSatisfied;
    }
    return true;
  }

  // substitute for the built-in required validator that treats an empty array from IME as not valid
  requiredValidator() {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (control.value) {
        return null;
      }
      return { notValid: true };
    };
  }

  checkBoxValidator(conditions: ICheckBoxGroupConditions, formPart: any, isRequired: boolean): any {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const itemsSelected = control.value?.length;

      if (conditions.selectedCondition === SELECTION_LIMITS_NAMES.min && conditions.min.length > 0 != null && +conditions.min > itemsSelected) {
        formPart.meta.errorMsg = VAL_MSG_CHGR_MIN.replace('%N%', conditions.min);
        return { notValid: true };
      }

      if (conditions.selectedCondition === SELECTION_LIMITS_NAMES.max && conditions.max.length > 0 && +conditions.max < itemsSelected) {
        formPart.meta.errorMsg = VAL_MSG_CHGR_MAX.replace('%N%', conditions.max);
        formPart = { ...formPart };
        return { notValid: true };
      }

      if (
        conditions.selectedCondition === SELECTION_LIMITS_NAMES.range &&
        conditions.minRange.length > 0 &&
        conditions.maxRange.length > 0 &&
        (+conditions.minRange > itemsSelected || +conditions.maxRange < itemsSelected)
      ) {
        formPart.meta.errorMsg = VAL_MSG_CHGR_BTW.replace('%N%', conditions.minRange).replace('%N2%', conditions.maxRange);
        return { notValid: true };
      }
      if (
        conditions.selectedCondition === SELECTION_LIMITS_NAMES.exactly &&
        conditions.exact.length > 0 != null &&
        +conditions.exact !== itemsSelected
      ) {
        formPart.meta.errorMsg = VAL_MSG_CHGR_EXACT.replace('%N%', conditions.exact);
        return { notValid: true };
      }
      if (isRequired && itemsSelected < 1) {
        formPart.meta.errorMsg = VAL_MSG_CHGR_REQUIRED;
        return { notValid: true };
      }

      return null;
    };
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);
    of(true)
      .pipe(delay(50), takeUntil(this.destroy$))
      .subscribe((t) => {
        this.formatOnBlurDirective.forEach((d) => d.onblur(null));
      });
  }

  getErrorMsg(fp: any): string {
    const type: FormBuilderControlTypes = fp.type as FormBuilderControlTypes;
    switch (type) {
      case FormBuilderControlTypes.textInput:
        return 'Please enter a valid text (Between 1-' + fp.length + ')';
      case FormBuilderControlTypes.numberInput:
        return 'Please enter a valid number';
      case FormBuilderControlTypes.date:
        return 'Please enter a valid date';
      case FormBuilderControlTypes.multilineInput:
        return 'Please enter a valid text (Between 1- ' + fp.length + ' characters)';
      case FormBuilderControlTypes.radioButton:
        return 'Please select one of the options';
      case FormBuilderControlTypes.dropDown:
        return 'Please select one of the options';
      case FormBuilderControlTypes.checkbox:
        return 'Please select to proceed';
      case FormBuilderControlTypes.checkboxGroup:
        return 'Please select one of the options';
      case FormBuilderControlTypes.eyEmailLookup:
        return 'Please provide email address to proceed';
      default:
        return '';
    }
  }

  getValues(isSavedCalled: boolean = false): Array<PropertyInput> {
    try {
      const values: Array<PropertyInput> = [];
      if (this.form.valid || isSavedCalled) {
        let ctr = 0;
        const list: any = this.form.getRawValue();
        list.forEach((li: any) => {
          if (this.formParts[ctr].type !== FormBuilderControlTypes.description) {
            values.push({ name: this.formParts[ctr].name, value: this.formatValue(li, this.formParts[ctr].type) });
          }
          ctr++;
        });
      }
      return values;
    } catch (ex) {
      console.log(ex);
      return [];
    }
  }

  formatValue(val: any, type: FormBuilderControlTypes): any {
    switch (type) {
      case FormBuilderControlTypes.numberInput:
        return this.numberNormalizationService.normalizeNumberString(val);
      case FormBuilderControlTypes.date:
        return this.dateParser.formatUTC(val);
      case FormBuilderControlTypes.dropDown:
        return val ? val?.id : null;
      case FormBuilderControlTypes.checkboxGroup:
        return val ? JSON.stringify(val.map((o) => o.id)) : null;
      case FormBuilderControlTypes.eyEmailLookup:
        return val ? JSON.stringify(val) : null;
      default:
        return val;
    }
  }

  onPrev(): void {
    this.spinnerService.withLoadingIndicator(this.flowService.doPrev(this.getValues(), this.flowType), this.destroy$).subscribe();
  }

  get isHidden(): boolean {
    return this.page.moduleFlowPage.isHidden;
  }

  onNext(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.focusOnError(this.form, this.domElementRef);
      return;
    }

    this.spinnerService.withLoadingIndicator(this.flowService.doNext(this.getValues(), this.flowType), this.destroy$).subscribe();
  }

  mapToRadioGroupModel(options: any): IEyRadioGroupModel[] {
    if (options) {
      return options.map((o) => {
        return { value: o.id, label: o.text };
      });
    } else {
      return [];
    }
  }

  getHrStyle(type: string): any {
    return DIVIDER_STYLE_HELPER[type];
  }

  onEmailSearch(searchText: string, formPart: any): void {
    if (!this.currentUser.isExternal) {
      if (searchText && searchText.length >= 2) {
        formPart.showSpinnerPM = true;
        this.graphService
          .searchInternalUsersInTools(searchText)
          .pipe(takeUntil(this.destroy$))
          .subscribe((u) => {
            formPart.emailDictionary = u?.map((usr: any) => {
              return { email: usr.mail, id: usr.id, name: usr.displayName };
            });
            formPart.showSpinnerPM = false;
          });
      } else {
        formPart.emailDictionary = null;
      }
    } else {
      formPart.emailDictionary = [{ email: this.currentUser.email, id: this.currentUser.id, name: this.currentUser.name }];
    }
  }
}
