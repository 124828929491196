<app-fluid-footer-template class="d-flex flex-fill overflow-x-auto" [hintPresent]="page?.moduleFlowPage?.hint" [selectedViewType]="selectedViewType">
  <ng-container appPageContent>
    <i class="emp-icon icon-ico_Expand right-top-corner" (click)="openModal()"></i>
    <app-table-preview [overflowVisible]="true" [selectedViewType]="selectedViewType" [page]="page" [flowType]="flowType" [isAutoSave]="isAutoSave">
    </app-table-preview>
  </ng-container>
  <ng-container appPageFooter>
    <div>
      <div class="d-flex">
        <ey-button class="ms-auto me-20" [btnClass]="btnClass.btnPrev" text="Prev" (click)="onPrev()"></ey-button>
        <ey-button [class.margin-right]="selectedViewType === 2" [btnClass]="btnClass.btnNext" text="Next" (click)="onNext()"></ey-button>
      </div>
      <div *ngIf="lastTechReviewDate && !isMobileBreakpoint">
        <span class="reviewDate spacing">Last Technical Review: {{ lastTechReviewDate }}</span>
      </div>
    </div>
  </ng-container>
</app-fluid-footer-template>
