import { createAction, props } from '@ngrx/store';
import { SpecificPart } from './specific-part';
import { createNonUndoableAction } from '../utils/action-utils';
import { TypeAndNameAlias } from '../tool-type-map';
import { RectangularTool } from '../tools/rectangular-tool';
import { Shape } from '../shape/shape';

export const addOrUpdateSpecificPart = createAction('[Specific parts] Add Or Update Specific Part', props<{ specificPart: SpecificPart }>());
export const addOrUpdateFormToolSpecificPart = createAction(
  '[Specific parts] Add Or Update FORM TOOL Specific Part',
  props<{ specificPart: SpecificPart; versionId: string }>(),
);
// tslint:disable-next-line: max-line-length
export const removeOptionForDecisionTool = createAction(
  '[Specific parts] Remove Option For Decision Tool',
  props<{ decisionToolId: string; connectionId: string }>(),
);
export const clearSpecificParts = createNonUndoableAction('[Specific parts] Clear specific parts');
export const deleteSpecificParts = createAction('[Specific parts] Delete specific parts', props<{ toolId: string }>());
export const loadSpecificParts = createNonUndoableAction('[Specific parts] LoadSpecificParts', props<{ specificParts: SpecificPart[] }>());
export const cloneSpecificPart = createAction(
  '[Specific parts] Clone',
  props<{ shape: Shape; typeNameAlias: TypeAndNameAlias; rectTool: RectangularTool }>(),
);
