<ey-summary-row
  ><span class="subject">{{ subject }}</span></ey-summary-row
>
<ey-summary-row *ngFor="let to of toSections; let i = index" [label]="getLabel(to, i)">
  <ey-mail-expander [mailTags]="to.content"></ey-mail-expander>
</ey-summary-row>
<ey-summary-row *ngFor="let cc of ccSections; let i = index" [label]="getLabel(cc, i)">
  <ey-mail-expander [mailTags]="cc.content"></ey-mail-expander>
</ey-summary-row>
<ey-summary-row *ngIf="attachments.length > 0">
  <ey-attachments-wrapper [attachments]="attachments"></ey-attachments-wrapper>
</ey-summary-row>
