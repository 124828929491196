import { Connector, ConnectorPosition } from './connector';
import { createConnector, draw as drawConnector } from './connector';
import * as _ from 'lodash';
import { Property } from '../../../shared/directives/dynamic-control.directive';
import { IRectangle } from '../domain/canvas-state';
import { ValidationSeverityType } from '../../preview/page.model';

const invalidToolIcon = new Image();
invalidToolIcon.src = 'assets/icons/block_error.svg';
invalidToolIcon.onload = () => {
  return;
};

const warningToolIcon = new Image();
warningToolIcon.src = 'assets/icons/block_warning.svg';
warningToolIcon.onload = () => {
  return;
};

export type DrawFn = (ctx: CanvasRenderingContext2D) => void;
export const fill: DrawFn = (ctx) => ctx.fill();
export const stroke: DrawFn = (ctx) => {
  ctx.stroke();
};
export const fillAndStroke: DrawFn = (ctx) => {
  ctx.fill();
  ctx.stroke();
};

export const WIDTH = 120;
export const HEIGHT = 80;
export const SELECTED_PADDING = 3;
export const BORDER_RADIUS = 6;
export const TEXT_PADDING = 8;

export interface Colors {
  readonly fillColor: string;
  readonly selectedFillColor: string;
  readonly hoverFillColor: string;
  readonly fontColor: string;
}

export enum RectToolType {
  StartTool = 1,
  EndTool,
  StatementTool,
  ListTool,
  UploadTool,
  YesNoTool,
  DecisionTool,
  FormTool,
  LetterTool,
  TableTool,
  ExcelAITool,
  MailTool,
  ConnectorTool,
}

export interface RectangularTool {
  readonly id: string;
  readonly x: number;
  readonly y: number;
  readonly colors: Colors;
  readonly connectors: Array<Connector>;
  readonly properties: Array<Property>;
  readonly isSelected: boolean;
  readonly isHovered: boolean;
  readonly width: number;
  readonly height: number;
  readonly type: RectToolType;
  readonly name: string;
  readonly isInvalid: boolean;
  readonly validationSeverityType: ValidationSeverityType;
}

export function createRectangularTool(
  id: string,
  x: number,
  y: number,
  type: RectToolType,
  isSelected: boolean = false,
  colors: Colors,
  isHovered: boolean = false,
  name: string,
  properties: Property[],
  width = WIDTH,
  height = HEIGHT,
): RectangularTool {
  const validationSeverityType = ValidationSeverityType.None; // mk note: added this line
  const isInvalid = false;
  const connectors = [
    createConnector(ConnectorPosition.Up, id, x, y, width, height),
    createConnector(ConnectorPosition.Right, id, x, y, width, height),
    createConnector(ConnectorPosition.Bottom, id, x, y, width, height),
    createConnector(ConnectorPosition.Left, id, x, y, width, height),
  ];

  return {
    id,
    x,
    y,
    colors,
    isSelected,
    isHovered,
    connectors,
    properties,
    width,
    height,
    type,
    name,
    isInvalid,
    validationSeverityType,
  };
}

export function drawSquareToolWithBordersAndConnectors(rect: RectangularTool, type: string, ctx: CanvasRenderingContext2D): void {
  ctx.save();
  ctx.lineWidth = 2;
  ctx.fillStyle = rect.colors.fillColor;

  drawSquareTool(rect, ctx, BORDER_RADIUS, SELECTED_PADDING, fill, fillAndStroke);
  drawText(rect, type, ctx);
  drawConnectors(rect, ctx);

  if (rect.isInvalid && rect.validationSeverityType === ValidationSeverityType.Error) {
    drawInvalidToolIcon(rect, ctx);
  }

  if (rect.isInvalid && rect.validationSeverityType === ValidationSeverityType.Warning) {
    drawWarningToolIcon(rect, ctx);
  }

  ctx.restore();
}

export function drawMiniMapToolWithBordersAndConnectors(rect: RectangularTool, ctx: CanvasRenderingContext2D): void {
  ctx.save();
  ctx.lineWidth = 2;
  ctx.fillStyle = rect.colors.fillColor;
  drawSquareTool(rect, ctx, BORDER_RADIUS, SELECTED_PADDING, fill, fillAndStroke);
  ctx.fill();
  ctx.restore();
}

export function drawInvalidToolIcon(rect: RectangularTool, ctx: CanvasRenderingContext2D): void {
  let x = rect.x + rect.width;
  let y = rect.y;
  if (rect.type === RectToolType.StartTool || rect.type === RectToolType.EndTool) {
    x -= 15;
    y -= 0;
  } else {
    y -= 12;
    x -= 12;
  }

  ctx.drawImage(invalidToolIcon, x, y);
}

export function drawWarningToolIcon(rect: RectangularTool, ctx: CanvasRenderingContext2D): void {
  let x = rect.x + rect.width;
  let y = rect.y;
  if (rect.type === RectToolType.StartTool || rect.type === RectToolType.EndTool) {
    x -= 15;
    y -= 0;
  } else {
    y -= 12;
    x -= 12;
  }

  ctx.drawImage(warningToolIcon, x, y);
}

export function drawConnectors(rect: RectangularTool, ctx: CanvasRenderingContext2D): void {
  // mk note: removed this to fix multiple items hovered issue ( || rect.connectors.some(c => c.isHovered) )
  if (rect.isHovered) {
    rect.connectors.forEach((c) => drawConnector(c, ctx));
  }
}

export function drawText(rect: RectangularTool, type: string, ctx: CanvasRenderingContext2D): void {
  ctx.fillStyle = rect.colors.fontColor;
  // ctx.font = '12px EYInterstate-Bold';
  ctx.font = '12px Arial';
  ctx.textAlign = 'right';
  ctx.fillText(type, rect.x + rect.width - TEXT_PADDING, rect.y + rect.height - TEXT_PADDING), (ctx.textAlign = 'left');
  ctx.textBaseline = 'top';

  const textLines = getTextLines(rect.name, ctx);
  textLines.forEach((l, i) => ctx.fillText(l, rect.x + TEXT_PADDING, rect.y + TEXT_PADDING + 16 * i));
}

export function drawSquareTool(
  rect: RectangularTool,
  ctx: CanvasRenderingContext2D,
  borderRadius: number,
  padding: number,
  drawInner: DrawFn,
  drawOuter: DrawFn,
): void {
  if (rect.isSelected && !rect.isInvalid) {
    drawSquareToolOuterReact(rect, ctx, borderRadius, padding, drawOuter);
  }

  if (rect.isInvalid && rect.validationSeverityType === ValidationSeverityType.Error) {
    drawSquareToolOuterReact(rect, ctx, borderRadius, padding, drawOuter, 'red');
  }

  if (rect.isInvalid && rect.validationSeverityType === ValidationSeverityType.Warning) {
    drawSquareToolOuterReact(rect, ctx, borderRadius, padding, drawOuter, 'orange');
  }

  drawSquare(rect, ctx, borderRadius, drawInner);
}

export function drawSquareToolOuterReact(
  rect: RectangularTool,
  ctx: CanvasRenderingContext2D,
  borderRadius,
  padding: number,
  drawOuter: DrawFn,
  color: string = 'black',
): void {
  ctx.save();
  ctx.fillStyle = 'white';
  ctx.strokeStyle = color;
  ctx.lineWidth = 2;

  const outerRect: IRectangle = {
    x: rect.x - padding,
    y: rect.y - padding,
    width: rect.width + padding * 2,
    height: rect.height + padding * 2,
  };

  drawSquare(outerRect, ctx, borderRadius + padding, drawOuter);
  ctx.restore();
}

export function drawSquare(
  rect: IRectangle,
  ctx: CanvasRenderingContext2D,
  borderRadius: number,
  drawFn: (ctx: CanvasRenderingContext2D) => void,
  dashedLine = [],
): void {
  ctx.beginPath();
  ctx.setLineDash(dashedLine);
  ctx.moveTo(rect.x + borderRadius, rect.y);
  ctx.lineTo(rect.x + rect.width - borderRadius, rect.y);
  ctx.arcTo(rect.x + rect.width, rect.y, rect.x + rect.width, rect.y + borderRadius, borderRadius);
  ctx.lineTo(rect.x + rect.width, rect.y + rect.height - borderRadius);
  ctx.arcTo(rect.x + rect.width, rect.y + rect.height, rect.x + rect.width - borderRadius, rect.y + rect.height, borderRadius);
  ctx.lineTo(rect.x + borderRadius, rect.y + rect.height);
  ctx.arcTo(rect.x, rect.y + rect.height, rect.x, rect.y + rect.height - borderRadius, borderRadius);
  ctx.lineTo(rect.x, rect.y + borderRadius);
  ctx.arcTo(rect.x, rect.y, rect.x + borderRadius, rect.y, borderRadius);
  drawFn(ctx);
}

function getTextLines(name: string, ctx: CanvasRenderingContext2D): string[] {
  const SPACE = ' ';

  if (name.includes(SPACE)) {
    return getTwoLinesForNameWithSpaces(name, '', ctx);
  } else {
    return getTwoLinesWithoutSpaces(name, '', ctx);
  }
}

function getTwoLinesWithoutSpaces(firstLine: string, secondLine: string, ctx: CanvasRenderingContext2D): string[] {
  const measurements = ctx.measureText(firstLine);

  const textLength = measurements.actualBoundingBoxLeft + measurements.actualBoundingBoxRight;
  const spaceInsideRectangle = WIDTH - 2 * TEXT_PADDING;

  if (textLength > spaceInsideRectangle) {
    return getTwoLinesWithoutSpaces(firstLine.slice(0, firstLine.length - 1), `${firstLine.slice(firstLine.length - 1)}${secondLine}`, ctx);
  } else {
    return secondLine ? [firstLine, getOneLineWithoutSpaces(secondLine, ctx)] : [firstLine];
  }
}

function getOneLineWithoutSpaces(name: string, ctx: CanvasRenderingContext2D): string {
  const measurements = ctx.measureText(name);

  const textLength = measurements.actualBoundingBoxLeft + measurements.actualBoundingBoxRight;
  const spaceInsideRectangle = WIDTH - 2 * TEXT_PADDING;

  if (textLength > spaceInsideRectangle) {
    return getOneLineWithoutSpaces(name.slice(0, name.length - 1), ctx);
  } else {
    return `${name.slice(0, name.length - 2)}...`;
  }
}

function getTwoLinesForNameWithSpaces(firstLine: string, secondLine: string, ctx: CanvasRenderingContext2D): string[] {
  const measurements = ctx.measureText(firstLine);

  const textLength = measurements.actualBoundingBoxLeft + measurements.actualBoundingBoxRight;
  const spaceInsideRectangle = WIDTH - 2 * TEXT_PADDING;

  if (textLength > spaceInsideRectangle) {
    const words = firstLine.split(' ');

    return getTwoLinesForNameWithSpaces(
      _.take(words, words.length - 1).reduce((p, n) => (p ? `${p} ${n}` : n), ''),
      `${_.last(words)} ${secondLine}`,
      ctx,
    );
  } else {
    return secondLine ? [firstLine, getOneLineForNameWithSpaces(secondLine, ctx)] : [firstLine];
  }
}

function getOneLineForNameWithSpaces(name: string, ctx: CanvasRenderingContext2D): string {
  const measurements = ctx.measureText(name);

  const textLength = measurements.actualBoundingBoxLeft + measurements.actualBoundingBoxRight;
  const spaceInsideRectangle = WIDTH - 2 * TEXT_PADDING;

  if (textLength > spaceInsideRectangle) {
    const words = name.split(' ');

    return getOneLineForNameWithSpaces(
      _.take(words, words.length - 1).reduce((p, n) => (p ? `${p} ${n}` : n), ''),
      ctx,
    );
  } else {
    return `${name}...`;
  }
}
