import { Component, Input, OnInit } from '@angular/core';
import { BasePageContent } from '../base-page-content';
import { ModuleFlowService } from '../../module-flow.service';
import { FlowValidationError } from '../../page.model';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';

const DEFAULT_ERROR = {
  error: 1,
  errorTitle: 'Unexpected',
  errorText: 'You cannot add response to this project at this point. Please try later or contact the Engagement Team.',
};

@Component({
  selector: 'app-error-page-component',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent extends BasePageContent implements OnInit {
  @Input() flowValidationError: FlowValidationError;

  constructor(
    flowService: ModuleFlowService,
    protected spinnerService: EyAppSpinnerService,
  ) {
    super(flowService, spinnerService);
  }

  get validationError(): FlowValidationError {
    return this.flowValidationError ?? DEFAULT_ERROR;
  }

  ngOnInit(): void {
    this.flowService.hideSaveAndProgressBarOnErrorFlowPage.next(true);
  }
}
