import { Component } from '@angular/core';
import { DECLINED_PRIVACY_NOTICE } from '../../core/constants/constants';

@Component({
  templateUrl: './privacy-notice-declined.component.html',
  styleUrls: ['./privacy-notice-declined.component.scss'],
})
export class PrivacyNoticeDeclinedComponent {
  noticeText = DECLINED_PRIVACY_NOTICE;
}
