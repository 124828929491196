<div class="d-flex" [ngClass]="{ dark: dark || darkDropdown }">
  <div class="flex-grow-1">
    <div class="dropdown dropdown--single-select" [ngClass]="{ dropup: dropUp }">
      <a
        role="button"
        aria-label="select"
        [attr.aria-expanded]="isExpanded"
        aria-haspopup="true"
        class="dropdown-toggle"
        data-bs-toggle="dropdown"
        [id]="'single-select-active'"
        (click)="toggleExpand()"
        (keydown)="toggleExpand()"
      >
        <input
          (focus)="onFocusInput()"
          #inputElement
          autocomplete="noAutocomplete"
          class="textinput-group__textinput focusable"
          [attr.title]="getSelectedValue()"
          [ngClass]="{
            'light-dark-mode': lightDarkDropdown,
            error: showError,
            slim: slim,
            'no-padding-top': floatingLabels && !hidePlaceholder(),
            'dark-placeHolder': showDarkPlaceHolder,
            'light-placeHolder': dark || darkDropdown,
            lightPaginationInput: lightBackgroundPaginationDropdown,
            autoWidth: autoWidth,
            'padding-top': darkDropdown && valueSelected,
          }"
          (ngModelChange)="filter($event)"
          [ngModel]="getSelectedValue()"
          [readOnly]="dark || valueSelected || isDisabled || darkDropdown"
          (blur)="blur()"
          [placeholder]="getPlaceHolderValue()"
          [attr.aria-label]="showError ? meta.errorMsg : 'select' + meta.title + (meta.info ? meta.info : '')"
          [attr.aria-invalid]="showError"
        />

        <label
          class="textinput-group__label asterisk focus"
          [class.asterisk]="!meta?.hideRequiredMarker && !!meta?.required"
          [fieldMetaTitle]="meta"
          [class.light-placeholder]="dark || darkDropdown"
          [for]="'dd_' + ctrl_id"
          *ngIf="!slim && hidePlaceholder()"
        >
          {{ meta.title }}
        </label>
        <i
          class="emp-icon icon-ico_shevron_down"
          [class.lightPagination]="lightBackgroundPaginationDropdown"
          *ngIf="!valueSelected || hasDefaultValue || dark || lightBackgroundPaginationDropdown"
        ></i>
        <i
          class="emp-icon icon-ico_close"
          *ngIf="valueSelected && !dark && !isDisabled && !hasDefaultValue && !lightBackgroundPaginationDropdown"
          (click)="clearSelected()"
        ></i>
      </a>
      <div
        id="dropdown"
        aria-labelledby="single-select-active "
        class="dropdown-menu mb-auto w-100"
        [ngClass]="{ show: isExpanded, hide: !isExpanded }"
      >
        <button
          *ngFor="let v of filteredValues"
          [class.maxHeight]="maxContent"
          class="dropdown-item-custom w-100 lh-1-5rem"
          [ngClass]="{ 'p-10': dark || darkDropdown }"
          (click)="valSelected(v)"
        >
          <span
            class="font-875"
            [title]="v[labelName]"
            [class.dropDownItemWrap]="enableTwoLineCutOffForDropDownItem"
            [ngClass]="{ 'p-0 m-0': dark || darkDropdown }"
            >{{ v[labelName] }}</span
          >
        </button>
      </div>
      <label *ngIf="showError" class="textinput__errmsg"><i class="err_icon icon-ico_error_toast"></i>{{ meta.errorMsg }}</label>
    </div>
  </div>
  <div class="tooltip--holder" *ngIf="!dark && !slim && !noInfo && !lightBackgroundPaginationDropdown">
    <ey-input-tooltip *ngIf="meta.info" [toolTipContent]="meta.info"></ey-input-tooltip>
  </div>
</div>
