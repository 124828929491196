import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/core/models/user/user.model';
import { UserService } from 'src/app/core/services/user-service';
import { UserOtherPermissions, UserPermissions } from '../../user-permissions.model';
import { UserPermissionsService } from '../../user-permissions.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() isMobileBreakpoint = false;
  user: User = new User();
  roleHasReadAdminSetupPermission = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private userService: UserService,
    private ups: UserPermissionsService,
  ) {}

  ngOnInit(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: User) => {
        this.user = result;
        const otherPermissions = new UserOtherPermissions();
        this.roleHasReadAdminSetupPermission =
          this.user.permissions != null ? this.user.permissions.some((p) => p === otherPermissions.ReadAdminSetup) : false;
      });
  }
}
