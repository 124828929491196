import {
  AfterContentInit,
  AfterViewChecked,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { TableData } from './models/table-data.model';
import { GridState } from './models/grid-state.model';
import { SortDirection } from './models/sort-direction.model';
import { ActiveFilter } from './models/active-filter.model';
import { IRowExpandStatus } from './ey-row-expand/ey-row-expand.component';
import { EyGridSortComponent } from './ey-grid-sort/ey-grid-sort.component';
import { EyTemplateDirective } from '../helpers/ey-template.directive';
import { EyAppSpinnerService } from '../ey-app-spinner/ey-app-spinner.service';
import { EyRowExpandComponent } from './ey-row-expand/ey-row-expand.component';
import { EyGridMenuService } from 'src/app/shared/components/ey-grid/ey-grid-menu/ey-grid-menu.service';

export const DEF_GRID_SATE: GridState = {
  pageNumber: 1,
  pageSize: 10,
  search: '',
  sortBy: '',
  sortDirection: SortDirection.ASC,
  activeFilters: [],
};

@Component({
  selector: 'ey-grid',
  templateUrl: './ey-grid.component.html',
  styleUrls: ['./ey-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EyGridComponent implements OnInit, OnDestroy, AfterViewChecked, AfterContentInit {
  @Input() getData: (gridState: GridState) => Observable<TableData<any>> = null;
  @Input() lightPaginationPanel: false;
  @Input() showFilter = true;
  @Input() lightTable = false;
  @Input() tableBordersNone = false;
  @Input() showSearch = true;
  @Input() useCustomFiltering = false;
  @Input() searchInputLength = 2;
  @Input() subTableExpandToolTip: string;
  @Input() enablePaging = false;
  @Input() fixedWidth = false;
  @Input() gridState: GridState = { ...DEF_GRID_SATE };
  @Output() filterSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterCount: EventEmitter<any> = new EventEmitter<number>();
  @Output() totalDataCount: EventEmitter<any> = new EventEmitter<number>();
  /* can be removed if ng-content detection added */
  @Input() hasSubTable = false;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('tableHolder') tableHolder: ElementRef;
  @ViewChildren(EyRowExpandComponent) childRows: QueryList<EyRowExpandComponent>;
  @ContentChildren(EyTemplateDirective) templates: QueryList<EyTemplateDirective>;
  @ContentChildren(EyGridSortComponent, { descendants: true }) eyGridSortComponents: QueryList<EyGridSortComponent>;
  /* multi select search flags */
  totalCount: number;
  bodyTemplate: TemplateRef<any>;
  subTableTemplate: TemplateRef<any>;
  noContentTemplate: TemplateRef<any>;

  subTablesVisibility: { visible: boolean }[] = [];
  destroy$: Subject<void> = new Subject<void>();
  noContent = false;

  loaded = false;

  content: TableData<any> = null;
  isFilteringHidden = true;

  isReloadingData = false;
  subscribedToInput: boolean;
  @HostListener('window:resize')
  onWindowResize(): void {
    this.updateGridOffsetPosition();
  }

  constructor(
    private spinnerService: EyAppSpinnerService,
    private eyGridMenuService: EyGridMenuService,
  ) {}

  get des(): SortDirection {
    return SortDirection.DES;
  }

  updateGridOffsetPosition(): void {
    // Purpose: To update the offset correctly when grid table is scrolled on smaller screens or when window is resized.
    // The updated offset is being used in ey-grid-menu to update the "more action" dropdown position.
    const gridMenuTable = this.tableHolder?.nativeElement;
    const gridMenuTableScrollLeft = gridMenuTable?.scrollLeft;
    this.eyGridMenuService.gridMenuScrollLeftOffset.next(gridMenuTableScrollLeft);
  }

  ngAfterViewChecked(): void {
    if (!this.subscribedToInput && this.searchInput) {
      this.subscribedToInput = true;

      fromEvent(this.searchInput.nativeElement, 'input')
        .pipe(
          takeUntil(this.destroy$),
          debounceTime(1500),
          map((event: any) => event.target.value),
        )
        .subscribe((input) => {
          if (input.length >= this.searchInputLength) {
            this.gridState.search = input;
            this.gridState.pageNumber = 1;
            this.loadData();
          } else {
            this.gridState.search = '';
            this.gridState.pageNumber = 1;
            this.loadData();
          }
        });
    }
  }

  openFilterOptions(): void {
    this.filterSelect.emit();
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    if (!this.getData) {
      return;
    }
    const getData$ = this.spinnerService.withLoadingIndicator(this.getData(this.gridState), this.destroy$);

    getData$.subscribe(
      (tData: TableData<any>) => {
        this.totalCount = tData.totalCount;
        this.content = tData;
        this.isReloadingData = false;
        this.loaded = true;
        this.noContent = tData.totalCount === 0;
        if (this.gridState.search?.length > 0 || this.gridState.activeFilters?.length > 0) {
          this.emitFilterCount(this.totalCount);
        } else {
          this.emitFilterCount(null);
          this.emitTotalDataCount(tData?.totalCount);
        }
      },
      (err) => console.log(err),
    );
  }

  emitFilterCount(filterCount: number): void {
    this.filterCount.emit(filterCount);
  }

  emitTotalDataCount(filterCount: number): void {
    this.totalDataCount.emit(filterCount);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onSort(column: string, order: SortDirection): void {
    this.gridState = { ...this.gridState, sortBy: column, sortDirection: order };
    this.loadData();
  }

  currentPageChanged(pageNumber: number): void {
    this.resetExpandStatus();
    this.gridState.pageNumber = pageNumber;
    this.loadData();
  }

  currentPageSizeChanged(pageSize: number): void {
    this.resetExpandStatus();
    this.gridState.pageSize = pageSize;
    this.gridState.pageNumber = 1;
    this.loadData();
  }

  onFiltersChange(activeFilters: Array<ActiveFilter>): void {
    this.gridState.pageNumber = 1;
    this.gridState.activeFilters = activeFilters;
    this.loadData();
  }

  ngAfterContentInit(): void {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'body':
          this.bodyTemplate = item.template;
          break;
        case 'subTable':
          this.subTableTemplate = item.template;
          break;
        case 'noContent':
          this.noContentTemplate = item.template;
          break;
      }
    });

    this.eyGridSortComponents.forEach((c) =>
      c.sort.pipe(takeUntil(this.destroy$)).subscribe((sea) => {
        this.onSort(sea.column, sea.direction);
      }),
    );
  }

  resetExpandStatus(rowIndex?: number): void {
    this.subTablesVisibility = [];
    if (rowIndex !== null) {
      this.childRows.filter((c) => c.index !== rowIndex).forEach((c) => c.collapse());
    } else {
      this.childRows.forEach((c) => c.collapse());
    }
  }

  onExpandStatusChanged(status: IRowExpandStatus): void {
    this.resetExpandStatus(status.index);
    this.subTablesVisibility[status.index] = { visible: status.visible };
  }

  addRowIndex(data: any, rI: number): any {
    return { ...data, rowIndex: rI };
  }
}
