import { PillType } from '../../../shared/components/ey-pill/ey-pill.component';
import { BULK_DOWNLOAD_RESPONSE_ACTIONS, BulkDownloadActionTypes } from './bulk-download-responses.action.dictionary';

export const DOWNLOAD_STATUS = {
  1: { label: 'Download: In Progress', pillType: PillType.orange, actions: [] },
  2: { label: 'Download: In Progress', pillType: PillType.orange, actions: [] },
  4: {
    label: 'Download: Error',
    pillType: PillType.red,
    actions: BULK_DOWNLOAD_RESPONSE_ACTIONS.filter((a) => a.actionType !== BulkDownloadActionTypes.Download),
  },
  3: {
    label: 'Download: Ready',
    pillType: PillType.green,
    actions: BULK_DOWNLOAD_RESPONSE_ACTIONS.filter((a) => a.actionType === BulkDownloadActionTypes.Download),
  },
};

export enum DownloadStatuses {
  Pending = 1,
  InProgress,
  Ready,
  Error,
}
