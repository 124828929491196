import { createReducer, on } from '@ngrx/store';
import {
  addOrUpdateSpecificPart,
  clearSpecificParts,
  cloneSpecificPart,
  deleteSpecificParts,
  loadSpecificParts,
  removeOptionForDecisionTool,
} from './actions';
import { SpecificPart } from './specific-part';

const initialState: SpecificPart[] = [];
export const specificPartsReducers = createReducer(
  initialState,
  on(addOrUpdateSpecificPart, (state, action) => [...state.filter((s) => s.id !== action.specificPart.id), action.specificPart]),
  on(clearSpecificParts, () => []),
  on(deleteSpecificParts, (state, action) => [...state.filter((s) => s.toolId !== action.toolId)]),
  on(loadSpecificParts, (_, action) => action.specificParts),
  on(removeOptionForDecisionTool, (state, action) => {
    const decisionToolParts = state.find((s) => s.toolId === action.decisionToolId);

    if (!decisionToolParts) {
      return state;
    }

    const newPart = {
      ...decisionToolParts,
      children: [...decisionToolParts.children.filter((c) => c.conId !== action.connectionId)],
    };

    return [...state.filter((s) => s.id !== newPart.id), newPart];
  }),
  on(cloneSpecificPart, (state, action) => {
    if (action.typeNameAlias.cloneSpecificPart) {
      const specificPartCloneSource = state.find((s) => s.toolId === action.rectTool.id);
      return [...state, action.typeNameAlias.cloneSpecificPart(action.shape, specificPartCloneSource, action.rectTool)];
    }
    return [...state];
  }),
);
