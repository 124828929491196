import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModuleFlowService } from 'src/app/designer/preview/module-flow.service';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';
import { BasePageContent } from '../base-page-content';

@Component({
  selector: 'app-unknown-page',
  templateUrl: './unknown-page.component.html',
  styleUrls: ['./unknown-page.component.scss'],
})
export class UnknownPageComponent extends BasePageContent {
  constructor(
    flowService: ModuleFlowService,
    protected spinnerService: EyAppSpinnerService,
  ) {
    super(flowService, spinnerService);
  }
}
