<form [formGroup]="form">
  <div class="d-flex flex-fill flex-column">
    <div class="d-flex flex-fill flex-column p-20 border-bottom divider-height">
      <ey-drop-down
        formControlName="dividerStyle"
        [meta]="meta.dividerStyle"
        [values]="dividerStyles"
        [defaultValue]="dividerStyles[1]"
        noInfo="true"
        class="mb-20"
      ></ey-drop-down>
    </div>
  </div>
</form>
