import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModuleVersionVideo, VideoDeletionResult } from './module-version-video.model';

export const MAP_FIELDS_URL = '/module/version-mappings/';

@Injectable({
  providedIn: 'root',
})
export class VideoSourceService {
  private baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) {}

  getVideoSasUrl(videoId: string): Observable<string> {
    return this.httpClient.get<string>(`${this.baseUrl}/video/${videoId}`);
  }

  getVideosForModule(moduleId: string): Observable<ModuleVersionVideo[]> {
    return this.httpClient.get<ModuleVersionVideo[]>(`${this.baseUrl}/video/module/${moduleId}`);
  }

  isVideoUnique(moduleId: string, fileName: any): Observable<any> {
    return this.httpClient.get<boolean>(`${this.baseUrl}/video/module/${moduleId}/is-video-file-name-unique?fileName=${fileName}`);
  }

  uploadVideo(moduleId: string, versionId: string, data: any): Observable<string> {
    return this.httpClient.post<string>(`${this.baseUrl}/video/upload/${moduleId}/${versionId}`, data);
  }

  deleteVideo(videoId: string): Observable<VideoDeletionResult> {
    return this.httpClient.delete<VideoDeletionResult>(`${this.baseUrl}/video/${videoId}`);
  }
}
