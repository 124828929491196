import { Injectable } from '@angular/core';
import { ProgressBarStep, ProgressStates } from './progress-bar-step.model';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {
  constructor() {}

  next(steps: ProgressBarStep[]): ProgressBarStep[] {
    const activeStepId: number = this.getActiveStep(steps).id;
    steps = this.markStepAsCompleted(steps, activeStepId);
    steps = this.markStepAsActive(steps, activeStepId + 1);
    if (steps.find((t) => t.id === activeStepId + 1).state !== ProgressStates.completed) {
      this.markStepAsInProgress(steps, activeStepId + 1);
    }
    return steps;
  }

  prev(steps: ProgressBarStep[]): ProgressBarStep[] {
    return this.markStepAsActive(steps, this.getActiveStep(steps).id - 1);
  }

  getActiveStep(steps: ProgressBarStep[]): ProgressBarStep {
    return steps.find((t) => t.active === true);
  }

  private markStepAsCompleted(steps: ProgressBarStep[], stepId: number): ProgressBarStep[] {
    steps.find((s) => s.id === stepId).state = ProgressStates.completed;
    return [...steps];
  }

  private markStepAsInProgress(steps: ProgressBarStep[], stepId: number): ProgressBarStep[] {
    steps.find((s) => s.id === stepId).state = ProgressStates.inProgress;
    return [...steps];
  }

  private markStepAsActive(steps: ProgressBarStep[], stepId: number): ProgressBarStep[] {
    steps.forEach((s) => (s.active = false));
    steps.find((s) => s.id === stepId).active = true;
    return [...steps];
  }
}
