<form [formGroup]="form">
  <h3 class="fonts-col__heading-3 m-0 mb-20">Data Mapping<span> (4/4)</span></h3>

  <div *ngIf="mappingTemplates?.length > 0">
    <h4 class="fonts-col__heading-4 m-0 mb-20">Reuse Existing Mapping Template</h4>
    <ey-drop-down
      [noInfo]="true"
      class="d-block mb-20"
      formControlName="mappingTemplates"
      labelName="name"
      [values]="mappingTemplates"
      [meta]="meta.mappingTemplate"
    >
    </ey-drop-down>
  </div>

  <div class="d-flex w-100">
    <ey-input [showSearchIcon]="true" extra="none" class="d-block mb-20 w-100" [meta]="meta.search" formControlName="search"></ey-input>
  </div>

  <div formArrayName="mappings">
    <div>
      <div class="d-flex mt-30 mb-20">
        <div class="flex-grow-1">Module Fields</div>
        <div class="flex-grow-1 d-flex excel-templates-header">
          <div class="flex-grow-1">Excel Columns</div>
          <div>
            <a class="clear-all-link" href="javascript:void(0)" (click)="clearAllMappings()">Clear All</a>
          </div>
        </div>
      </div>
      <div *ngFor="let item of form.get('mappings')['controls']; let i = index" [formGroupName]="i">
        <div class="d-flex" [class.display-none]="this.hiddenModuleFields[item.value.mappingField]?.hidden">
          <div class="flex-grow-1">
            <fieldset>
              <ey-input
                [readOnly]="true"
                [disableErrorEvaluation]="true"
                extra="none"
                class="d-block mb-20"
                [meta]="meta.mappingField"
                formControlName="mappingField"
              ></ey-input>
            </fieldset>
          </div>
          <div class="text-align-center ms-20 me-20 mt-10">=</div>
          <div class="set-dropdown-width">
            <app-ey-dropdown-light-weight
              #excelColumnDropdownContainer
              (click)="dropdownClickFn(i)"
              [meta]="meta.excelColumn"
              formControlName="excelColumn"
              class="d-block mb-20"
            ></app-ey-dropdown-light-weight>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <fieldset [disabled]="disableSaveMappings">
      <div class="checkbox-container-section">
        <label class="checkbox">
          <input class="checkbox__input" type="checkbox" id="save-template" formControlName="saveTemplate" />
          <span class="checkbox__checkmark"></span>
          <p class="checkbox__label">Save your mapping as a Template for future use</p>
        </label>
      </div>
    </fieldset>
    <!-- ey-checkbox-group-plain
      class="input-height"
      [values]="checkboxValues"
      formControlName="saveTemplate"
      [meta]="meta.saveTemplate"
      extra="none"
    ></ey-checkbox-group-plain -->
    <div *ngIf="this.form.get('saveTemplate').value">
      <ey-input extra="none" class="d-block mb-20" [meta]="meta.templateName" formControlName="templateName"></ey-input>
      <ey-textarea
        [toolTipHolderOption]="toolTipHolderOptionsHidden"
        class="d-block mb-20"
        [meta]="meta.templateDescription"
        formControlName="templateDescription"
      ></ey-textarea>
    </div>
  </div>
</form>
