import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DraftModalAction, DraftModalResult } from './draft-modal-result';
import { DEF_GRID_SATE, EyGridComponent } from '../../../../shared/components/ey-grid/ey-grid.component';
import { GridState } from '../../../../shared/components/ey-grid/models/grid-state.model';
import { SortDirection } from '../../../../shared/components/ey-grid/models/sort-direction.model';
import { ResponseService } from '../../submit-response/response.service';
import { ResponsesPermissionsService } from '../../../../projects/responses/responses-permissions.service';
import { MULTIPLE_RESPONSE_MODE_ID, RESPONSE_MODES } from '../../../../projects/get-web-link/get-web-link.component';
import { Response } from '../../../../projects/responses/response.model';
import { IAction } from '../../../../projects/grid-actions/action.model';
import { IActionEventArgs } from '../../../../projects/grid-actions/grid-actions.component';
import { ActionTypes } from '../../../../projects/responses/responses-action.dictionary';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { EyFormBaseComponent } from '../../../../shared/components/ey-form-base/ey-base-form-control';
import { ProjectsService } from '../../../../projects/projects.service';

@Component({
  selector: 'app-draft-modal',
  templateUrl: './draft-modal.component.html',
  styleUrls: ['./draft-modal.component.scss'],
})
export class DraftModalComponent extends EyFormBaseComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();
  @ViewChild(EyGridComponent) grid: EyGridComponent;
  @Input() collaborationMode = false;
  createNewResponseEnabled = false;
  numberOfDraftResponses: number;
  state: GridState = {
    ...DEF_GRID_SATE,
    pageSize: 50,
    sortBy: 'dateCreated',
    sortDirection: SortDirection.DES,
  };
  @Input() taskId: string;
  @Input() showDraftsPopup = false;

  constructor(
    private activeModal: NgbActiveModal,
    private responseService: ResponseService,
    private responsesPermissionsService: ResponsesPermissionsService,
    private projectService: ProjectsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadData();
  }

  getData(): any {
    return this.getResponseData;
  }

  loadData(): void {
    this.projectService
      .getProjectInfoForResponse(this.taskId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectInfo) => {
        const multipleResponsesEnabled = RESPONSE_MODES.find((response) => response.id === projectInfo.responseMode);
        this.createNewResponseEnabled = multipleResponsesEnabled.id === MULTIPLE_RESPONSE_MODE_ID;
      });
  }

  getResponseData = (state) => this.responseService.getUserDraftResponses(this.taskId, state);

  getResponseActionNavElements(): IAction[] {
    return this.responsesPermissionsService.getResponsesActionNavElementsSubmitResponse(this.collaborationMode);
  }

  formatDate(respDate: string): string {
    const responseDate = new Date(respDate);
    return `${responseDate.toLocaleString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' })}, ${responseDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' })} (UTC)`;
  }

  onActionSelect(aea: IActionEventArgs, responseData: Response): void {
    switch (aea.actionType) {
      case ActionTypes.ResumeDraft:
        this.onResumeDraft(responseData.id);
        break;
      case ActionTypes.Delete:
        this.onDeleteResponse(responseData);
        break;
    }
  }

  onCreateNewResponse(): void {
    this.activeModal.close(new DraftModalResult(DraftModalAction.createNewResponse));
  }

  onResumeDraft(responseId: string): void {
    this.activeModal.close(new DraftModalResult(DraftModalAction.resumeDraft, responseId));
  }

  displayNumberOfDraftResponses(countOfDraftResponses: number): void {
    this.numberOfDraftResponses = countOfDraftResponses;
  }

  enableCreateNewResponse(): boolean {
    return this.createNewResponseEnabled ? true : this.numberOfDraftResponses === 0;
  }

  onDeleteResponse(responseData: any): void {
    this.responseService.deleteResponse(responseData.projectId, responseData.id).subscribe((res) => {
      this.grid.loadData();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
