import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { IEyRadioGroupModel } from '../../../shared/components/ey-radio-group/ey-radio-group.model';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { EyFormBaseComponent } from '../../../shared/components/ey-form-base/ey-base-form-control';
import { UploadMethods } from '../upload-method/upload-method.component';
import * as _ from 'lodash';

export const CompleteResponseAllowedOnlyToolTip = `Completed responses are allowed only for simple flow.`;
export const PartialResponseAllowedOnlyToolTip = `Draft response are not allowed in Project settings because of that, you can't upload partial responses.`;
export enum DataPurposes {
  PartialResponseData = 1,
  CompletedResponseData,
  NotSelected,
}

export const DataPurposesRGM: IEyRadioGroupModel[] = [
  {
    label: 'Partial Responses Data',
    value: DataPurposes.PartialResponseData,
    description: [
      'Allows you to partially or completely pre-populate responses  and then save in draft mode, so that you can assign it to end users for completion. Uploaded responses will be waiting for your review before will be visible for end users.',
      '    ',
      'For example, you can make it easy for your end users by prepopulating their forms with information you already known.',
    ],
  },
  {
    label: 'Completed Responses Data',
    value: DataPurposes.CompletedResponseData,
    description: [
      'Use this mode if you have a spreadsheet with complete data set required by the Module.',
      '    ',
      'For example - you can automatically generate hundreds of documents in bulk if your Project has linked templates. In this mode responses will be automatically assigned to the user who performs bulk upload',
    ],
  },
];

export const DATA_PURPOSES_FIELDS_META = {
  dataPurpose: {
    title: 'Data Purpose',
    errorMsg: 'Select data purpose to proceed.',
    required: true,
  },
};

@Component({
  selector: 'app-data-purpose',
  templateUrl: './data-purpose.component.html',
  styleUrls: ['./data-purpose.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DataPurposeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DataPurposeComponent),
      multi: true,
    },
  ],
})
export class DataPurposeComponent extends EyFormBaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() selectedUploadMethod: UploadMethods;
  @Input() isCompleteResponseAllowed: boolean;
  @Input() isPartialResponseAllowed: boolean;

  values = _.cloneDeep(DataPurposesRGM);
  form: UntypedFormGroup;
  meta = _.cloneDeep(DATA_PURPOSES_FIELDS_META);

  constructor(
    private fb: UntypedFormBuilder,
    private el: ElementRef,
  ) {
    super();
  }
  getNumberOfSteps(): number {
    return this.selectedUploadMethod === UploadMethods.UseMapping ? 4 : 3;
  }
  ngOnInit(): void {
    this.form = this.fb.group(
      {
        dataPurpose: [, Validators.required],
      },
      { updateOn: 'blur' },
    );
    this.manageToolTips();
  }

  writeValue(val: any): void {
    if (val) {
      this.form.patchValue(val, { emitEvent: false });
    }
  }
  public onTouched: () => void = () => {};
  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { subformerror: 'Data Purpose Form Error!' };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.form.statusChanges.subscribe(fn);
  }
  markAsTouched(): void {
    this.form.markAllAsTouched();
    this.focusOnError(this.form, this.el);
  }

  ngOnDestroy(): void {}
  setFocusOnTheFirstFormElement(): void {
    this.setFocusFirstFormElement(this.form, this.el);
  }

  manageToolTips(): void {
    if (this.isCompleteResponseAllowed !== this.isPartialResponseAllowed) {
      if (this.isPartialResponseAllowed) {
        this.values = _.cloneDeep(DataPurposesRGM);
        this.values.find((v) => v.value === DataPurposes.CompletedResponseData).tooltip = CompleteResponseAllowedOnlyToolTip;
      } else {
        this.values = _.cloneDeep(DataPurposesRGM);
        this.values.find((v) => v.value === DataPurposes.PartialResponseData).tooltip = PartialResponseAllowedOnlyToolTip;
      }
    }
  }
}
