import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { VersionStatus } from './version.const';
import { ActionTypes, VERSION_ACTIONS } from './version-action.dictionary';
import { IAction } from '../grid-actions/action.model';
import { BasePermissionsService } from '../base-permissions.service';
import { ModuleTabs, NavPlacement } from '../module.const';

@Injectable({
  providedIn: 'root',
})
export class VersionPermissionsService extends BasePermissionsService {
  getVersionActionNavElements(versionStatus: VersionStatus, moduleTab: ModuleTabs, modulePermissions: string[], isExternal: boolean): IAction[] {
    let actions: IAction[] = _.cloneDeep(VERSION_ACTIONS);

    switch (moduleTab) {
      case ModuleTabs.myModules:
        actions = this.actionsMyModules(actions, versionStatus, isExternal);
        break;
      case ModuleTabs.moduleLibrary:
        actions = this.actionsModuleLibrary(actions, versionStatus, isExternal);
        break;
      case ModuleTabs.myFavourites:
        actions = this.actionsModuleFavourites(actions, versionStatus, isExternal);
        break;
      case ModuleTabs.forMyReview:
        break;
      case ModuleTabs.moduleMgr:
        actions = this.actionsModuleMgr(actions, versionStatus, isExternal);
        break;
    }
    actions = this.removeActionsBasedOnPermissions(actions, modulePermissions);
    return actions;
  }

  private actionsMyModules(actions: IAction[], versionStatus: VersionStatus, isExternal: boolean): IAction[] {
    if (isExternal) {
      return [];
    } else {
      this.setNavPlacement(actions, ActionTypes.Clone, NavPlacement.primary);
      this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.none);
      switch (versionStatus) {
        case VersionStatus.Live:
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.secondary);
          break;
        case VersionStatus.LiveInRenewal:
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.primary);
          break;
        case VersionStatus.Expired:
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Expire, NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.none);
          break;
        case VersionStatus.ExpiredInRenewal:
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.none);
          break;
        case VersionStatus.Test:
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.Expire, NavPlacement.none);
          break;
        case VersionStatus.TestInPublishing:
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.Expire, NavPlacement.none);
          break;
        case VersionStatus.TestInReview:
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.Expire, NavPlacement.none);
          break;
        case VersionStatus.TestReviewed:
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.Expire, NavPlacement.none);
          break;
        default:
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Expire, NavPlacement.none);
          break;
      }
    }

    return [...actions];
  }

  private actionsModuleLibrary(actions: IAction[], versionStatus: VersionStatus, isExternal: boolean): IAction[] {
    if (isExternal) {
      switch (versionStatus) {
        case VersionStatus.Live:
          actions.forEach((a) => (a.navPlacement = NavPlacement.none));
          this.setNavPlacement(actions, ActionTypes.Download, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Clone, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          break;
        case VersionStatus.LiveInRenewal:
          actions.forEach((a) => (a.navPlacement = NavPlacement.none));
          this.setNavPlacement(actions, ActionTypes.Download, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Clone, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          break;
        case VersionStatus.Expired:
          actions.forEach((a) => (a.navPlacement = NavPlacement.none));
          this.setNavPlacement(actions, ActionTypes.Download, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Clone, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          break;
        case VersionStatus.ExpiredInRenewal:
          actions.forEach((a) => (a.navPlacement = NavPlacement.none));
          this.setNavPlacement(actions, ActionTypes.Download, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Clone, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          break;
        default:
          actions = [];
          break;
      }
    } else {
      this.setNavPlacement(actions, ActionTypes.Download, NavPlacement.primary);
      this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
      this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.none);
      switch (versionStatus) {
        case VersionStatus.Live:
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.secondary);
          break;
        case VersionStatus.ExpiredInRenewal:
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.secondary);
          break;
        case VersionStatus.LiveInRenewal:
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.secondary);
          break;
        case VersionStatus.Expired:
          this.setNavPlacement(actions, ActionTypes.Expire, NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.secondary);
          break;
        default:
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Expire, NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.secondary);
          break;
      }
    }
    return [...actions];
  }

  private actionsModuleFavourites(actions: IAction[], versionStatus: VersionStatus, isExternal: boolean): IAction[] {
    if (isExternal) {
      switch (versionStatus) {
        case VersionStatus.Live:
          actions.forEach((a) => (a.navPlacement = NavPlacement.none));
          this.setNavPlacement(actions, ActionTypes.Download, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Clone, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          break;
        case VersionStatus.LiveInRenewal:
          actions.forEach((a) => (a.navPlacement = NavPlacement.none));
          this.setNavPlacement(actions, ActionTypes.Download, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Clone, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          break;
        case VersionStatus.Expired:
          actions.forEach((a) => (a.navPlacement = NavPlacement.none));
          this.setNavPlacement(actions, ActionTypes.Download, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Clone, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          break;
        case VersionStatus.ExpiredInRenewal:
          actions.forEach((a) => (a.navPlacement = NavPlacement.none));
          this.setNavPlacement(actions, ActionTypes.Download, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Clone, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          break;
        default:
          actions = [];
          break;
      }
    } else {
      this.setNavPlacement(actions, ActionTypes.Download, NavPlacement.primary);
      this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
      this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.none);

      switch (versionStatus) {
        case VersionStatus.Live:
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.secondary);
          break;
        case VersionStatus.LiveInRenewal:
          this.setNavPlacement(actions, ActionTypes.Expire, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.secondary);
          break;
        case VersionStatus.ExpiredInRenewal:
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.secondary);
          break;
        case VersionStatus.Expired:
          this.setNavPlacement(actions, ActionTypes.Expire, NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.secondary);
          break;
        default:
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Expire, NavPlacement.none);
          break;
      }
    }

    return [...actions];
  }

  private actionsModuleMgr(actions: IAction[], versionStatus: VersionStatus, isExternal: boolean): IAction[] {
    if (isExternal) {
      switch (versionStatus) {
        case VersionStatus.Live:
          actions.forEach((a) => (a.navPlacement = NavPlacement.none));
          this.setNavPlacement(actions, ActionTypes.Download, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.Clone, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.ShowCanvasFlow, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          break;
        case VersionStatus.LiveInRenewal:
          actions.forEach((a) => (a.navPlacement = NavPlacement.none));
          this.setNavPlacement(actions, ActionTypes.Download, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.Clone, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.ShowCanvasFlow, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          break;
        case VersionStatus.Expired:
          actions.forEach((a) => (a.navPlacement = NavPlacement.none));
          this.setNavPlacement(actions, ActionTypes.Download, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.Clone, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.ShowCanvasFlow, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          break;
        case VersionStatus.ExpiredInRenewal:
          actions.forEach((a) => (a.navPlacement = NavPlacement.none));
          this.setNavPlacement(actions, ActionTypes.Download, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.Clone, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.ShowCanvasFlow, NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
          break;
        default:
          actions = [];
          break;
      }
    } else {
      this.setNavPlacement(actions, ActionTypes.ShowCanvasFlow, NavPlacement.primary);
      this.setNavPlacement(actions, ActionTypes.ShowPreview, NavPlacement.primary);
      // this.setNavPlacement(actions, ActionTypes.Renew,  NavPlacement.none);
      switch (versionStatus) {
        case VersionStatus.Expired:
          this.setNavPlacement(actions, ActionTypes.Expire, NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.secondary);
          break;
        case VersionStatus.Live:
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.secondary);
          break;
        case VersionStatus.LiveInRenewal:
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.secondary);
          break;
        case VersionStatus.ExpiredInRenewal:
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.Expire, NavPlacement.secondary);
          this.setNavPlacement(actions, ActionTypes.Edit, NavPlacement.none);
          break;
        default:
          this.setNavPlacement(actions, ActionTypes.Renew, NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Expire, NavPlacement.none);
          break;
      }
    }

    return [...actions];
  }

  setNavPlacement(actions: IAction[], actionType: ActionTypes, navPlacement: NavPlacement): IAction[] {
    actions.find((a) => a.actionType === actionType).navPlacement = navPlacement;
    return actions;
  }
}
