import { NavPlacement } from '../module.const';
import { IAction } from '../grid-actions/action.model';
import { ModuleActionsPermissions } from '../module-permissions.const';
import { UserModulePermissions } from 'src/app/core/user-permissions.model';

export enum ActionTypes {
  Clone = 'Clone',
  Download = 'Download',
  Edit = 'Edit Information',
  Info = 'Info',
  Delete = 'Delete',
  Renew = 'Renew Version',
  Expire = 'Expire',
  ShowPreview = 'Show Preview',
  /* todo(MK): this has to be excluded from grid actions but is visble in module mgr - versions*/
  ShowCanvasFlow = 'Go to Designer Flow for the Version' /*'Show Canvas Flow (not a separate icon but iniated by click on version name or arrow)',*/,
}

export const VERSION_ACTIONS: IAction[] = [
  {
    actionType: ActionTypes.ShowCanvasFlow,
    navPlacement: NavPlacement.none,
    icon: 'icon-ico_design',
    requiredPermissions: [ModuleActionsPermissions.read],
  },
  {
    actionType: ActionTypes.Clone,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_copy',
    requiredPermissions: [ModuleActionsPermissions.createModuleFlow, new UserModulePermissions().CreateAllModulesFlow],
  },
  {
    actionType: ActionTypes.Download,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_download',
    requiredPermissions: [ModuleActionsPermissions.readModuleFlow, new UserModulePermissions().ReadAllModulesFlow],
  },
  {
    actionType: ActionTypes.Edit,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_edit',
    toolTip: 'Edit Version Parameters',
    requiredPermissions: [ModuleActionsPermissions.updateModuleFlow, new UserModulePermissions().UpdateAllModulesFlow],
  },
  {
    actionType: ActionTypes.Info,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_tooltip',
    requiredPermissions: [ModuleActionsPermissions.readModuleFlow, new UserModulePermissions().ReadAllModulesFlow],
  },
  {
    actionType: ActionTypes.Delete,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_delete',
    requiredPermissions: [ModuleActionsPermissions.deleteModuleFlow, new UserModulePermissions().DeleteAllModulesFlow],
  },
  {
    actionType: ActionTypes.Renew,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_RenewVersion',
    toolTip: 'Renew Version',
    requiredPermissions: [ModuleActionsPermissions.updateModuleFlow, new UserModulePermissions().UpdateAllModulesFlow],
  },
  {
    actionType: ActionTypes.Expire,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_ExpireVersion',
    toolTip: 'Expire Version',
    requiredPermissions: [ModuleActionsPermissions.updateModuleFlow, new UserModulePermissions().UpdateAllModulesFlow],
  },
  {
    actionType: ActionTypes.ShowPreview,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_eye',
    requiredPermissions: [ModuleActionsPermissions.readModuleFlow],
  },
];
