import { IConnectedExcelAITemplate } from '../workflow-designer/specific-parts/specific-part';

export interface FlowValidationError {
  error: number;
  errorTitle: string;
  errorText: string;
}

export enum ValidationErrorType {
  PropertyNotExist,
  ValidationNotExist,
  EmptyProperty,
  InvalidFormat,
  OutOfRange,
  FieldIsRequired,
  WrongInput,
  InvalidOptionValue,
  ExcelAIMandatoryValueMissing,
}

export interface PageValidationResult {
  pageId: string;
  propertyValidationErrors: PropertyValidationError[];
  propertyValidationWarnings: PropertyValidationError[];
}

export interface PropertyValidationError {
  error: ValidationErrorType;
  message: string;
  propertyName: string;
}

export interface Page {
  errors: PageValidationResult[];
  moduleFlowPage: any;
  sessionId: string;
  flowValidationError?: FlowValidationError;
  progressBarPercentage?: any;
}

export enum ViewTypes {
  desktop,
  tablet,
  mobile,
}

export enum ValidationSeverityType {
  None = 0,
  Warning,
  Error,
}

export interface IUploadedFile {
  fileId: string;
  tempId: string;
  name: string;
  type: string;
}

export interface IModuleFlowPage {
  id: string;
  type: string;
  name: string;
}

export interface IExcelAIPage extends IModuleFlowPage {
  mode: number;
  connectedInputPageId: string;
  connectedTemplate: IConnectedExcelAITemplate;
}
