export interface IBulkUploadResponse {
  status: BulkUploadStatus;
  uploadedResponsesCount: number;
  isPartialResponseAllowed?: boolean;
  isCompleteResponseAllowed?: boolean;
}

export enum BulkUploadStatus {
  DraftNotAllowed = 0,
  UploadFilesExist = 1,
  Upload = 2,
  // ... add additional statuses
}

export interface IBulkImportResponsesPayload {
  dataPurpose: number;
  uploadMethod: number;
  documentIds: string[];
  mappings?: IMapping[];
  saveTemplate?: boolean;
  templateName?: boolean;
  templateDescription?: boolean;
}

export interface IMapping {
  mappingField: string;
  excelColumn: string;
}

export interface IMappingTemplate {
  name: string;
  description: string;
  mappings: IMapping[];
  saveMappingTemplate?: boolean;
}
