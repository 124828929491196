import { Directive, HostListener } from '@angular/core';
import { BLANK_TARGET } from '../../designer/preview/page-templates/fluid-footer-template/fluid-footer-template.component';

@Directive({
  selector: '[appMouseEvents]',
})
export class MouseEventsDirective {
  @HostListener('click', ['$event'])
  onMouseLeftClickEvent(event: MouseEvent): void {
    // Perform actions for left mouse click
    const anchorElement = event?.target instanceof HTMLAnchorElement;
    if (!anchorElement) {
      return;
    }
    // need not to change any behaviour for other links which doesn't have href property set
    const linkAttribute = this.checkForLinkAttributes(event);
    linkAttribute ? this.checkForAnchorElementAndOpenLink(event, linkAttribute) : null;
  }

  checkForLinkAttributes(event: MouseEvent): string {
    return (event.target as HTMLAnchorElement).getAttribute('href');
  }

  checkForAnchorElementAndOpenLink(event: MouseEvent, linkAttribute: string): void {
    if (event.target instanceof HTMLAnchorElement) {
      event.preventDefault();
      // for blank targets links should open in new tab rest of the links open in new window.
      event.target.target !== BLANK_TARGET
        ? window.open(linkAttribute, BLANK_TARGET, 'width=600,height=400')
        : window.open(linkAttribute, BLANK_TARGET);
    }
  }

  @HostListener('contextmenu', ['$event'])
  onMouseRightClickEvent(event: MouseEvent): void {
    event.target instanceof HTMLVideoElement ? event.preventDefault() : null;
  }

  constructor() {}
}
