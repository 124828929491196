<div class="d-flex flex-row-reverse">
  <i
    tabindex="0"
    role="button"
    aria-label="show less"
    *ngIf="expanded"
    class="emp-icon icon-ico_shevron_up_big"
    [title]="toolTipText"
    (click)="toggle()"
  ></i>
  <i
    tabindex="0"
    role="button"
    aria-label="show more"
    *ngIf="!expanded"
    class="emp-icon icon-ico_shevron_down_big"
    [title]="toolTipText"
    (click)="toggle()"
  ></i>
</div>
