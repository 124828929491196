import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EyBaseFormControlComponent } from '../ey-base-form-control/ey-base-form-control';

@Component({
  selector: 'ey-drop-down',
  templateUrl: './ey-drop-down.component.html',
  styleUrls: ['./ey-drop-down.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyDropDownComponent),
      multi: true,
    },
  ],
})
export class EyDropDownComponent extends EyBaseFormControlComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @ViewChild('inputElement') inputElement: ElementRef;
  @Input() labelName = 'name';
  // darkDropdown inputs are used for dropdowns having dark mode but are being used as input field which may change
  // and would have the ability to remove inputs which are selected.
  @Input() darkDropdown = false;
  @Input() autoWidth = false;
  @Input() autoFocus = false;
  @Input() maxContent = false;
  @Input() lightDarkDropdown = false;
  @Input() enableTwoLineCutOffForDropDownItem = false;
  @Input() lightBackgroundPaginationDropdown = false;
  @Input() set values(val) {
    this._values = val;
    this.filteredValues = val;
  }

  @Input() set defaultValue(val) {
    this.hasDefaultValue = true;
    this.valSelected(val);
  }
  hasDefaultValue = false;
  inputFocussed: boolean;

  _values: any[] = [];
  _selectedValue: any = {};
  valueSelected = false;
  filteredValues: any[] = [];
  @Input() placeHolderValue = null;
  @Input() floatingLabels = false;
  @Input() showDarkPlaceHolder = false;
  // dark inputs here is just used for dark pagination panel at the bottom which selects number of rows shown
  // and this would not have the ability to remove selected input.
  @Input() dark: false;
  @Input() slim = false;
  @Input() dropUp = false;
  @Input() noInfo = false;
  // TODO: change this workaround with fix of @Input() isDisabled issue
  @Input() isDropdownDisabled = false;
  @Output() onType: EventEmitter<string> = new EventEmitter<string>();
  @Input() filteringDisabled = false;
  @Output() selectionRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionAdded: EventEmitter<any> = new EventEmitter<any>();
  isExpanded = false;

  constructor(
    private eRef: ElementRef,
    controlContainer: ControlContainer,
  ) {
    super(controlContainer);
  }

  @HostListener('document:click', ['$event'])
  clickOut(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isExpanded = false;
    }
  }

  ngOnInit(): void {
    this.inputFocussed = this.autoFocus;
  }

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      this.inputElement.nativeElement.focus();
    }
  }

  onFocusInput(): void {
    this.inputFocussed = true;
  }

  filter(val): void {
    if (val) {
      this.onType.emit(val);
      if (!this.filteringDisabled) {
        const startsWith = this._values.filter((v) => v[this.labelName].search(new RegExp('^' + val, 'i')) !== -1);
        let containsList = this._values.filter((v) => v[this.labelName].search(new RegExp(val, 'i')) !== -1);
        containsList = containsList.filter((e) => !startsWith.includes(e));
        this.filteredValues = [...startsWith, ...containsList];
      }
      this.isExpanded = true;
    } else {
      this.filteredValues = this._values;
    }
  }

  toggleExpand(): void {
    if ((this.valueSelected && !this.dark && !this.hasDefaultValue && !this.lightBackgroundPaginationDropdown) || this.isDropdownDisabled) {
      return;
    }
    this.isExpanded = !this.isExpanded;
  }

  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  set selectedValue(val) {
    this._selectedValue = val;
    this.onChange(val);
    this.onTouched(val);
  }

  get selectedValue(): any {
    return this._selectedValue;
  }

  writeValue(initValue: any): void {
    this._selectedValue = initValue || [];
    this.valueSelected = initValue != null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  valSelected(v: any): void {
    this.isExpanded = false;
    this.selectedValue = v;
    this.valueSelected = true;
    this.selectionAdded.emit(true);

    if (this.hasDefaultValue) {
      this.onTouched(this.selectedValue);
      this.onChange(this.selectedValue);
    }
  }

  getPlaceHolderValue(): string {
    return !this.isExpanded && !this.inputFocussed && this.floatingLabels ? this.placeHolderValue : '';
  }

  hidePlaceholder(): boolean {
    if (this.floatingLabels) {
      return this.isExpanded || this.valueSelected || this.inputFocussed ? true : false;
    } else {
      return true;
    }
  }

  clearSelected(): void {
    this.selectedValue = null;
    this.valueSelected = false;
    this.filteredValues = this._values;
    this.selectionRemoved.emit(true);
  }

  getSelectedValue(): any {
    return this.selectedValue == null ? null : this.selectedValue[this.labelName];
  }

  blur(): void {
    this.inputFocussed = false;
    this.onTouched(this.selectedValue);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
