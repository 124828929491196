import { Pipe, PipeTransform } from '@angular/core';
import { NavPlacement } from '../../modules/module.const';
import { IAction } from '../../modules/grid-actions/action.model';

@Pipe({
  name: 'gridActionSecondary',
})
export class GridActionSecondaryPipe implements PipeTransform {
  transform(actions: IAction[]): IAction[] {
    return actions.filter((a) => a.navPlacement === NavPlacement.secondary);
  }
}
