import { Component, ElementRef, OnDestroy } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { EyFormBaseComponent } from '../../../../shared/components/ey-form-base/ey-base-form-control';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tb-form-base',
  template: '',
})
export class TableBuilderFormBaseComponent extends EyFormBaseComponent implements OnDestroy, ControlValueAccessor {
  form: UntypedFormGroup = this.fb.group(
    {
      name: [,],
      description: [, Validators.required],
    },
    { updateOn: 'blur' },
  );

  constructor(
    private fb: UntypedFormBuilder,
    private el: ElementRef,
  ) {
    super();
  }

  writeValue(val: any): void {
    if (val) {
      this.form.patchValue(val, { emitEvent: false });
    }
  }

  public onTouched: () => void = () => {};
  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { subformerror: 'Form Builder Editor Form Error!' };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.form.statusChanges.subscribe(fn);
  }
  markAsTouched(): void {
    this.form.markAllAsTouched();
    this.focusOnError(this.form, this.el);
  }

  ngOnDestroy(): void {}
}
