<div class="d-flex">
  <div hieroglyphicInputSupport class="textinput-group flex-grow-1">
    <input
      [attr.aria-label]="showError ? meta?.errorMsg : meta?.title + (meta?.info ? meta?.info : '')"
      [attr.aria-invalid]="showError"
      [max]="maxChars"
      [placeholder]="placeholder"
      (blur)="onBlur()"
      (input)="onInput()"
      [disabled]="isDisabled"
      [id]="'inp' + ctrl_id"
      class="textinput-group__textinput focusable"
      [ngClass]="{ error: showError, warning: warning, white: isWhite }"
      [type]="type"
      [(ngModel)]="selectedValue"
    />
    <label *ngIf="showError" class="textinput__errmsg"><i class="err_icon icon-ico_error_toast"></i>{{ meta.errorMsg }}</label>
  </div>
  <div class="tooltip--holder" *ngIf="extra !== 'none'">
    <ey-input-tooltip *ngIf="extra === 'tooltip'" [toolTipContent]="meta.info"></ey-input-tooltip>
    <ey-copy-to-clipboard [content]="selectedValue" *ngIf="extra === 'clipboard-copy'"></ey-copy-to-clipboard>
  </div>
</div>
