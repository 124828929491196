<div aria-atomic="true" aria-live="polite" class="toast--container justify-content-center align-items-right">
  <div *ngFor="let t of toasts" class="toast toast--default fade showing mt-0 mb-10" data-autohide="false" data-delay="700">
    <div class="toast__body d-flex align-items-stretch" [class]="t.type">
      <label [ngSwitch]="t.type" class="p-0 icon__holder d-flex" [class]="t.type">
        <i *ngSwitchCase="toastTypes.info" class="material-icons align-self-center me-0">info</i>
        <i *ngSwitchCase="toastTypes.success" class="material-icons align-self-center me-0">check_circle</i>
        <i *ngSwitchCase="toastTypes.warning" class="material-icons align-self-center me-0">warning</i>
        <i *ngSwitchCase="toastTypes.error" class="material-icons align-self-center me-0">error</i>
      </label>
      <label class="flex-grow-1 px-10 m-0 d-flex">
        <label class="align-self-center">{{ t.text }}</label>
      </label>
      <div class="d-flex flex-row">
        <!--button aria-label="Action" class="btn btn--secondary"> Action </button-->
        <button aria-label="Close" class="btn btn--dismiss" (click)="remove(t.id)" data-dismiss="toast">
          <i class="emp-icon icon-ico_close"></i>
        </button>
      </div>
    </div>
  </div>
</div>
