import { CONDITION_OPTIONS, ConditionDropdownItem, EMAIL_LOOKUP, FormToolControlMapper, IS_SELECTED, ToolNames } from './ey-condition.meta';
import { MappingField, MappingFieldType } from '../../../modules/version/version-mapping-data.model';

export function manageConditionFieldsExceptions(conditionItem: MappingField): Array<ConditionDropdownItem> {
  if (conditionItem?.descriptor.includes(IS_SELECTED) && conditionItem?.toolType === ToolNames.Form) {
    return CONDITION_OPTIONS.conditionCheckbox;
  } else if (conditionItem?.toolType === ToolNames.Table) {
    return CONDITION_OPTIONS.conditionGeneric;
  } else {
    return CONDITION_OPTIONS.conditionText;
  }
}

export function removeUploadMailAndIsSelectedFields(currentTargetValueFields: Array<MappingField>): Array<MappingField> {
  return currentTargetValueFields.filter(
    (s) => s.toolType !== ToolNames.Upload && !s.descriptor.includes(IS_SELECTED) && s.toolType !== ToolNames.Mail,
  );
}

export function addTableToolCellFields(currentTargetValueFields: Array<MappingField>, sourceValues: MappingField[]): Array<MappingField> {
  return currentTargetValueFields.concat(sourceValues.filter((s) => s.toolType === ToolNames.Table));
}

export function manageFormToolTextFieldsExceptions(
  currentTargetValueFields: Array<MappingField>,
  sourceValues: MappingField[],
  selectedSource: MappingField,
): Array<MappingField> {
  if (selectedSource.controlType === FormToolControlMapper.Mail) {
    currentTargetValueFields = currentTargetValueFields.filter((s) => s.toolType !== ToolNames.YesNo);
    return currentTargetValueFields;
  } else if (selectedSource.descriptor.includes(IS_SELECTED)) {
    currentTargetValueFields = sourceValues.filter((s) => s.toolType === ToolNames.Form && s.descriptor.includes(IS_SELECTED));
    return currentTargetValueFields;
  } else {
    return currentTargetValueFields;
  }
}

export function manageTargetFieldsExceptions(
  toolType: string,
  toolValueType: string,
  currentTargetValueFields: Array<MappingField>,
  sourceValues: MappingField[],
  selectedSource: MappingField,
): Array<MappingField> | null {
  switch (toolType) {
    case ToolNames.Decision: {
      switch (toolValueType) {
        case MappingFieldType.Text: {
          currentTargetValueFields = removeUploadMailAndIsSelectedFields(currentTargetValueFields);
          return currentTargetValueFields;
        }
        case MappingFieldType.Number: {
          currentTargetValueFields = addTableToolCellFields(currentTargetValueFields, sourceValues);
          return currentTargetValueFields;
        }
      }
      break;
    }
    case ToolNames.Form: {
      switch (toolValueType) {
        case MappingFieldType.Text: {
          currentTargetValueFields = removeUploadMailAndIsSelectedFields(currentTargetValueFields);
          return manageFormToolTextFieldsExceptions(currentTargetValueFields, sourceValues, selectedSource);
        }
        case MappingFieldType.Number: {
          currentTargetValueFields = addTableToolCellFields(currentTargetValueFields, sourceValues);
          return currentTargetValueFields;
        }
        case MappingFieldType.Date: {
          currentTargetValueFields = addTableToolCellFields(currentTargetValueFields, sourceValues);
          return currentTargetValueFields;
        }
      }
      break;
    }
    case ToolNames.YesNo: {
      switch (toolValueType) {
        case MappingFieldType.Text: {
          currentTargetValueFields = removeUploadMailAndIsSelectedFields(currentTargetValueFields);
          currentTargetValueFields = currentTargetValueFields.filter((s) => !s.descriptor.includes(EMAIL_LOOKUP));
          return currentTargetValueFields;
        }
        case MappingFieldType.Number: {
          currentTargetValueFields = addTableToolCellFields(currentTargetValueFields, sourceValues);
          return currentTargetValueFields;
        }
      }
      break;
    }
    case ToolNames.List: {
      switch (toolValueType) {
        case MappingFieldType.Text: {
          currentTargetValueFields = removeUploadMailAndIsSelectedFields(currentTargetValueFields);
          return currentTargetValueFields;
        }
        case MappingFieldType.Number: {
          currentTargetValueFields = addTableToolCellFields(currentTargetValueFields, sourceValues);
          return currentTargetValueFields;
        }
      }
      break;
    }
    case ToolNames.Table: {
      switch (toolValueType) {
        case MappingFieldType.Text: {
          currentTargetValueFields = sourceValues;
          currentTargetValueFields = removeUploadMailAndIsSelectedFields(currentTargetValueFields);
          return currentTargetValueFields;
        }
      }
      break;
    }
    default: {
      break;
    }
  }
}
