import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ey-input-tooltip',
  templateUrl: './ey-input-tooltip.component.html',
  styleUrls: ['./ey-input-tooltip.component.scss'],
})
export class EyInputTooltipComponent implements OnInit {
  @Input() toolTipContent: string = null;
  @Input() toolTipContentBottom = false;

  constructor() {}

  ngOnInit(): void {}
}
