import { Component, Input, OnInit } from '@angular/core';
import { IMailBarSection } from '../../../../designer/builders/letter-builder/mail-bar/mail-bar-section/mail-bar-section.model';
import { MailBarSectionsTypes } from '../../../../designer/builders/letter-builder/mail-bar/mail-bar-section/mail-bar.enums';
import { TagEditorPartType } from 'src/app/shared/components/ey-tag-editor/ey-tag-editor.component';

@Component({
  selector: 'ey-mail-summary',
  templateUrl: './mail-summary.component.html',
  styleUrls: ['./mail-summary.component.scss'],
})
export class EyMailSummaryComponent implements OnInit {
  @Input() mailSections: Array<IMailBarSection> = [];

  constructor() {}

  ngOnInit(): void {}

  get subject(): string {
    return this.mailSections
      .filter((s) => s.type === MailBarSectionsTypes.subject)[0]
      ?.content.map((c) => c.textContent)
      .join('');
  }

  get toSections(): Array<IMailBarSection> {
    return this.mailSections.filter((s) => s.type === MailBarSectionsTypes.to);
  }

  get ccSections(): Array<IMailBarSection> {
    return this.mailSections.filter((s) => s.type === MailBarSectionsTypes.cc);
  }

  get attachments(): Array<string> {
    const attachments = this.mailSections.filter((s) => s.type === MailBarSectionsTypes.attachment)[0];

    if (!attachments) {
      return [];
    }

    const names = attachments.content
      .filter((c) => c.type === TagEditorPartType.tag)
      .map((c) => c.tagContent.name.split(';'))
      .reduce((p, n) => [...p, ...n], [])
      .filter((c) => !!c);

    return names;
  }

  getLabel(to: IMailBarSection, i: number): string {
    return i === 0 ? to.label : `${to.label} (${i + 1})`;
  }
}
