export const COMPLETE_PROFILE_FIELDS_META = {
  name: {
    title: 'Name',
    errorMsg: '',
    info: '',
    required: false,
  },
  email: {
    title: 'Email',
    errorMsg: '',
    info: '',
    required: false,
  },
  areaId: {
    title: 'Area',
    errorMsg: 'Please choose one option',
    required: true,
  },
  countryId: {
    title: 'Country',
    errorMsg: 'Please choose one option',
    required: true,
  },
  serviceLineId: {
    title: 'Service Line',
    errorMsg: 'Please choose one option',
    required: true,
  },
  subServiceLineId: {
    title: 'Sub Service Line',
    errorMsg: 'Please choose one option',
    required: true,
  },
};
