import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ey-copy-to-clipboard',
  templateUrl: './ey-copy-to-clipboard.component.html',
  styleUrls: ['./ey-copy-to-clipboard.component.scss'],
})
export class EyCopyToClipboardComponent implements OnInit {
  @Input() content: string;
  @Input() showIcon = true;
  @Input() showText = false;
  @ViewChild(NgbTooltip) tooltip: NgbTooltip;

  copied: boolean;

  constructor(private eRef: ElementRef) {}

  ngOnInit(): void {}

  onClipboardCopy(): void {
    this.tooltip.close();
    this.tooltip.triggers = 'manual';
    navigator.clipboard.writeText(this.content).then(() => {
      this.copied = true;
      this.tooltip.open(this.eRef);

      setTimeout(() => {
        this.tooltip.close();
        this.copied = false;
      }, 1500);
    });
  }
}
