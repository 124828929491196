import { Component } from '@angular/core';
import { USER_PROFILE_NOT_COMPLETED } from 'src/app/core/constants/constants';

@Component({
  templateUrl: './user-profile-not-completed.component.html',
  styleUrls: ['./user-profile-not-completed.component.scss'],
})
export class UserProfileNotCompletedComponent {
  noticeText = USER_PROFILE_NOT_COMPLETED;
}
