import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
@Component({
  selector: 'ey-text-expander-auto',
  templateUrl: './ey-text-expander-auto.component.html',
  styleUrls: ['./ey-text-expander-auto.component.scss'],
})
export class EyTextExpanderComponentAuto implements OnInit {
  @Input() text: string;
  @Input() lines: string = '1';

  constructor() {}

  ngOnInit(): void {}
}
