import { Injectable } from '@angular/core';
const REFRESH_GRID = 'refreshGrid';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  public get getLocalStorageItemToRefreshGrid(): string {
    return window.localStorage.getItem(REFRESH_GRID);
  }

  public set setLocalStorageItemToRefreshGrid(refreshGridData: string) {
    window.localStorage.setItem(REFRESH_GRID, refreshGridData);
  }
}
