<ey-modal-template [showSubHeader]="false" class="auto-height">
  <div header>
    <h2 class="modal__title"></h2>
  </div>
  <div body class="auto-height">
    <app-table-preview [page]="page"></app-table-preview>
  </div>
  <div footer class="d-flex w-100">
    <button class="btn btn--primary btn-border-color" type="button" (click)="close()">Close</button>
  </div>
</ey-modal-template>
