<app-ey-app-spinner *ngIf="selectedTab === 2"></app-ey-app-spinner>
<ey-modal-template>
  <div header>
    <h2 class="modal__title">Video Manager</h2>
  </div>
  <div body>
    <ul class="tab-navigation">
      <li (click)="selectTab(1)" [class.active]="selectedTab === 1" class="d-flex justify-content-center">
        <span class="emp-icon icon-ico_responses_upload me-2"></span>Upload Video
      </li>
      <li (click)="selectTab(2)" [class.active]="selectedTab === 2" class="d-flex justify-content-center">
        <span class="emp-icon icon-ico_img me-2"></span>Video Gallery
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane" [class.active]="selectedTab === 1">
        <!-- Content for Tab 1 -->
        <h4 class="fonts-col__heading-4 mb-10 pb-0 mt-0">Add Video</h4>
        <ey-file-upload
          dragAndDropTitle="Drag and drop video or"
          filesSubTitle="Video"
          [warningOnVideoUpload]="warningText"
          [acceptedFormats]="acceptedFormats"
          [increaseFixedWidth]="true"
          [fileExt]="fileExtension"
          [maxFiles]="1"
          [maxSize]="500"
          [files]="files"
          [docType]="docType"
          (initFileUpload)="upload($event)"
          (deleteFile)="deleteFile($event)"
        >
          <p class="doc-type-des m-0 p-0">Allowed Video formats (mp4, webm).</p>
        </ey-file-upload>
      </div>
      <div class="tab-pane" [class.active]="selectedTab === 2">
        <!-- Content for Tab 2-->
        <div class="d-flex flex-column video-gallery" *ngIf="versionVideos.length > 0; else noVideos">
          <div class="video-card-container">
            <div class="container video-card" *ngFor="let video of versionVideos; index as i">
              <div class="overlay"></div>
              <div class="buttons">
                <button (click)="onInsertVideoFromGallery(video)" title="Insert Video" class="add-icon-container">
                  <i role="button" title="Insert Video" class="custom-add-icon emp-icon icon-ico_plus btn-icon m-0"></i>
                </button>
                <button (click)="onDeleteVideoFromGallery(video)" title="Delete Video" class="delete-icon-container">
                  <i class="custom-delete-icon emp-icon icon-ico_delete btn-icon m-0"></i>
                </button>
              </div>
              <video
                [title]="video.name"
                [style.border-style]="video.id === selectedVideoId ? 'dotted' : 'none'"
                src="{{ video.url }}"
                data-id="{{ video.id }}"
              ></video>
              <p class="margin-left">{{ video.name.length > 40 ? video.name.slice(0, 40) + '...' : video.name }}</p>
            </div>
          </div>
        </div>
        <ng-template #noVideos>
          <div class="mt-20">
            <p>No videos yet...</p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div footer class="d-flex w-100">
    <button
      [disabled]="!dataLoaded"
      class="btn me-20 btn--primary btn-border-color d-flex justify-content-center"
      type="button"
      (click)="insertVideo()"
    >
      Insert
    </button>
    <button class="btn btn--secondary ms-0" type="button" (click)="cancel()">Cancel</button>
  </div>
</ey-modal-template>
