import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Tool } from '../tools/tool';

@Injectable()
export class PropertiesEditorService {
  tool$ = new BehaviorSubject<Tool>(null);
  clear$ = new Subject<boolean>();
  clearToolSelection$ = new Subject<boolean>();

  openPropertiesFor(tool: Tool): void {
    this.tool$.next(tool);
  }

  clear(): void {
    this.clear$.next(true);
  }

  clearToolSelection(): void {
    this.clearToolSelection$.next(true);
  }
}
