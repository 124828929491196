import { Component, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { EyBaseFormControlComponent } from '../ey-base-form-control/ey-base-form-control';

@Component({
  selector: 'ey-multi-select',
  templateUrl: './ey-multi-select.component.html',
  styleUrls: ['./ey-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyMultiSelectComponent),
      multi: true,
    },
  ],
})
export class EyMultiSelectComponent extends EyBaseFormControlComponent implements ControlValueAccessor {
  @Input() set selectedFieldIds(val) {
    if (val && val.length > 0) {
      this.mapFieldIds(val);
    }
  }
  @Input() showDarkPlaceHolder = false;
  @Input() showFilterPills = true;
  @Input() placeHolderValue = null;
  @Input() floatingLabels = false;
  @Input() showDarkScroll = false;
  @Input() showClearAllOption = false;
  @Input() labelName = 'name';
  @Input() searchable: boolean = false;
  @Input() dropUp = false;
  @Input() toolTipVisible = true;
  @Input() darkMode = false;
  @Input() set values(val) {
    this.incomingValues = val;
    this.filteredValues = val;
  }
  @Input() showLargeBadges = false;

  filteredValues: any[] = [];
  isExpanded = false;
  incomingValues: any[] = [];
  _selectedValues = [];

  constructor(
    private eRef: ElementRef,
    private controlContainer: ControlContainer,
  ) {
    super(controlContainer);
  }

  toggleExpand(): void {
    if (this.isDisabled) {
      return;
    }
    this.isExpanded = !this.isExpanded;
    /* info(MK | 02-11-2021): this has been commented out to address refresh with no changes to params (admin - benefit tracking)
    if for some reason this becomes an issue pls get in touch
    this.onChange(this.selectedValues);
    this.onTouched(this.selectedValues);
    */
  }
  @HostListener('document:click', ['$event'])
  clickOut(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isExpanded = false;
    }
  }

  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  set selectedValues(val) {
    this._selectedValues = val;
    this.onChange(val);
    this.onTouched(val);
  }

  get selectedValues(): any {
    return this._selectedValues;
  }

  writeValue(initValue: any): void {
    this._selectedValues = initValue || [];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  isChecked(v): boolean {
    return _.isEmpty(this.selectedValues) ? false : !(this.selectedValues.find((t) => JSON.stringify(t) === JSON.stringify(v)) === undefined);
  }

  changed(v): any {
    if (_.isEmpty(this.selectedValues)) {
      this.selectedValues = [{ ...v }];
    } else {
      const item = this.selectedValues.find((t) => JSON.stringify(t) === JSON.stringify(v));
      if (item === undefined) {
        this.selectedValues = [...this.selectedValues, { ...v }];
      } else {
        this.removeItem(item);
      }
    }
  }

  hidePlaceholder(): boolean {
    if (this.floatingLabels) {
      return this.isExpanded || this.selectedValues.length > 0 ? true : false;
    } else {
      return true;
    }
  }

  onClearAll(): void {
    this.selectedValues = [];
  }

  filter(val): void {
    if (val) {
      const startsWith = this.incomingValues.filter((v) => v[this.labelName].search(new RegExp('^' + val, 'i')) !== -1);
      let containsList = this.incomingValues.filter((v) => v[this.labelName].search(new RegExp(val, 'i')) !== -1);
      containsList = containsList.filter((e) => !startsWith.includes(e));
      this.filteredValues = [...startsWith, ...containsList];
      this.isExpanded = true;
    } else {
      this.filteredValues = this.incomingValues;
    }
  }

  removeItem(item: any): void {
    this.selectedValues = this.selectedValues.filter((t) => JSON.stringify(t) !== JSON.stringify(item));
  }

  setInputValue(): any {
    if (this.placeHolderValue) {
      return null;
    }
    return 0;
  }

  getPlaceHolderValue(): string {
    return !this.isExpanded ? this.placeHolderValue : '';
  }

  mapFieldIds(val): void {
    const selectedFields = [];
    val.forEach((el) => {
      selectedFields.push(this.filteredValues?.find((x) => x.id === el));
    });
    this.selectedValues = selectedFields;
  }
}
