import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { TableBuilderFormBaseComponent } from '../table-builder-form-base.component';
import { CATEGORIES, CATEGORY_PERCENT, DECIMAL_SEPARATORS, DropdownItem, THOUSANDS_SEPARATORS } from '../controls.const';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';

const META = {
  name: {
    title: 'Name',
  },
  question: {
    title: 'Question',
    required: true,
    errorMsg: 'Question is required. Max length 2000 characters',
  },
  hint: {
    title: 'Hint',
    errorMsg: 'Max length 1000 characters',
  },
  category: {
    title: 'Category',
  },
  decimalPlaces: {
    title: 'Decimal places',
  },
  decimalSeparator: {
    title: 'Decimal Separator',
  },
  thousandSeparator: {
    title: '1000 Separator',
  },
};

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumberComponent),
      multi: true,
    },
  ],
})
export class NumberComponent extends TableBuilderFormBaseComponent implements OnInit, OnDestroy {
  @Input() showFormulaSection = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  meta = META;
  defCategoryNumber = CATEGORIES[0];
  defDecSeparator = DECIMAL_SEPARATORS[0];
  defThoSeparator = THOUSANDS_SEPARATORS.filter((ts) => ts.value !== this.defDecSeparator.value)[0];
  categories: DropdownItem[] = CATEGORIES;
  decimalSeparators: DropdownItem[] = DECIMAL_SEPARATORS;
  thousandsSeparators: DropdownItem[] = THOUSANDS_SEPARATORS.filter((ts) => ts.value !== this.defDecSeparator.value);

  form = this.fbs.group(
    {
      category: [this.defCategoryNumber, Validators.required],
      decimalPlaces: [0, [Validators.required, Validators.min(0), Validators.max(10)]],
      decimalSeparator: [this.defDecSeparator, Validators.required],
      useThousandsSeparator: [false, { updateOn: 'change' }],
      thousandsSeparator: [this.defThoSeparator, Validators.required],
    },
    { updateOn: 'blur' },
  );

  get thousandsSeparatorControl(): UntypedFormControl {
    return this.form.get('useThousandsSeparator') as UntypedFormControl;
  }

  get categoryCtrl(): UntypedFormControl {
    return this.form.get('category') as UntypedFormControl;
  }

  get thousandsSeparatorChecked(): boolean {
    return this.thousandsSeparatorControl.value;
  }

  get decimalPlacesControl(): UntypedFormControl {
    return this.form.controls.decimalPlaces as UntypedFormControl;
  }
  get decimalSeparatorCtrl(): UntypedFormControl {
    return this.form.controls.decimalSeparator as UntypedFormControl;
  }

  constructor(
    private fbs: UntypedFormBuilder,
    private els: ElementRef,
  ) {
    super(fbs, els);
  }

  ngOnInit(): void {
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges(): void {
    this.thousandsSeparatorControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((v) => {
      const controlName = 'thousandsSeparator';

      if (v) {
        let defThSep = this.defThoSeparator;
        if (this.thousandsSeparators.find((s) => s.value === this.defThoSeparator.value) === undefined) {
          defThSep = this.thousandsSeparators[0];
        }
        this.form.patchValue({ thousandsSeparator: defThSep }, { emitEvent: false });
      } else {
        // this.form.removeControl(controlName);
      }
    });

    this.decimalPlacesControl.valueChanges.subscribe((v) => {
      const decimalPlaces = +v;

      if (decimalPlaces > 10) {
        this.decimalPlacesControl.setValue('10', { emitEvent: false });
      }

      if (decimalPlaces < 0) {
        this.decimalPlacesControl.setValue('0', { emitEvent: false });
      }
    });

    this.categoryCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val?.name === CATEGORY_PERCENT) {
        this.form.patchValue({ decimalPlaces: 2 }, { emitEvent: false });
      } else {
        this.form.patchValue({ decimalPlaces: 0 }, { emitEvent: false });
      }
    });

    this.decimalSeparatorCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((v) => {
      this.thousandsSeparators = THOUSANDS_SEPARATORS.filter((ts) => ts.value !== v.value);
      this.defThoSeparator = this.thousandsSeparators[0];
      this.form.patchValue({ thousandsSeparator: [this.thousandsSeparators[0]] }, { emitEvent: false });
    });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((v) => {
      if (v.category !== null) {
        this.onChange(this.convertToReturnValue(v));
      }
    });

    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(this.onValidatorChange);
    if (this.form.value.category !== null) {
      this.onChange(this.convertToReturnValue(this.form.value));
    }
  }

  convertToReturnValue(value: any): any {
    return {
      ...value,
      category: value.category.value,
      decimalSeparator: value.decimalSeparator?.value,
      thousandsSeparator: value.thousandsSeparator?.value,
    };
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { subformerror: 'Number Input Editor Form Error!' };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  onChange: (val: any) => void = () => {};
  onValidatorChange: () => void = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(val: any): void {
    if (val && val.category !== null) {
      const patchValue = val.category
        ? {
            ...val,
            category: this.categories.find((c) => c.value === val.category),
            decimalSeparator: this.decimalSeparators.find((c) => c.value === val.decimalSeparator),
            thousandsSeparator: THOUSANDS_SEPARATORS.find((c) => c.value === val.thousandsSeparator),
          }
        : val;
      this.form.patchValue(patchValue);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    super.ngOnDestroy();
  }
}
