import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { IEyRadioGroupModel } from '../../../shared/components/ey-radio-group/ey-radio-group.model';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { EyFormBaseComponent } from '../../../shared/components/ey-form-base/ey-base-form-control';

export const DATA_PURPOSES_FIELDS_META = {
  uploadMethod: {
    title: 'Upload Method',
    errorMsg: 'Select upload method to proceed.',
    required: true,
  },
};

export enum UploadMethods {
  UseOurTemplate = 1,
  UseMapping,
}

export const UploadMethodRGM: IEyRadioGroupModel[] = [
  {
    label: 'Use Our Template',
    value: UploadMethods.UseOurTemplate,

    description: ['Download our template and use it to prepare and upload data into Empowerment'],
  },
  {
    label: 'Use Mapping',
    value: UploadMethods.UseMapping,
    description: ['Map your existing Excel data with Project data'],
  },
];
@Component({
  selector: 'app-upload-method',
  templateUrl: './upload-method.component.html',
  styleUrls: ['./upload-method.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadMethodComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UploadMethodComponent),
      multi: true,
    },
  ],
})
export class UploadMethodComponent extends EyFormBaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
  values = UploadMethodRGM;
  form: UntypedFormGroup;
  meta = DATA_PURPOSES_FIELDS_META;
  @Input() selectedUploadMethod: UploadMethods;
  constructor(
    private fb: UntypedFormBuilder,
    private el: ElementRef,
  ) {
    super();
  }

  getNumberOfSteps(): number {
    return this.selectedUploadMethod === UploadMethods.UseMapping ? 4 : 3;
  }
  ngOnInit(): void {
    this.form = this.fb.group(
      {
        uploadMethod: [, Validators.required],
      },
      { updateOn: 'blur' },
    );
  }
  writeValue(val: any): void {
    if (val) {
      this.form.patchValue(val, { emitEvent: false });
    }
  }
  public onTouched: () => void = () => {};
  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { subformerror: 'Upload Method Form Error!' };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.form.statusChanges.subscribe(fn);
  }
  markAsTouched(): void {
    this.form.markAllAsTouched();
    this.focusOnError(this.form, this.el);
  }

  ngOnDestroy(): void {}
  setFocusOnTheFirstFormElement(): void {
    this.setFocusFirstFormElement(this.form, this.el);
  }
}
