import { Component, Input, OnInit } from '@angular/core';

export const ButtonClass = {
  primary: 'btn--primary',
  secondary: 'btn--secondary',
  btnNext: 'btn--primary btn--next',
  btnPrev: 'btn--secondary btn--back',
  btnDelete: 'btn--delete',
  btnLinkWhite: 'btn--link btn--link__white',
};

@Component({
  selector: 'ey-button',
  templateUrl: './ey-button.component.html',
  styleUrls: ['./ey-button.component.scss'],
})
export class EyButtonComponent implements OnInit {
  @Input() type = 'button';
  @Input() icon = null;
  @Input() btnClass: string = ButtonClass.primary;
  @Input() text = 'Please provide text';
  @Input() disabled = false;
  constructor() {}

  ngOnInit(): void {}
}
