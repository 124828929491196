import { ProgressBarStep, ProgressStates } from '../../shared/components/ey-form-progress-bar/progress-bar-step.model';

export enum progressBarStepIds {
  dataPurpose,
  uploadMethod,
  uploadYourData,
  dataMapping,
}

export const PROGRESS_BAR_STEPS: ProgressBarStep[] = [
  {
    id: progressBarStepIds.dataPurpose,
    active: true,
    state: ProgressStates.inProgress,
    title: 'Data Purpose',
    formName: 'dataPurpose',
    componentName: 'dataPurposeComponent',
  },
  {
    id: progressBarStepIds.uploadMethod,
    active: false,
    state: ProgressStates.new,
    title: 'Upload Method',
    formName: 'uploadMethod',
    componentName: 'uploadMethodComponent',
  },
  {
    id: progressBarStepIds.uploadYourData,
    active: false,
    state: ProgressStates.new,
    title: 'Upload Your Data',
    formName: 'uploadYourData',
    componentName: 'uploadYourDataComponent',
  },
];

export const DATA_MAPPING_STEP = {
  id: progressBarStepIds.dataMapping,
  active: false,
  state: ProgressStates.new,
  title: 'Data Mapping',
  formName: 'dataMapping',
  componentName: 'dataMappingComponent',
};
