<div class="d-flex fix-height" [class.mt-40]="cardView">
  <div class="d-flex fix-height" [ngClass]="{ 'text-grey': cardView, 'flex-grow-1': cardView }">
    <div *ngFor="let a of primaryActions" (click)="onActionClick(a.actionType)">
      <span [title]="a.toolTip ? a.toolTip : a.actionType.toString()">
        <i [ngClass]="a.icon" class="me-10 emp-icon"></i>
        <label class="ico_label ps-0 pe-20 m-0" *ngIf="showLabelText">{{ getLabelText(a.actionType) }}</label>
      </span>
    </div>
  </div>
  <div>
    <ey-grid-menu
      *ngIf="secondaryActions?.length > 0"
      [parentViewFlag]="parentViewFlag"
      [light]="lightTable"
      [relativePositioning]="false"
      [class]="{ 'flex-grow-1': true }"
    >
      <div dropDownItems>
        <button [title]="a.toolTip ? a.toolTip : ''" *ngFor="let a of secondaryActions" class="dropdown-item" (click)="onActionClick(a.actionType)">
          <i [ngClass]="a.icon" class="emp-icon emp-icon-confident-grey"></i> {{ a.toolTip ? a.toolTip : a.actionType.toString() }}
        </button>
      </div>
    </ey-grid-menu>
  </div>
</div>
