import { createReducer, on } from '@ngrx/store';
import { DRAFT_STATUS_ID } from 'src/app/modules/version/version.const';
import { ModuleInfo } from '../../modules/manage-module/module-info.model';
import { VersionStructure } from '../../modules/version/version-structure.model';
import { Version } from '../../modules/version/version.model';
import {
  designerCanvasStateLoaded,
  designerResetDataLoaded,
  designerSetDataReady,
  designerSetDefaultShapesAdded,
  designerUpdateVersionStatus,
} from './designer.actions';
import { CanvasDesignerPersistentState } from './domain/canvas-state';
import { clearDesignerPosition, persistDesignerPosition } from './shape/actions';

export interface DesignerState {
  module: ModuleInfo;
  version: Version;
  versionStructure: VersionStructure;
  dataLoaded: boolean;
  dataReady: boolean; // after data loaded shapes and specific parts in store should be updated before enabling autosave
  defaultShapesAdded: boolean; // in case of creating new flow, designer component creates Start and End shapes. This flag indicates that this was done
}

export const designerReducer = createReducer(
  { dataLoaded: false, dataReady: false } as DesignerState,
  on(designerCanvasStateLoaded, (_, action) => {
    return {
      module: action.module,
      version: action.version,
      versionStructure: action.versionStructure,
      dataLoaded: true,
      dataReady: false,
      defaultShapesAdded: false,
    };
  }),
  on(designerResetDataLoaded, (state, _) => ({ ...state, dataLoaded: false, dataReady: false, defaultShapesAdded: false })),
  on(designerSetDataReady, (state, _) => ({ ...state, dataReady: true })),
  on(designerSetDefaultShapesAdded, (state, _) => ({ ...state, defaultShapesAdded: true })),
  on(designerUpdateVersionStatus, (state, action) => {
    return { ...state, version: { ...action.version, status: action.status } };
  }),
);

export const designerPositionReducer = createReducer(
  {} as CanvasDesignerPersistentState,
  on(persistDesignerPosition, (_, action) => {
    return {
      scale: action.position.scale,
      offsetX: action.position.offsetX,
      offsetY: action.position.offsetY,
    };
  }),
  on(clearDesignerPosition, (_, action) => {
    return {
      scale: 1,
      offsetX: 0,
      offsetY: 0,
    };
  }),
);
