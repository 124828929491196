import { NavPlacement } from './module.const';
import { IAction } from './grid-actions/action.model';
import { ModuleActionsPermissions } from './module-permissions.const';
import { UserModulePermissions } from '../core/user-permissions.model';

export enum ActionTypes {
  addFavorite = 'Add Favourite',
  removeFavorite = 'Remove Favourite',
  addTeamMember = 'Add Team Member',
  infoModule = 'Module information',
  trackBenefits = 'Track Benefits',
  editModule = 'Edit module',
  addVersion = 'Add version',
  delete = 'Delete module',
  view = 'View',
  review = 'Review',
}

export const ACTIONS: IAction[] = [
  {
    actionType: ActionTypes.addFavorite,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_favorites',
    requiredPermissions: [ModuleActionsPermissions.read],
  },
  {
    actionType: ActionTypes.removeFavorite,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_favorites-Yes',
    requiredPermissions: [ModuleActionsPermissions.read],
  },
  {
    actionType: ActionTypes.addTeamMember,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_grant_access',
    requiredPermissions: [ModuleActionsPermissions.updateModuleMembers, new UserModulePermissions().UpdateAllModulesMembers],
  },
  {
    actionType: ActionTypes.infoModule,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_tooltip',
    requiredPermissions: [
      ModuleActionsPermissions.read,
      new UserModulePermissions().ReadAllGoldModules,
      new UserModulePermissions().ReadAllStdModules,
    ],
  },
  {
    actionType: ActionTypes.trackBenefits,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_benefits',
    requiredPermissions: [ModuleActionsPermissions.readModuleBenefits, new UserModulePermissions().ReadAllModulesBenefit],
  },
  {
    actionType: ActionTypes.editModule,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_edit',
    requiredPermissions: [ModuleActionsPermissions.update],
  },
  {
    actionType: ActionTypes.addVersion,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_plus',
    requiredPermissions: [ModuleActionsPermissions.createModuleFlow, new UserModulePermissions().CreateAllModulesFlow],
  },
  {
    actionType: ActionTypes.delete,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_delete',
    requiredPermissions: [ModuleActionsPermissions.delete],
  },
];

export const REVIEW_ACTIONS: IAction[] = [
  {
    actionType: ActionTypes.view,
    navPlacement: NavPlacement.primary,
    icon: 'icon-ico_eye',
    requiredPermissions: [ModuleActionsPermissions.createModuleReview],
  },
  {
    actionType: ActionTypes.review,
    navPlacement: NavPlacement.primary,
    icon: 'icon-ico_review',
    requiredPermissions: [ModuleActionsPermissions.createModuleReview],
  },
];
