import { Component, OnInit } from '@angular/core';
import { SummarySectionBaseComponent } from '../summary-section-base.component';
import { ERROR_DATE, ERROR_TEXT } from '../../../../../builders/table-builder/controls/controls.const';
import { ModuleFlowService } from '../../../../module-flow.service';
import { TableSummaryService } from './table-summary.service';

@Component({
  selector: 'app-table-summary',
  templateUrl: './table-summary.component.html',
  styleUrls: ['./table-summary.component.scss'],
})
export class TableSummaryComponent extends SummarySectionBaseComponent implements OnInit {
  children: any[][];
  constructor(
    private moduleFlowService: ModuleFlowService,
    private tableSummaryService: TableSummaryService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.children = this.tableSummaryService.emptyStateHandler(this.moduleFlowService.mapTableChildren(this.page.moduleFlowPage?.children));
  }

  isError(val: string): boolean {
    return val === ERROR_TEXT || val === ERROR_DATE;
  }

  get isHidden(): boolean {
    return this.page.moduleFlowPage?.isHidden || false;
  }
}
