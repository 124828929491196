import { Injectable } from '@angular/core';
import { IAction } from './grid-actions/action.model';
import { ModuleActionsPermissions } from './module-permissions.const';

@Injectable({
  providedIn: 'root',
})
export class BasePermissionsService {
  public removeActionsBasedOnPermissions(actions: IAction[], permissionsList: string[]): IAction[] {
    return actions.filter((a) => this.hasPermission(a.requiredPermissions, permissionsList));
  }

  public hasPermission(requiredPermission: string[], permissionList: string[]): boolean {
    return permissionList == null ? false : permissionList.find((p) => requiredPermission.some((permission) => permission === p)) !== undefined;
  }
}
