import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { BasePageContent } from '../base-page-content';
import { PropertyInput } from '../../property-input.model';
import { ButtonClass } from 'src/app/shared/components/ey-button/ey-button.component';
import { FlowType, ModuleFlowService } from '../../module-flow.service';
import { SaveNotificationServiceEndUserForm } from '../../save-notification-service-end-user-form.service';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';
import { ScrollService } from 'src/app/designer/designer-services/scroll.service';

export const LIST_PAGE_META = {
  item: {
    title: 'Enter your text',
    errorMsg: 'Text should not be empty',
    required: true,
  },
};
export const ITEM_PROP_NAME = 'Items';
@Component({
  selector: 'app-list-page.d-flex.flex-fill',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss'],
})
export class ListPageComponent extends BasePageContent implements OnInit, AfterViewInit {
  btnClass = ButtonClass;
  listForm: UntypedFormGroup;
  fieldMeta = LIST_PAGE_META;
  itemsPropertyName = ITEM_PROP_NAME;
  constructor(
    flowService: ModuleFlowService,
    protected spinnerService: EyAppSpinnerService,
    private saveNotification: SaveNotificationServiceEndUserForm,
    private formBuilder: UntypedFormBuilder,
    private scrollService: ScrollService,
  ) {
    super(flowService, spinnerService);
  }

  get items(): UntypedFormArray {
    return this.listForm.get('items') as UntypedFormArray;
  }

  get itemsProperty(): any {
    const pageProps = this.page.moduleFlowPage?.properties as Array<any>;
    const itemsProp = pageProps.find((p) => p.name === this.itemsPropertyName);

    return itemsProp;
  }

  get pageItems(): Array<string> {
    const itemsProp = this.itemsProperty;

    return itemsProp ? itemsProp?.items : null;
  }

  createItem(item: string): UntypedFormControl {
    return new UntypedFormControl(item, [Validators.required]);
  }

  addItem(): void {
    this.items.push(this.createItem(''));
  }

  removeItem(index: number): void {
    this.items.removeAt(index);
  }

  ngOnInit(): void {
    this.flowService.responseHeaderMobileViewChange.pipe(takeUntil(this.destroy$)).subscribe((mobileViewChange) => {
      this.isMobileBreakpoint = mobileViewChange;
    });

    // TODO SO: refactor this quick fix to a normal operation
    if (this.flowType === FlowType.response && this.saveNotification.saveCurrentProperties$.observers.length === 0) {
      this.saveNotification.saveCurrentProperties$
        .pipe(
          switchMap(() => this.flowService.saveCurrent(this.page.moduleFlowPage.id, this.getProperties(true))),
          takeUntil(this.destroy$),
        )
        .subscribe((response) => {
          this.flowService.transformCurrentPageData(response);
          this.page = response;
        });
    }

    const listItems = this.pageItems;
    this.listForm = this.formBuilder.group({
      items: this.formBuilder.array(listItems?.map((item) => this.createItem(item)) ?? [this.createItem('')]),
    });

    this.onValueChange();
  }

  ngAfterViewInit(): void {
    if (this.scrollService.shouldScroll()) {
      window.scroll(0, 0);
      this.scrollService.setHasScrolled(true);
    }
  }

  onValueChange(): void {
    this.listForm.valueChanges.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe(() => {
      this.saveNotification.dispatchNotSaved();
      if (this.flowType === FlowType.response && this.isAutoSave) {
        this.saveNotification.saveCurrentProperties$.next();
      }
    });
  }

  getProperties(isSaveDraft = false): Array<PropertyInput> {
    let props: Array<PropertyInput> = null;
    if (this.listForm.valid || isSaveDraft) {
      const list: any = this.listForm.getRawValue();
      props = list && list.items ? [{ name: this.itemsPropertyName, value: JSON.stringify(list.items) }] : null;
    }

    return props;
  }

  onPrev(): void {
    if (!this.listForm.valid) {
      this.listForm.markAllAsTouched();
      return;
    }

    const loadingResult$ = this.spinnerService.withLoadingIndicator(this.flowService.doPrev(this.getProperties(), this.flowType), this.destroy$);
    loadingResult$.subscribe();
  }

  onNext(): void {
    if (!this.listForm.valid) {
      this.listForm.markAllAsTouched();
      return;
    }
    const loadingResult$ = this.spinnerService.withLoadingIndicator(this.flowService.doNext(this.getProperties(), this.flowType), this.destroy$);
    loadingResult$.subscribe();
  }
}
