import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EyBaseFormControlComponent } from '../ey-base-form-control/ey-base-form-control';
import { BulkUploadModalService } from '../../../projects/bulk-upload-modal/bulk-upload-modal.service';

@Component({
  selector: 'app-ey-dropdown-light-weight',
  templateUrl: './ey-dropdown-light-weight.component.html',
  styleUrls: ['./ey-dropdown-light-weight.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyDropdownLightWeightComponent),
      multi: true,
    },
  ],
})
export class EyDropdownLightWeightComponent extends EyBaseFormControlComponent implements OnInit, ControlValueAccessor {
  selectedOption: any;
  showValidationError = false;
  @Input() options: any[];

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private controlContainer: ControlContainer,
    private bulkUploadModalService: BulkUploadModalService,
  ) {
    super(controlContainer);
  }
  writeValue(obj: any): void {
    if (obj?.id) {
      this.selectedOption = this.options.find((el) => el.id === obj.id).id;
    } else {
      this.selectedOption = null;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  ngOnInit(): void {
    this.bulkUploadModalService.checkForDataMappingErrors.subscribe((checkForErrors) => {
      if (checkForErrors) {
        this.showValidationError = this.showDataMappingError();
        this.bulkUploadModalService.checkForDataMappingErrors.next(false);
      }
    });
  }

  showDataMappingError(): boolean {
    if (!this.control) {
      return this.standaloneHasError;
    }
    const { disabled } = this.control;
    return !this.control.valid && this.control.status !== 'PENDING' && !disabled ? true : false;
  }

  setSelectedValue(event: any): void {
    if (event.target.value === '') {
      this.selectedOption = null;
      this.onChange(null);
      this.onTouched(null);
      this.showValidationError = this.showError;
    } else {
      this.onChange({ name: this.selectedOption, id: this.selectedOption });
      this.onTouched({ name: this.selectedOption, id: this.selectedOption });
      this.showValidationError = this.showError;
    }
  }
}
