import { UserModulePermissions } from '../../../core/user-permissions.model';
import { ModuleActionsPermissions } from '../../../modules/module-permissions.const';
import { Module } from '../../../modules/module.model';

export enum Placement {
  Primary,
  Secondary,
  Save,
}

export enum VersionStatus {
  Draft = 1,
  DraftInReview,
  DraftReviewed,
  DraftInPublishing,
  Test,
  TestInReview,
  TestReviewed,
  TestInPublishing,
  Expired,
  ExpiredInRenewal,
  LiveInRenewal,
  Live,
}

export interface DesignerAction {
  name: string;
  icon?: string;
  placement: Placement;
  availableForStatus: VersionStatus[];
  requiredPermission?: string[];
  requiredModulePermission?: string[];
  specialConditionFn?: (module: Module, userId) => boolean;
}

export const Preview = 'Preview';
export const DownloadAsEmpowermentFile = 'Download flow to .empx file';
export const DownloadAsPdf = 'Download flow as pdf';
export const PublishToTest = 'Publish';
export const PublishToLive = 'Publish to Live';
export const ResumePublishToLive = 'Resume Publish to Live';
export const CancelLivePublishingApproval = 'Cancel Live Publishing Approval';
export const CancelRenewalAndClose = 'Cancel Renewal and Close';
export const Save = 'Save';
export const Autosave = 'Autosave';
export const ResumeRenewal = 'Resume Renewal';
export const SendToReview = 'Send to Review';
export const CancelReview = 'Cancel Review';
export const RejectReview = 'Reject Review';
export const BreakReview = 'Break Review';
export const SendBackToRevision = 'Send back for revision';
export const ConfirmReview = 'Mark as Reviewed';
export const CloneToCreateNewVersion = 'Clone to Create New Version';
export const ModuleInformation = 'Module Information';

const USER_MODULE_PERMISSION = new UserModulePermissions();

export const ACTIONS_META: DesignerAction[] = [
  {
    name: Preview,
    icon: 'icon-ico_eye',
    placement: Placement.Primary,
    availableForStatus: [
      VersionStatus.Draft,
      VersionStatus.DraftInPublishing,
      VersionStatus.DraftInReview,
      VersionStatus.DraftReviewed,
      VersionStatus.Expired,
      VersionStatus.ExpiredInRenewal,
      VersionStatus.Live,
      VersionStatus.LiveInRenewal,
      VersionStatus.Test,
      VersionStatus.TestInPublishing,
      VersionStatus.TestInReview,
      VersionStatus.TestReviewed,
    ],
    requiredPermission: [
      USER_MODULE_PERMISSION.ReadAllGoldModules,
      USER_MODULE_PERMISSION.ReadAllStdModules,
      USER_MODULE_PERMISSION.ReadAllHiddenModules,
    ],
    requiredModulePermission: [ModuleActionsPermissions.readModuleFlow],
  },
  {
    name: DownloadAsEmpowermentFile,
    icon: 'icon-ico_download',
    placement: Placement.Secondary,
    availableForStatus: [
      VersionStatus.Draft,
      VersionStatus.DraftInPublishing,
      VersionStatus.DraftInReview,
      VersionStatus.DraftReviewed,
      VersionStatus.Expired,
      VersionStatus.ExpiredInRenewal,
      VersionStatus.Live,
      VersionStatus.LiveInRenewal,
      VersionStatus.Test,
      VersionStatus.TestInPublishing,
      VersionStatus.TestInReview,
      VersionStatus.TestReviewed,
    ],
    requiredPermission: [USER_MODULE_PERMISSION.ReadAllModulesFlow],
    requiredModulePermission: [ModuleActionsPermissions.readModuleFlow],
  },
  {
    name: DownloadAsPdf,
    icon: 'icon-ico_download',
    placement: Placement.Secondary,
    availableForStatus: [
      VersionStatus.Draft,
      VersionStatus.DraftInPublishing,
      VersionStatus.DraftInReview,
      VersionStatus.DraftReviewed,
      VersionStatus.Expired,
      VersionStatus.ExpiredInRenewal,
      VersionStatus.Live,
      VersionStatus.LiveInRenewal,
      VersionStatus.Test,
      VersionStatus.TestInPublishing,
      VersionStatus.TestInReview,
      VersionStatus.TestReviewed,
    ],
    requiredPermission: [USER_MODULE_PERMISSION.ReadAllModulesFlow],
    requiredModulePermission: [ModuleActionsPermissions.readModuleFlow],
  },
  {
    name: PublishToTest,
    icon: 'icon-ico_publish',
    placement: Placement.Primary,
    availableForStatus: [VersionStatus.Draft, VersionStatus.DraftReviewed],
    requiredPermission: [USER_MODULE_PERMISSION.UpdateAllModulesFlow],
    requiredModulePermission: [ModuleActionsPermissions.updateModuleFlow],
  },
  {
    name: PublishToLive,
    icon: 'icon-ico_publish',
    placement: Placement.Primary,
    availableForStatus: [VersionStatus.Test, VersionStatus.TestReviewed],
    requiredPermission: [USER_MODULE_PERMISSION.UpdateAllModulesFlow],
    requiredModulePermission: [ModuleActionsPermissions.updateModuleFlow],
  },
  {
    name: ResumeRenewal,
    icon: 'icon-ico_RenewVersion',
    placement: Placement.Primary,
    availableForStatus: [VersionStatus.ExpiredInRenewal, VersionStatus.LiveInRenewal],
    requiredPermission: [USER_MODULE_PERMISSION.UpdateAllModulesFlow],
    requiredModulePermission: [ModuleActionsPermissions.updateModuleFlow],
  },
  {
    name: ResumePublishToLive,
    icon: 'icon-ico_publish',
    placement: Placement.Primary,
    availableForStatus: [VersionStatus.DraftInPublishing, VersionStatus.TestInPublishing],
    requiredPermission: [USER_MODULE_PERMISSION.UpdateAllModulesFlow],
    requiredModulePermission: [ModuleActionsPermissions.updateModuleFlow],
  },
  {
    name: CancelLivePublishingApproval,
    icon: 'icon-ico_publish',
    placement: Placement.Primary,
    availableForStatus: [VersionStatus.DraftInPublishing, VersionStatus.TestInPublishing],
    requiredPermission: [USER_MODULE_PERMISSION.UpdateAllModulesFlow],
    requiredModulePermission: [ModuleActionsPermissions.updateModuleFlow],
  },
  {
    name: CancelRenewalAndClose,
    icon: 'icon-ico_RenewVersion',
    placement: Placement.Primary,
    availableForStatus: [VersionStatus.LiveInRenewal, VersionStatus.ExpiredInRenewal],
    requiredPermission: [USER_MODULE_PERMISSION.UpdateAllModulesFlow],
    requiredModulePermission: [ModuleActionsPermissions.updateModuleFlow],
  },
  {
    name: Save,
    icon: 'icon-ico_save',
    placement: Placement.Save,
    availableForStatus: [VersionStatus.Draft, VersionStatus.DraftInReview, VersionStatus.DraftReviewed],
    requiredPermission: [USER_MODULE_PERMISSION.UpdateAllModulesFlow],
    requiredModulePermission: [ModuleActionsPermissions.updateModuleFlow],
  },
  {
    name: Autosave,
    placement: Placement.Save,
    availableForStatus: [VersionStatus.Draft, VersionStatus.DraftInReview, VersionStatus.DraftReviewed],
    requiredPermission: [USER_MODULE_PERMISSION.UpdateAllModulesFlow],
    requiredModulePermission: [ModuleActionsPermissions.updateModuleFlow],
  },
  {
    name: SendToReview,
    icon: 'icon-ico_review',
    placement: Placement.Primary,
    availableForStatus: [VersionStatus.Draft, VersionStatus.Test],
    requiredPermission: [USER_MODULE_PERMISSION.UpdateAllModulesFlow],
    requiredModulePermission: [ModuleActionsPermissions.updateModuleFlow],
    specialConditionFn: (module, userId) => !!module.reviewerId,
  },
  {
    name: ConfirmReview,
    icon: 'icon-ico_success',
    placement: Placement.Primary,
    availableForStatus: [VersionStatus.DraftInReview, VersionStatus.TestInReview],
    requiredModulePermission: [ModuleActionsPermissions.createModuleReview],
    specialConditionFn: (module, userId) => module.reviewerId === userId,
  },
  {
    name: RejectReview,
    icon: 'icon-ico_reject',
    placement: Placement.Primary,
    availableForStatus: [VersionStatus.DraftReviewed, VersionStatus.TestReviewed],
    requiredModulePermission: [ModuleActionsPermissions.createModuleReview],
    specialConditionFn: (module, userId) => module.reviewerId === userId,
  },
  {
    name: BreakReview,
    icon: 'icon-ico_break',
    placement: Placement.Primary,
    availableForStatus: [VersionStatus.DraftInReview, VersionStatus.TestInReview],
    requiredPermission: [USER_MODULE_PERMISSION.UpdateAllModulesFlow],
    requiredModulePermission: [ModuleActionsPermissions.updateModuleFlow],
    specialConditionFn: (module, userId) => module.reviewerId !== userId,
  },
  {
    name: SendBackToRevision,
    icon: 'icon-ico_send-back',
    placement: Placement.Primary,
    availableForStatus: [VersionStatus.DraftInReview, VersionStatus.TestInReview],
    requiredModulePermission: [ModuleActionsPermissions.createModuleReview],
    specialConditionFn: (module, userId) => module.reviewerId === userId,
  },
  {
    name: CloneToCreateNewVersion,
    icon: 'icon-ico_clone',
    placement: Placement.Secondary,
    availableForStatus: [
      VersionStatus.Draft,
      VersionStatus.DraftInPublishing,
      VersionStatus.DraftInReview,
      VersionStatus.DraftReviewed,
      VersionStatus.Expired,
      VersionStatus.ExpiredInRenewal,
      VersionStatus.Live,
      VersionStatus.LiveInRenewal,
      VersionStatus.Test,
      VersionStatus.TestInPublishing,
      VersionStatus.TestInReview,
      VersionStatus.TestReviewed,
    ],
    requiredPermission: [USER_MODULE_PERMISSION.CreateAllModulesFlow],
    requiredModulePermission: [ModuleActionsPermissions.createModuleFlow],
  },
  {
    name: ModuleInformation,
    icon: 'icon-ico_tooltip',
    placement: Placement.Secondary,
    availableForStatus: [
      VersionStatus.Draft,
      VersionStatus.DraftInPublishing,
      VersionStatus.DraftInReview,
      VersionStatus.DraftReviewed,
      VersionStatus.Expired,
      VersionStatus.ExpiredInRenewal,
      VersionStatus.Live,
      VersionStatus.LiveInRenewal,
      VersionStatus.Test,
      VersionStatus.TestInPublishing,
      VersionStatus.TestInReview,
      VersionStatus.TestReviewed,
    ],
    requiredModulePermission: [ModuleActionsPermissions.readModuleFlow],
  },
];
