import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../core/services/user-service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COMPLETE_PROFILE_FIELDS_META } from './complete-profile-fields.meta';
import { User } from 'src/app/core/models/user/user.model';
import { DictionaryService } from '../../core/services/dictionary.service';
import { LoginService } from '../../core/services/login.service';
import { Area } from 'src/app/core/models/dictionaries/area.model';
import { Country } from 'src/app/core/models/dictionaries/country.model';
import { ServiceLine } from 'src/app/core/models/dictionaries/serviceline.model';
import { SubServiceLine } from 'src/app/core/models/dictionaries/subserviceline.model';
import { Config, ConfigService } from 'src/app/core/services/configuration.service';
import { EyFormBaseComponent } from '../../shared/components/ey-form-base/ey-base-form-control';
import { ModalResult } from 'src/app/shared/components/ey-modal-template/ey-modal-result.enum';

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.scss'],
})
export class CompleteProfileComponent extends EyFormBaseComponent implements OnInit, OnDestroy {
  public areas: Area[];
  public allCountries: Country[];
  public countries: Country[];
  public sls: ServiceLine[];
  public allSubSls: SubServiceLine[];
  public subSls: SubServiceLine[];

  fieldsMeta = COMPLETE_PROFILE_FIELDS_META;

  completeProfileForm: UntypedFormGroup = this.fb.group(
    {
      name: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
      email: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(150)]],
      areaId: [null, [Validators.required]],
      countryId: [null, [Validators.required]],
      serviceLineId: [null, [Validators.required]],
      subServiceLineId: [null, [Validators.required]],
    },
    { updateOn: 'change' },
  );

  private destroy$: Subject<void> = new Subject<void>();
  private config: Config = this.configService.getConfig();

  constructor(
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private dictionaryService: DictionaryService,
    private loginService: LoginService,
    private configService: ConfigService,
    private el: ElementRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadData();
  }

  setFocusOnTheFirstFormElement(): void {
    this.setFocusFirstFormElement(this.completeProfileForm, this.el);
  }

  loadData(): void {
    forkJoin([
      this.dictionaryService.getArea(),
      this.dictionaryService.getCountry(),
      this.dictionaryService.getServiceLine(),
      this.dictionaryService.getSubServiceLine(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([areas, countries, sls, subSls]) => {
        this.areas = areas;
        this.countries = countries;
        this.allCountries = countries;
        this.sls = sls;
        this.allSubSls = subSls;
        this.subSls = subSls;
      });

    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: User) => {
        this.completeProfileForm = this.fb.group(
          {
            name: [user.name, [Validators.required, Validators.max(150)]],
            email: [user.email, [Validators.required, Validators.max(150)]],
            areaId: [null, [Validators.required]],
            countryId: [null, [Validators.required]],
            serviceLineId: [null, [Validators.required]],
            subServiceLineId: [null, [Validators.required]],
          },
          { updateOn: 'change' },
        );
      });
  }

  onAreaChanged(area: Area): void {
    if (area !== null && area.id > 1) {
      this.countries = this.allCountries.filter((c) => c.areaId === area.id);
    } else {
      this.countries = this.allCountries.filter((c) => c.id !== 1);
    }
    const country = { countryId: null };
    this.completeProfileForm.patchValue(country);
  }

  onSlChanged(sl: ServiceLine): void {
    if (sl) {
      this.subSls = this.allSubSls.filter((s) => s.serviceLineId === null || s.serviceLineId === sl.id);
      let crossSubSls = this.allSubSls.filter((s) => s.serviceLineId === null)[0];
      if (crossSubSls) {
        let index = this.allSubSls.indexOf(crossSubSls) - 1;
        crossSubSls.name = 'Cross SubServicelines - ' + sl.name.toString();
        this.allSubSls[index] = crossSubSls;
      }
    }
    const subServiceLine = { subServiceLineId: null };
    this.completeProfileForm.patchValue(subServiceLine);
  }

  onCancel(): void {
    this.activeModal.close('cancel');
    this.loginService.logout(`${this.config.environment.redirectUrl}/profile-not-completed`);
  }

  onCompleteProfile(): void {
    if (this.completeProfileForm.valid) {
      const user: any = this.completeProfileForm.value;
      const updateProfile: any = {};

      updateProfile.areaId = user.areaId.id;
      updateProfile.serviceLineId = user.serviceLineId.id;
      updateProfile.subServiceLineId = user.subServiceLineId.id;
      updateProfile.countryId = user.countryId.id;

      this.userService
        .updateProfile(updateProfile)
        .pipe(takeUntil(this.destroy$))
        .subscribe((x) => {
          this.activeModal.close(ModalResult.submit);
        });
    } else {
      this.completeProfileForm.markAllAsTouched();
      this.focusOnError(this.completeProfileForm, this.el);
    }
  }

  ngOnDestroy(): void {
    if (!this.completeProfileForm.valid) {
      this.loginService.logout(`${this.config.environment.redirectUrl}/profile-not-completed`);
    }

    this.destroy$.next();
  }
}
