<div class="pt-20 pb-20 d-flex align-content-center">
  <a class="back-button d-flex m-0 p-0" [routerLink]="'../..'" [queryParams]="{ atid: this.parentActiveTabId }">
    <i class="emp-icon icon-ico_arrow_left me-10"></i>
    <span class="back-button--text pe-20">Back</span>
  </a>
  <h1 class="flex-grow-1 ms-20 text-white">{{ project?.projectName }}</h1>
  <div class="d-flex align-items-center">
    <div class="d-flex pe-20" [class.grey-border-right]="isGetWebLinkAvailable && (projectParticipantsUpdate || globalWebLinkPermission)">
      <ey-pill
        class="d-flex align-items-center"
        [pillType]="getProjectStatus(project?.status)?.pillType"
        [toolTip]="getProjectStatus(project?.status)?.toolTip"
      >
        {{ getProjectStatus(project?.status)?.labelText ? getProjectStatus(project?.status)?.labelText : project?.status }}
      </ey-pill>
    </div>
    <div class="d-flex ps-20" *ngIf="isGetWebLinkAvailable && (projectParticipantsUpdate || globalWebLinkPermission)">
      <button type="button" class="btn btn--primary-dark btn-border-color m-0 d-flex align-items-center" (click)="getWebLink(project.projectId)">
        <i class="me-10 emp-icon icon-ico_weblink"></i> Get Web Link
      </button>
    </div>
  </div>
</div>
<ey-tabset [destroyOnHide]="true" [activeId]="activeTabId" (tabChange)="initTab($event)" class="pb-5" [showBottomBorder]="true">
  <ey-tab title="Responses" [id]="projectTabs.ProjectResponses">
    <ng-template eyTabContent>
      <app-responses-manage-project
        [isBulkUploadAvailable]="isBulkUploadAvailable"
        [projectName]="project?.projectName"
        [projectId]="projectId"
        [projectTab]="projectMgr"
        [otherPermissions]="otherUserPermissions"
        [projectPermissions]="project?.userPermissions"
      ></app-responses-manage-project>
    </ng-template>
  </ey-tab>
  <ey-tab title="Project Information" [id]="projectTabs.ProjectInfo" *ngIf="readProject || projectPermissions?.ReadAllProjects">
    <ng-template eyTabContent>
      <app-project-info [project]="project"></app-project-info>
    </ng-template>
  </ey-tab>
  <ey-tab title="Team Members" [id]="projectTabs.TeamMembers">
    <ng-template eyTabContent>
      <app-team-members [project]="project"></app-team-members>
    </ng-template>
  </ey-tab>
  <ey-tab title="Document Automation" [id]="projectTabs.DocumentAutomation" *ngIf="updateProject || projectPermissions?.UpdateAllProjects">
    <ng-template eyTabContent>
      <app-document-automation [projectId]="projectId"></app-document-automation>
    </ng-template>
  </ey-tab>

  <ey-tab title="Bulk Upload Responses" [id]="projectTabs.BulkUploadResponses">
    <ng-template eyTabContent>
      <app-bulk-upload-responses
        [projectName]="project?.projectName"
        [projectId]="projectId"
        [isBulkUploadAvailable]="isBulkUploadAvailable"
      ></app-bulk-upload-responses>
    </ng-template>
  </ey-tab>
  <ey-tab title="Bulk Download Responses" [id]="projectTabs.BulkDownloadResponses">
    <ng-template eyTabContent>
      <app-bulk-download-responses
        [projectName]="project?.projectName"
        [projectId]="project?.projectId"
        [projectPermissions]="project?.userPermissions"
      ></app-bulk-download-responses>
    </ng-template>
  </ey-tab>
</ey-tabset>
