import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import { IMailBarSection } from '../../../builders/letter-builder/mail-bar/mail-bar-section/mail-bar-section.model';
import { ButtonClass } from 'src/app/shared/components/ey-button/ey-button.component';
import { FlowType, ModuleFlowService } from '../../module-flow.service';
import { BasePageContent } from '../base-page-content';
import { switchMap, takeUntil } from 'rxjs/operators';
import { SaveNotificationServiceEndUserForm } from '../../save-notification-service-end-user-form.service';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';

@Component({
  selector: 'app-mail-page',
  templateUrl: './mail-page.component.html',
  styleUrls: ['./mail-page.component.scss'],
})
export class MailPageComponent extends BasePageContent implements OnInit, AfterViewInit {
  @HostBinding('class.d-flex') flex = true;
  @HostBinding('class.flex-fill') flexFill = true;

  btnClass = ButtonClass;

  constructor(
    flowService: ModuleFlowService,
    private saveNotification: SaveNotificationServiceEndUserForm,
    protected spinnerService: EyAppSpinnerService,
  ) {
    super(flowService, spinnerService);
  }

  get mailSections(): Array<IMailBarSection> {
    return this.page.moduleFlowPage?.properties[0]?.mailSections || [];
  }

  get htmlBlocks(): Array<string> {
    return this.page.moduleFlowPage?.properties[0]?.reportBlocksProperty || [];
  }

  get isHidden(): boolean {
    return this.page.moduleFlowPage?.properties[0]?.isHidden || false;
  }

  get isMailNotTriggered(): boolean {
    return this.page.moduleFlowPage?.properties[0]?.condition?.isSatisfied === false;
  }

  ngOnInit(): void {
    this.flowService.responseHeaderMobileViewChange.pipe(takeUntil(this.destroy$)).subscribe((mobileViewChange) => {
      this.isMobileBreakpoint = mobileViewChange;
    });

    // TODO SO: refactor this quick fix to a normal operation
    if (this.flowType === FlowType.response && this.saveNotification.saveCurrentProperties$.observers.length === 0) {
      this.saveNotification.saveCurrentProperties$
        .pipe(
          switchMap(() => this.flowService.saveCurrent(this.page.moduleFlowPage.id)),
          takeUntil(this.destroy$),
        )
        .subscribe((response) => {
          this.flowService.transformCurrentPageData(response);
          this.page = response;
        });
    }
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);
  }
}
