import { PillType } from '../shared/components/ey-pill/ey-pill.component';

export const MODULE_LIVE_STATUS_ID = 5;
export const MODULE_TEST_STATUS_ID = 3;
export const MODULE_DRAFT_STATUS_ID = 2;
export const MODULE_EMPTY_STATUS_ID = 1;
export const MODULE_EXPIRED_STATUS_ID = 4;

export enum NavPlacement {
  primary,
  secondary,
  none,
}

export interface IAllModulesStatus {
  name: string;
  id: number;
}

export const AllModulesStatus: IAllModulesStatus[] = [
  {
    name: 'Live',
    id: MODULE_LIVE_STATUS_ID,
  },
  {
    name: 'Test',
    id: MODULE_TEST_STATUS_ID,
  },
  {
    name: 'Draft',
    id: MODULE_DRAFT_STATUS_ID,
  },
  {
    name: 'Empty',
    id: MODULE_EMPTY_STATUS_ID,
  },
  {
    name: 'Expired',
    id: MODULE_EXPIRED_STATUS_ID,
  },
];

export const CLIENT_ACCESSIBLE = [
  {
    name: 'Yes',
    value: true,
  },
  {
    name: 'No',
    value: false,
  },
];

export const CLIENT_SPECIFIC_INFO = [
  {
    name: 'Yes',
    value: true,
  },
  {
    name: 'No',
    value: false,
  },
];

export const HIDDEN_FROM_SEARCH = [
  {
    name: 'Yes',
    value: true,
  },
  {
    name: 'No',
    value: false,
  },
];

export const MODULE_GOLD_STANDARD = [
  {
    name: 'Yes',
    value: true,
  },
  {
    name: 'No',
    value: false,
  },
];

export enum ModuleStatus {
  Live = 'Live',
  Test = 'Test',
  Draft = 'Draft',
  Empty = 'Empty',
  Expired = 'Expired',
}

export enum ModuleReviewStatus {
  Todo = 'To Do',
  Completed = 'Completed',
  Rejected = 'Rejected',
}

export interface IModuleStatus {
  pillType: string;
  label: ModuleStatus | ModuleReviewStatus;
  id: number;
}

export const UNKNOWN_MODAL_STATUS = { color: PillType.red, label: 'UNKNOWN', id: 6 };

export const ModuleStatuses: IModuleStatus[] = [
  { pillType: PillType.green, label: ModuleStatus.Live, id: 5 },
  { pillType: PillType.orange, label: ModuleStatus.Test, id: 3 },
  { pillType: PillType.draft, label: ModuleStatus.Draft, id: 2 },
  { pillType: PillType.draft, label: ModuleStatus.Empty, id: 1 },
  { pillType: PillType.red, label: ModuleStatus.Expired, id: 4 },
];

export enum ModuleTabs {
  myModules,
  forMyReview,
  moduleLibrary,
  myFavourites,
  moduleMgr,
}

export const MODULE_MSGS = {
  DELETE: 'All Projects using this Module will Expire. Are you sure you want to delete this Module?',
  DELETE_FORBIDDEN: 'You cannot delete this Module, because it has Versions. Delete all Versions to delete the Module.',
  DELETE_TITLE: 'Delete Module',
};

export const ModuleReviewStatuses: IModuleStatus[] = [
  { pillType: PillType.lightGray, label: ModuleReviewStatus.Todo, id: 1 },
  { pillType: PillType.green, label: ModuleReviewStatus.Completed, id: 3 },
  { pillType: PillType.red, label: ModuleReviewStatus.Rejected, id: 2 },
];
