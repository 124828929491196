import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EyBaseFormControlComponent } from '../ey-base-form-control/ey-base-form-control';
import { IEyRadioGroupModel } from './ey-radio-group.model';

@Component({
  selector: 'ey-radio-group',
  templateUrl: './ey-radio-group.component.html',
  styleUrls: ['./ey-radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyRadioGroupComponent),
      multi: true,
    },
  ],
})
export class EyRadioGroupComponent extends EyBaseFormControlComponent implements ControlValueAccessor {
  @Input() values: IEyRadioGroupModel[] = [];
  @Input() horizontal = false;

  @Output() blur = new EventEmitter<any>();

  _selectedValue: any;

  constructor(
    private eRef: ElementRef,
    private controlContainer: ControlContainer,
  ) {
    super(controlContainer);
  }
  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  onBlur(): void {
    this.onTouched(this.selectedValue);
    this.onChange(this.selectedValue);
    this.blur.emit();
  }

  set selectedValue(val) {
    this._selectedValue = val;
    this.onBlur();
  }

  get selectedValue(): any {
    return this._selectedValue;
  }

  writeValue(initValue: any): void {
    this._selectedValue = initValue || [];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelectionChange(val: any): void {
    this.selectedValue = val;
  }

  isSelected(val: any): boolean {
    return JSON.stringify(this._selectedValue) === JSON.stringify(val);
  }
}
