import { Component, ElementRef, forwardRef, Input, OnInit } from '@angular/core';
import { TableBuilderFormBaseComponent } from '../table-builder-form-base.component';
import { AbstractControl, UntypedFormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { DATE_FORMATS, DropdownItem } from '../controls.const';
import { getCurrentBrowserDateFormat } from '../../../../../shared/utils/date.functions';

const META = {
  name: {
    title: 'Name',
  },
  format: {
    title: 'format',
    required: true,
    errorMsg: 'Date Format Required',
  },
};
@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateComponent),
      multi: true,
    },
  ],
})
export class DateComponent extends TableBuilderFormBaseComponent implements OnInit {
  meta = META;
  dateFormats: DropdownItem[] = DATE_FORMATS;
  currentBrowserDateFormat = getCurrentBrowserDateFormat();
  defDateFormatNumber = this.getDefaultDate();
  @Input() showFormulaSection = true;

  form = this.fbs.group(
    {
      dateFormat: [this.defDateFormatNumber, Validators.required],
    },
    { updateOn: 'blur' },
  );

  constructor(
    private fbs: UntypedFormBuilder,
    private els: ElementRef,
  ) {
    super(fbs, els);
  }

  getDefaultDate(): DropdownItem {
    const defDateFormat = DATE_FORMATS.find((t) => t.name.toLowerCase() === this.currentBrowserDateFormat.toLowerCase());
    return defDateFormat !== undefined ? defDateFormat : DATE_FORMATS[0];
  }

  ngOnInit(): void {
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges(): void {
    this.form.valueChanges.subscribe((v) => {
      this.onChange(this.convertToReturnValue(v));
    });

    this.form.statusChanges.subscribe(this.onValidatorChange);

    this.onChange(this.convertToReturnValue(this.form.value));
  }

  convertToReturnValue(value: any): any {
    return {
      ...value,
      dateFormat: value?.dateFormat?.value,
      valueDateFormat: this.currentBrowserDateFormat,
    };
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { subformerror: 'Number Input Editor Form Error!' };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  onChange: (val: any) => void = () => {};
  onValidatorChange: () => void = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(val: any): void {
    if (val) {
      const patchValue = val.dateFormat
        ? {
            ...val,
            dateFormat: this.dateFormats.find((c) => c.value === val.dateFormat),
          }
        : val;

      this.form.patchValue(patchValue);
      this.subscribeToFormChanges();
    }
  }
}
