import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EyBaseFormControlComponent } from '../ey-base-form-control/ey-base-form-control';
import * as _ from 'lodash';
import { EyTemplateDirective } from '../helpers/ey-template.directive';

@Component({
  selector: 'ey-multiselect-search',
  templateUrl: './ey-multiselect-search.component.html',
  styleUrls: ['./ey-multiselect-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyMultiselectSearchComponent),
      multi: true,
    },
  ],
})
export class EyMultiselectSearchComponent extends EyBaseFormControlComponent implements ControlValueAccessor, OnInit, AfterContentInit {
  @Input() labelName = 'name';
  @Input() placeholder = '';
  @Input() labelName2 = '';
  @Input() values: any[] = [];
  @Input() showLargeBadges = false;
  @Input() limit: number = null;
  @Input() tooltipPadding = true;
  @Input() showSpinner = false;
  @Input() deleteTitle = 'Delete';

  @Output() searchTextChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() valueAdded: EventEmitter<any> = new EventEmitter<any>();

  @ContentChild(EyTemplateDirective) selectedItemDirective: EyTemplateDirective;
  selectedItemTemplate: TemplateRef<any> = null;
  hideSuggestions = false;

  searchText: string;
  isExpanded = false;
  _selectedValues = [];

  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  constructor(
    private eRef: ElementRef,
    controlContainer: ControlContainer,
    changeDecRef: ChangeDetectorRef,
  ) {
    super(controlContainer, changeDecRef);
  }
  ngAfterContentInit(): void {
    if (this.selectedItemDirective) {
      this.selectedItemTemplate = this.selectedItemDirective.template;
    }
  }

  ngOnInit(): void {}

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
    this.onChange(this.selectedValues);
    this.onTouched(this.selectedValues);
  }
  @HostListener('document:click', ['$event'])
  clickOut(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isExpanded = false;
    }
  }

  set selectedValues(val) {
    this._selectedValues = val;
    this.onChange(val);
    this.onTouched(val);
  }

  get selectedValues(): any {
    return this._selectedValues;
  }

  writeValue(initValue: any): void {
    this._selectedValues = initValue || [];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  stChanged(val: string): void {
    this.searchTextChanged.emit(val);
  }

  add(v): any {
    this.searchText = '';
    if (_.isEmpty(this.selectedValues)) {
      this.selectedValues = [{ ...v }];
      this.valueAdded.emit(v);
    } else {
      const item = this.selectedValues.find((t) => JSON.stringify(t) === JSON.stringify(v));
      if (item === undefined) {
        this.selectedValues = [...this.selectedValues, { ...v }];
        this.valueAdded.emit(v);
      }
    }
    this.values = [];
  }

  removeItem(item: any): void {
    this.selectedValues = this.selectedValues.filter((t) => JSON.stringify(t) !== JSON.stringify(item));
  }

  onBlur(): void {
    this.searchText = '';
    this.isFocus = false;
    setTimeout(() => {
      this.hideSuggestions = true;
      this.values = [];
    }, 200);
  }
}
