<div class="d-flex flex-fill">
  <div class="menubar d-flex justify-content-between align-items-center flex-fill">
    <div class="d-flex px-3">
      <div class="d-flex align-items-center">
        <a href class="back-link eyfontbold" title="Back to Designer" (click)="onGoBack(); $event.preventDefault()">
          <i class="material-icons">west</i>
          Back
        </a>
      </div>
      <div class="separator"></div>
      <div class="module">
        <div class="title" [title]="title">
          {{ title }}
        </div>
        <div class="sub-title d-flex" [title]="subTitle">
          <span class="label flex-grow-1"> {{ subTitle }} </span><span class="ms-1">{{ saveStatus }}</span>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <ng-content></ng-content>
    </div>
  </div>
</div>
