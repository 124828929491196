import { Directive, ElementRef, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NumberFormatService } from '../../../builders/table-builder/table/number-format.service';
import { CATEGORY_PERCENT, SeparatorEnum } from '../../../builders/table-builder/controls/controls.const';
import { NumberNormalizationService } from 'src/app/core/services/number-normalization.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[tableCellFormatOnBlur]',
  exportAs: 'tableCellFormatOnBlur',
})
export class TableCellInputFocusDirective implements OnInit {
  @Input() tableCellFormatOnBlur: any;
  @Input() transformFormItem = true;
  @HostBinding('class.my-focused-element') isFocused: boolean;
  tableCellValue: any;
  constructor(
    public model: NgControl,
    private elRef: ElementRef,
    private numberNormalizationService: NumberNormalizationService,
    private numberFormatService: NumberFormatService,
  ) {}

  @HostListener('focus', ['$event']) onFocus(e): void {
    this.isFocused = true;
    this.elRef.nativeElement.getElementsByTagName('input')[0].value = this.formatTableNumber(this.tableCellValue, this.tableCellFormatOnBlur);
  }

  @HostListener('blur', ['$event']) onblur(e): void {
    this.isFocused = false;
    this.tableCellValue = this.elRef.nativeElement.getElementsByTagName('input')[0].value;
    this.elRef.nativeElement.getElementsByTagName('input')[0].value = this.formatTableNumber(this.tableCellValue, this.tableCellFormatOnBlur);
  }

  formatTableNumber(val: any, item: any): string {
    if (val == null || undefined === val || val === '') {
      return '';
    }
    const normVal = this.numberNormalizationService.normalizeNumberString(val);
    let fieldProperties = item;
    if (this.transformFormItem) {
      fieldProperties = {
        ...item,
        category: this.getCategory(item),
        useThousandsSeparator: item.properties.thousandsSeparator != null ? true : false,
        thousandsSeparator: SeparatorEnum[item.properties.thousandsSeparator],
        decimalSeparator: SeparatorEnum[item.properties.decimalSeparator],
      };
    }
    return this.numberFormatService.formatTableCellNumber(normVal, fieldProperties);
  }

  getCategory(item: any): string {
    if (item.properties.category) {
      return item.properties.category;
    }
    if (item.properties.isPercentage) {
      return CATEGORY_PERCENT;
    }
    return '';
  }

  ngOnInit(): void {
    this.elRef.nativeElement.getElementsByTagName('input')[0].addEventListener('focus', (e) => this.onFocus(e));
  }
}
