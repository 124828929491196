import { UndoableState } from '../designer/workflow-designer/domain/undoable-state';
import { createUndoableReducer } from '../designer/workflow-designer/utils/reducer.utils';
import { createReducer, on } from '@ngrx/store';
import { updateCurrentPageData } from './application.actions';

export interface ApplicationState {
  pageData: any | null;
  error: string | null;
}

export const initialState: ApplicationState = {
  pageData: null,
  error: null,
};

export const applicationReducer = createReducer(
  initialState,
  on(updateCurrentPageData, (state: ApplicationState, action) => {
    const newState = {
      ...state,
      pageData: action.pageData,
    };
    return newState;
  }),
);

const initState = new UndoableState<ApplicationState>([], initialState, []);

export const undoableApplicationState = createUndoableReducer(initState, applicationReducer);
