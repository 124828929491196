import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TableBuilderControlTypes } from '../../../../builders/table-builder/controls/controls.meta';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { combineLatest, of, Subject } from 'rxjs';
import { FlowType, ModuleFlowService } from '../../../module-flow.service';
import { PropertyInput } from '../../../property-input.model';
import { ERROR_DATE, ERROR_TEXT } from '../../../../builders/table-builder/controls/controls.const';
import { Page, ViewTypes } from '../../../page.model';
import { delay, takeUntil } from 'rxjs/operators';
import { TableCellInputFocusDirective } from '../table-cell-input-focus.directive';

const META = {
  dropdown: {
    title: '',
  },
  textInput: {
    title: '',
  },
};

interface CellPropertyInput {
  rowIndex: number;
  colIndex: number;
  value: any;
}

@Component({
  selector: 'app-table-preview',
  templateUrl: './table-preview.component.html',
  styleUrls: ['./table-preview.component.scss'],
})
export class TablePreviewComponent implements OnInit, AfterViewInit {
  @ViewChildren('tableCellFormatOnBlur') tableCellFormatOnBlur: QueryList<TableCellInputFocusDirective>;
  public destroy$ = new Subject<boolean>();
  @Input() flowType: FlowType = FlowType.preview;
  @Input() selectedViewType: ViewTypes;
  @Input() overflowVisible = false;
  meta = META;
  cellTypes = TableBuilderControlTypes;
  formArray: UntypedFormArray;

  propertiesMap = new Map<string, CellPropertyInput>();
  @Input() page: any;
  @Input() isAutoSave: boolean;

  get formCells(): any {
    return this.page?.moduleFlowPage?.children;
  }

  getProperties(cellProperties: CellPropertyInput[]): Array<PropertyInput> {
    return cellProperties.map((c) => ({ name: this.page?.moduleFlowPage?.name, value: JSON.stringify(c) }));
  }

  constructor(
    private flowService: ModuleFlowService,
    private fb: UntypedFormBuilder,
  ) {}

  ngAfterViewInit(): void {
    window.scroll(0, 0);
    of(true)
      .pipe(delay(50), takeUntil(this.destroy$))
      .subscribe((t) => {
        this.tableCellFormatOnBlur.forEach((d) => d.onblur(null));
      });
  }

  ngOnInit(): void {
    this.formArray = new UntypedFormArray(
      this.formCells.map((x) => new UntypedFormArray(x.map((y) => new UntypedFormControl(y.value)))),
      { updateOn: 'blur' },
    );
    this.formArray.controls.forEach((c: UntypedFormArray, rowIndex) => {
      c.controls.forEach((sc, colIndex) => {
        const indexes$ = of({ rowIndex, colIndex });
        const formValChanged$ = sc.valueChanges;
        combineLatest([indexes$, formValChanged$]).subscribe(([index, cellVal]) => {
          const val = { ...index, value: this.getCellValue(this.formCells[index.rowIndex][index.colIndex].type, cellVal) };
          this.propertiesMap.set(`${index.rowIndex}${index.colIndex}`, val);

          const cellProps = this.getProperties(Array.from(this.propertiesMap.values()));

          if (this.flowType === FlowType.response && this.isAutoSave) {
            this.flowService
              .saveCurrent(this.page.moduleFlowPage.id, cellProps)
              .pipe(takeUntil(this.destroy$))
              .subscribe((response) => {
                this.setTableValuesFromResponse(response);
              });
          } else {
            this.flowService
              .recalculateCurrent(this.page.moduleFlowPage.id, cellProps, this.flowType)
              .pipe(takeUntil(this.destroy$))
              .subscribe((response) => {
                this.setTableValuesFromResponse(response);
              });
          }
        });
      });
    });
  }

  setTableValuesFromResponse(response: Page): void {
    const cells = response?.moduleFlowPage?.children;

    if (!cells) {
      return;
    }

    this.page?.moduleFlowPage?.children?.forEach((tableRow, rowIndex) => {
      tableRow.forEach((tableCell, colIndex) => {
        const cell = cells[rowIndex][colIndex];
        if (cell) {
          tableCell.value = cell.value;
          tableCell.formattedControlContent = this.flowService.getTableFormattedControlContent(cell);
        }
      });
    });

    this.tableCellFormatOnBlur.forEach((d) => d.onblur(null));
  }

  getValue(cell: any, rowIndex: number, colIndex: number): void {
    return cell.formattedControlContent;
  }

  getCellValue(type: TableBuilderControlTypes, value: any): any {
    switch (type) {
      case TableBuilderControlTypes.dropdown:
        return value?.conId;
      default:
        return value;
    }
  }

  get isHidden(): boolean {
    return this.page.moduleFlowPage?.isHidden;
  }

  isError(val: string): boolean {
    return val === ERROR_TEXT || val === ERROR_DATE;
  }
}
