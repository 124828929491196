<div
  #templateContainer
  class="template-container d-flex flex-fill flex-column"
  [ngClass]="{ 'overflow-x-inherit': selectedViewType === 0, 'overflow-x-clip': selectedViewType !== 0 }"
>
  <div #body class="body flex-fill p-40 overflow-x-auto">
    <ng-content select="[appPageContent]"></ng-content>
  </div>
  <div
    #footer
    class="footer p-20"
    [class.remove-padding-top]="removePaddingTopOnReviewDateDisplay"
    [class.remove-padding-bottom]="removePaddingBottomOnReviewDateDisplay"
  >
    <ng-content select="[appPageFooter]"></ng-content>
  </div>
</div>

<!-- div class="container-fluid p-0">
  <div class="d-flex flex-column min-vh-100">
    <div #body class="d-flex flex-fill">

      <ng-content select="[appPageContent]"></ng-content>

    </div>
    <div #footer class="d-flex">
      <ng-content select="[appPageFooter]"></ng-content>
    </div>
  </div>
</div -->
