import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-allowed-file-type-modal',
  templateUrl: './allowed-file-type-modal.component.html',
  styleUrls: ['./allowed-file-type-modal.component.scss'],
})
export class AllowedFileTypeModalComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onDismiss(): void {
    this.activeModal.close();
  }
}
