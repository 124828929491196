import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CompleteProfileComponent } from 'src/app/user/complete-profile/complete-profile.component';
import { UserService } from '../../core/services/user-service';
import { User } from 'src/app/core/models/user/user.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModalVerticalSizeWindowClass, ModalWidth } from '../../shared/components/ey-modal-template/ey-modal-result.enum';
import { ContentViewerBreakpoints } from '../../designer/preview/content-viewer/content-viewer.component';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  isMobileBreakpoint = false;
  modalOptions: NgbModalOptions = {
    animation: false,
    backdrop: 'static',
    size: ModalWidth.default,
    windowClass: ModalVerticalSizeWindowClass.auto,
  };

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.userService.initCurrentUserSession().pipe(takeUntil(this.destroy$)).subscribe();
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: User) => {
        if (!user.isUserProfileCompleted) {
          this.modalService.open(CompleteProfileComponent, this.modalOptions);
        }
      });
    this.breakpointObserver
      .observe([ContentViewerBreakpoints.tablet, ContentViewerBreakpoints.mobile])
      .pipe(takeUntil(this.destroy$))
      .subscribe((o) => {
        this.isMobileBreakpoint = o.breakpoints[ContentViewerBreakpoints.mobile];
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
