import { Component, forwardRef, Input } from '@angular/core';
import { EyBaseFormControlComponent } from '../ey-base-form-control/ey-base-form-control';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface CheckBoxVal {
  title?: string;
  hint?: string;
}

@Component({
  selector: 'ey-checkbox',
  templateUrl: './ey-checkbox.component.html',
  styleUrls: ['./ey-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyCheckboxComponent),
      multi: true,
    },
  ],
})
export class EyCheckboxComponent extends EyBaseFormControlComponent implements ControlValueAccessor {
  @Input() value: CheckBoxVal = { title: '', hint: '' };
  @Input() isDisabled: boolean;

  #selectedValue: boolean;

  get selectedValue(): boolean {
    return this.#selectedValue;
  }

  constructor(controlContainer: ControlContainer) {
    super(controlContainer);
  }

  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  writeValue(value: boolean): void {
    this.#selectedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onCheckboxChange(event: InputEvent): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.#selectedValue = isChecked;

    this.onTouched(this.#selectedValue);
    this.onChange(this.#selectedValue);
  }

  isChecked(): boolean {
    return this.#selectedValue;
  }
}
