<div class="d-flex" *ngIf="splitText; else singleLineText">
  <span [title]="fullTextOnHover" class="split-text-container">
    <span [title]="fullTextOnHover" class="split-text"
      >{{ text | slice: 0 : charLimit }}<span *ngIf="text && text.length > charLimit" class="three-dots">...</span></span
    >
  </span>
</div>

<ng-template #singleLineText>
  <span class="single-line-text" [title]="fullTextOnHover">{{ text | slice: 0 : charLimit }}</span>
  <span *ngIf="text && text.length > charLimit" class="three-dots">...</span>
</ng-template>
