export enum ProjectActionsPermissions {
  readProject = 'project.read',
  updateProject = 'project.update',
  deleteProject = 'project.delete',

  createProjectMembers = 'projectmembers.create',
  readProjectMembers = 'projectmembers.read',
  updateProjectMembers = 'projectmembers.update',
  deleteProjectMembers = 'projectmembers.delete',

  createProjectParticipants = 'projectparticipiants.create',
  readProjectParticipants = 'projectparticipiants.read',
  updateProjectParticipants = 'projectparticipiants.update',
  deleteProjectParticipants = 'projectparticipiants.delete',

  createResponses = 'responses.create',
  readResponses = 'responses.read',

  createMyResponse = 'myresponse.create',
}
