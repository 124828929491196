import { NavPlacement } from '../project.const';
import { IAction } from '../grid-actions/action.model';
import { ResponseActionsPermissions } from './response-permissions.const';

export enum ActionTypes {
  ViewSummary = 'View Summary',
  ResumeDraft = 'Resume Draft',
  Delete = 'Delete',
  Lock = 'Lock',
  Unlock = 'Unlock',
  ListOfRespondents = 'List Of Respondents',
  ReviewDraft = 'Review Response',
  Duplicate = 'Duplicate',
}

export enum ViewType {
  Projects = 0,
  Dashboard,
}

export const RESPONSE_ACTIONS: IAction[] = [
  {
    actionType: ActionTypes.ViewSummary,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_eye',
    requiredPermissions: [ResponseActionsPermissions.readAllResponses, ResponseActionsPermissions.readResponses],
  },
  {
    actionType: ActionTypes.ResumeDraft,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_edit',
    requiredPermissions: [ResponseActionsPermissions.createMyResponse],
  },
  {
    actionType: ActionTypes.Delete,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_delete',
    requiredPermissions: [ResponseActionsPermissions.deleteAllResponses, ResponseActionsPermissions.deleteResponses],
  },
  {
    actionType: ActionTypes.Lock,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_unlock',
    requiredPermissions: [ResponseActionsPermissions.readAllResponses, ResponseActionsPermissions.readResponses],
  },
  {
    actionType: ActionTypes.Unlock,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_lock',
    requiredPermissions: [ResponseActionsPermissions.readAllResponses, ResponseActionsPermissions.readResponses],
  },
  {
    actionType: ActionTypes.ListOfRespondents,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_mult_users',
    requiredPermissions: [ResponseActionsPermissions.readAllResponses, ResponseActionsPermissions.readResponses],
  },
  {
    actionType: ActionTypes.ReviewDraft,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_review',
    requiredPermissions: [ResponseActionsPermissions.createResponses],
    useActionTypeAsDescription: true,
  },
  {
    actionType: ActionTypes.Duplicate,
    navPlacement: NavPlacement.none,
    icon: 'icon-ico_clone',
    requiredPermissions: [ResponseActionsPermissions.createMyResponse, ResponseActionsPermissions.createResponses],
    useActionTypeAsDescription: true,
  },
];

export const RESPONSE_ACTIONS_DASHBOARD: IAction[] = [
  {
    actionType: ActionTypes.Delete,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_delete',
    requiredPermissions: [ResponseActionsPermissions.readResponses],
  },
];
