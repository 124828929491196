import { Injectable } from '@angular/core';

interface IMsalTokenHelper {
  getAccessToken(): string;
}

@Injectable({
  providedIn: 'root',
})
export class MsalTokenHelper implements IMsalTokenHelper {
  getAccessToken(): string {
    const key = Object.keys(sessionStorage).find((key) => key.includes('accesstoken') && key.includes('access_as_user'));
    if (!key) {
      return null;
    }
    const item = sessionStorage.getItem(key);
    if (!item) {
      return null;
    }

    const jwt = JSON.parse(item)?.secret;
    if (!jwt) {
      return null;
    }

    return `Bearer ${jwt}`;
  }
}
