import { Component, forwardRef, Input, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EyRangeSelectionLimit } from './ey-range-selection-limit';

export interface RangeSelectionResult {
  selectionLimit: EyRangeSelectionLimit;
  min?: number;
  max?: number;
  exactly?: number;
}

export const SELECTION_LIMITS_NAMES = {
  min: 'Min',
  max: 'Max',
  range: 'Range',
  exactly: 'Exactly',
  unlimited: 'Unlimited',
};

export const SELECTION_LIMITS = [
  {
    selectionLimit: EyRangeSelectionLimit.Unlimited,
    name: SELECTION_LIMITS_NAMES.unlimited,
  },
  {
    selectionLimit: EyRangeSelectionLimit.Min,
    name: SELECTION_LIMITS_NAMES.min,
  },
  {
    selectionLimit: EyRangeSelectionLimit.Max,
    name: SELECTION_LIMITS_NAMES.max,
  },
  {
    selectionLimit: EyRangeSelectionLimit.Range,
    name: SELECTION_LIMITS_NAMES.range,
  },
  {
    selectionLimit: EyRangeSelectionLimit.Exactly,
    name: SELECTION_LIMITS_NAMES.exactly,
  },
];

function minIsLessThanOptionCount(optionCount: number): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value === null || c.value === '') {
      return { minIsNotLessThanOptionCount: true };
    }

    const count = +c.value;

    return count < optionCount ? null : { minIsNotLessThanOptionCount: true };
  };
}

function maxIsLessOrEqualThanOptionCount(optionCount: number): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value === null || c.value === '') {
      return { maxIsNotLessOrEqualThanOptionCount: true };
    }

    const count = +c.value;

    return count <= optionCount ? null : { maxIsNotLessOrEqualThanOptionCount: true };
  };
}

function exactlyShouldBeLessOrEqualThanOptionCount(optionCount: number): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value === null || c.value === '') {
      return { exactlyIsNotLessThanOptionCount: true };
    }

    const count = +c.value;

    return count <= optionCount ? null : { exactlyIsNotLessThanOptionCount: true };
  };
}

function minShouldBeLowerThanMax(g: UntypedFormGroup): ValidationErrors {
  const min = +g.controls.min.value;
  const max = +g.controls.max.value;

  return min < max ? null : { minShouldBeLowerThanMax: true };
}

@Component({
  selector: 'ey-range-selector',
  templateUrl: './ey-range-selector.component.html',
  styleUrls: ['./ey-range-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyRangeSelectorComponent),
      multi: true,
    },
  ],
})
export class EyRangeSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() set optionsCount(val) {
    this._optionsCount = val;

    if (this.range && (val !== null || val !== undefined)) {
      const value = this.range.value;
      const touched = this.range.touched;

      this.initForm(val);

      this.range.patchValue(value);

      if (touched) {
        this.range.markAllAsTouched();
      }
    }
  }

  @Output() rangeSelection = new EventEmitter<number>();

  get optionsCount(): number {
    return this._optionsCount;
  }

  _optionsCount = null;

  rangeMeta = {
    selectionLimits: {
      title: 'Selection Limits',
    },
    min: {
      title: 'Min',
    },
    max: {
      title: 'Max',
    },
    exactly: {
      title: 'Exactly',
    },
  };

  selectionLimits = SELECTION_LIMITS;

  get selectionLimitsDefault(): any {
    return this.selectionLimits[0];
  }

  selectionLimit = this.selectionLimitsDefault;

  range: UntypedFormGroup = null;
  private destroy$ = new Subject<boolean>();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  onChange: (r: any) => void = () => {};
  onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      const selectionLimit = this.selectionLimits.find((s) => s.selectionLimit === val.selectionLimit);

      if (selectionLimit) {
        this.selectionLimit = selectionLimit;
      }

      this.range.patchValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.initForm(this.optionsCount);

    this.range.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((v) => {
      if (this.formInvalid) {
        return;
      }

      this.changeIncorrectInput(v.min, 'min');
      this.changeIncorrectInput(v.max, 'max');
      this.changeIncorrectInput(v.exactly, 'exactly');

      const newValue = this.range.getRawValue();

      const result: RangeSelectionResult = { selectionLimit: this.selectionLimit.selectionLimit };

      if (newValue.min) {
        result.min = newValue.min;
      }

      if (newValue.max) {
        result.max = newValue.max;
      }

      if (newValue.exactly) {
        result.exactly = newValue.exactly;
      }

      this.onChange(result);
      this.onTouched();
    });
  }

  changeIncorrectInput(valueStr: string, controlName: string): void {
    if (!valueStr) {
      return;
    }

    const control = this.range.get(controlName);
    const value = +valueStr;
    if (value < 0) {
      this.range.controls.min.setValue('0');
    }

    if (!Number.isInteger(value)) {
      control.setValue(`${Math.floor(value)}`);
    }
  }

  initForm(optionsCount: number): void {
    this.range = this.fb.group(
      {
        min: [null, minIsLessThanOptionCount(optionsCount)],
        max: [null, maxIsLessOrEqualThanOptionCount(optionsCount)],
        exactly: [null, exactlyShouldBeLessOrEqualThanOptionCount(optionsCount)],
      },
      { validators: minShouldBeLowerThanMax, updateOn: 'blur' },
    );
  }

  selectionLimitChange(): void {
    this.rangeSelection.emit(this.selectionLimit.selectionLimit);

    this.range.reset();
  }

  get currentLimit(): number {
    return this.selectionLimit.selectionLimit;
  }

  get formInvalid(): boolean {
    return (
      (this.currentLimit === EyRangeSelectionLimit.Min && this.range.controls.min.invalid) ||
      (this.currentLimit === EyRangeSelectionLimit.Max && this.range.controls.max.invalid) ||
      // tslint:disable-next-line: max-line-length
      (this.currentLimit === EyRangeSelectionLimit.Range &&
        (this.range.controls.min.invalid || this.range.controls.max.invalid || this.range.errors?.minShouldBeLowerThanMax)) ||
      (this.currentLimit === EyRangeSelectionLimit.Exactly && this.range.controls.exactly.invalid)
    );
  }

  get isMin(): boolean {
    return this.currentLimit === EyRangeSelectionLimit.Min;
  }

  get isMax(): boolean {
    return this.currentLimit === EyRangeSelectionLimit.Max;
  }

  get isRange(): boolean {
    return this.currentLimit === EyRangeSelectionLimit.Range;
  }

  get isExactly(): boolean {
    return this.currentLimit === EyRangeSelectionLimit.Exactly;
  }
}
