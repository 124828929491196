<ey-modal-template>
  <div header>
    <h2 class="modal__title">Download Generated Documents</h2>
  </div>
  <div body>
    <span *ngIf="isResponseInDraftState" class="d-flex ms-3">
      <span class="emp-icon icon-ico_waring_toast"></span>
      <p class="ms-2">
        Warning: You are downloading partially filled document for a Draft response, it may contain incomplete data or calculations. Please use this
        file with caution.
      </p>
    </span>
    <div>
      <div *ngFor="let v of fileData" class="d-flex border-bottom">
        <label class="d-flex dropdown-item dropdown-item-custom checkbox checkbox--all w-100" data-value="{{ v.name }}">
          <input class="checkbox__input" type="checkbox" ngModel="{{ v.isSelected }}" (ngModelChange)="changed($event, v)" />
          <span class="checkbox__checkmark"></span>
          <p class="checkbox__label eyfontlight position-and-line-break">{{ v.name + '.' + v.extension }}</p>
        </label>
        <i class="icon-ico_download emp-icon shift-right" title="Download" (click)="downloadSingleFile(v)"></i>
      </div>
      <div *ngIf="noFilesSelected">
        <div class="d-flex mt-3">
          <i class="icon-ico_error_toast selected_files_icon me-2"></i>
          <div class="selected_files_errmsg">Select at least one file to download</div>
        </div>
      </div>
    </div>
  </div>
  <div footer class="d-flex w-100">
    <button class="btn me-20 btn--primary btn-border-color d-flex" type="button" (click)="onDownloadAllSelected()">
      <i class="icon-ico_download emp-icon custom-icon-position"></i><span class="download-action-position">{{ downloadButtonText }}</span>
    </button>
    <button class="btn btn--secondary ms-0" type="button" (click)="onCancel()">Cancel</button>
  </div>
</ey-modal-template>
