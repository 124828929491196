import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EyGridMenuService {
  // NOTE: This is done so as to update the grid menu offset position accordingly when grid is horizontally scrolled/window is resized.
  // The offset is required so as to update the correct position of "more action" dropdown on smaller screens.

  gridMenuScrollLeftOffset = new BehaviorSubject<number>(0);

  constructor() {}
}
