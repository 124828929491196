import { ActionCreator, createAction } from '@ngrx/store';
import { NotAllowedCheck, ActionCreatorProps, TypedAction } from '@ngrx/store/src/models';

export interface NonUndoableTypedAction<T extends string> extends TypedAction<T> {
  skipCreateUndo: true;
}

export function createNonUndoableAction<T extends string>(type: T): ActionCreator<T, () => NonUndoableTypedAction<T>>;
export function createNonUndoableAction<T extends string, P extends object>(
  type: T,
  config: ActionCreatorProps<P> & NotAllowedCheck<P>,
): ActionCreator<T, (props: P & NotAllowedCheck<P>) => P & NonUndoableTypedAction<T>>;
export function createNonUndoableAction<T extends string, P extends object>(
  type: T,
  config?: ActionCreatorProps<P> & NotAllowedCheck<P>,
): ActionCreator<T> {
  const creatorInteral = createAction(type, config);

  const creator = config
    ? (p: P & NotAllowedCheck<P>) => {
        const action = creatorInteral(p);
        const actionWithProp = Object.defineProperty(action, 'skipCreateUndo', { value: true, writable: false });
        return actionWithProp;
      }
    : () => {
        const action = (creatorInteral as ActionCreator<T>)();
        const actionWithProp = Object.defineProperty(action, 'skipCreateUndo', { value: true, writable: false });
        return actionWithProp;
      };

  const nonUndoableActionCreator = Object.defineProperty(creator, 'type', {
    value: type,
    writable: false,
  });

  return nonUndoableActionCreator as ActionCreator<T>;
}
