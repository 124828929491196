import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalResult } from '../../../../shared/components/ey-modal-template/ey-modal-result.enum';

@Component({
  selector: 'app-submit-collaboration-response-modal',
  templateUrl: './submit-collaboration-response-modal.component.html',
  styleUrls: ['./submit-collaboration-response-modal.component.scss'],
})
export class SubmitCollaborationResponseModalComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onSubmit(): void {
    this.activeModal.close(ModalResult.submit);
  }

  onCancel(): void {
    this.activeModal.close(ModalResult.dismiss);
  }
}
