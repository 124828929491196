import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalResult } from '../../shared/components/ey-modal-template/ey-modal-result.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EyAppSpinnerService } from '../../shared/components/ey-app-spinner/ey-app-spinner.service';
import { ResponseService } from '../../designer/preview/submit-response/response.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { TemplateFileModel } from './template-file.modal';
import { catchError } from 'rxjs/operators';
import { EyInfoDialogService } from 'src/app/shared/components/ey-info-dialog/ey-info-dialog.service';
import { InfoDialogTypes } from 'src/app/shared/components/ey-info-dialog/info-dialog-types.enum';
import { DownloadGeneratedDocumentsService } from './download-generated-documents.service';

const DOWNLOAD_BUTTON_TEXT = 'Download Selected Documents';
const DOWNLOAD_BUTTON_TEXT_DRAFT_STATE = 'Acknowledge and Download';

@Component({
  selector: 'app-download-generated-documents-modal',
  templateUrl: './download-generated-documents-modal.component.html',
  styleUrls: ['./download-generated-documents-modal.component.scss'],
})
export class DownloadGeneratedDocumentsModalComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  noFilesSelected = false;
  downloadButtonText: string;
  @Input() fileData: Array<TemplateFileModel> = null;
  @Input() projectId: string;
  @Input() templateType: number;
  @Input() responseId: string;
  @Input() isResponseInDraftState = false;
  @Input() downloadDocumentsBasedOnTemplateType = true;

  filesToDownload: Array<{
    docAutomationId: string;
    fileName: string;
    fileExtension: string;
    templateType: number;
    dateCreated: string;
    createdBy: string;
  }> = [];
  constructor(
    public activeModal: NgbActiveModal,
    private spinnerService: EyAppSpinnerService,
    private responseService: ResponseService,
    private downloadGeneratedDocumentsService: DownloadGeneratedDocumentsService,
    private dialogService: EyInfoDialogService,
  ) {}

  ngOnInit(): void {
    this.setDownloadButtonText();
    if (this.downloadDocumentsBasedOnTemplateType) {
      this.getTemplatesBasedOnType();
    }
  }

  setDownloadButtonText(): void {
    this.isResponseInDraftState ? (this.downloadButtonText = DOWNLOAD_BUTTON_TEXT_DRAFT_STATE) : (this.downloadButtonText = DOWNLOAD_BUTTON_TEXT);
  }

  getTemplatesBasedOnType(): void {
    this.spinnerService
      .withLoadingIndicator(
        this.downloadGeneratedDocumentsService.downloadMultipleTemplatesResponsePopup(this.projectId, this.templateType),
        this.destroy$,
      )
      .subscribe((templateData) => {
        this.fileData = templateData;
      });
  }

  downloadSingleFile(fileData: TemplateFileModel): void {
    this.spinnerService
      .withLoadingIndicator(
        this.downloadGeneratedDocumentsService.downloadMultipleResponseTemplates(this.responseId, fileData.templateType, fileData.id),
        this.destroy$,
      )
      .subscribe((x) => {
        this.downloadGeneratedDocumentsService.downloadSingleTemplate(x, fileData, this.isResponseInDraftState);
        return;
      });
  }

  changed(event: boolean, data: TemplateFileModel): void {
    if (event) {
      this.filesToDownload.push({
        docAutomationId: data.id,
        fileName: data.name,
        fileExtension: data.extension,
        templateType: data.templateType,
        createdBy: data.createdBy,
        dateCreated: data.dateCreated,
      });
      this.noFilesSelected = false;
    } else {
      this.filesToDownload = this.filesToDownload.filter((fileData) => fileData.docAutomationId !== data.id);
    }
  }

  onDownloadAllSelected(): void {
    if (this.filesToDownload.length > 0) {
      this.downloadFiles();
    } else {
      this.noFilesSelected = true;
    }
  }

  downloadFiles(): void {
    this.spinnerService.withLoadingIndicator(this.getDownloadData(), this.destroy$).subscribe((response) => {
      const errors = [];
      response.forEach((res, index) => {
        if (res.isError) {
          errors.push(res);
        } else {
          this.saveFileData(res, index);
        }
      });
      if (errors.length > 0) {
        const errorMessage = 'Error downlading the following documents:<br>' + errors.map((e) => e.errorMessage).join('<br>');
        this.dialogService.openInfoDialog(errorMessage, 'Error Downloading Generated Documents', InfoDialogTypes.dismiss);
      }
    });
  }

  getDownloadData(): Observable<any> {
    const apiCallsArray = [];
    this.filesToDownload.forEach((fileData) => {
      apiCallsArray.push(
        this.downloadGeneratedDocumentsService
          .downloadMultipleResponseTemplates(this.responseId, fileData.templateType, fileData.docAutomationId)
          .pipe(
            catchError((err) => {
              return of({ isError: true, errorMessage: fileData.fileName + (err.status === 404 ? ' (file not found)' : ' (unknown error)') });
            }),
          ),
      );
    });
    return forkJoin(apiCallsArray);
  }

  saveFileData(fileDownloadResponse: any, indexToPick: number): void {
    this.downloadGeneratedDocumentsService.downloadTemplatesBasedOnIndex(
      fileDownloadResponse,
      indexToPick,
      this.filesToDownload,
      this.isResponseInDraftState,
    );
  }

  onCancel(): void {
    this.activeModal.close(ModalResult.dismiss);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
