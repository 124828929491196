import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { CoreModule } from '../core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { LoggedOutWithReasonComponent } from '../user/logged-out-with-reason/logged-out-with-reason.component';
import { LogoutPageComponent } from '../user/logout-page/logout-page.component';
import { UserModule } from './../user/user.module';
import { AccessDeniedComponent } from '../user/access-denied/access-denied.component';
import { PrivacyNoticeDeclinedComponent } from '../user/privacy-notice-declined/privacy-notice-declined.component';
import { UserProfileNotCompletedComponent } from '../user/user-profile-not-completed/user-profile-not-completed.component';
import { ComponentsModule } from '../shared/components/components.module';

@NgModule({
  imports: [NgbModule, BrowserModule, CoreModule, RouterModule, UserModule, ComponentsModule],
  declarations: [
    HomeComponent,
    LoggedOutWithReasonComponent,
    LogoutPageComponent,
    AccessDeniedComponent,
    PrivacyNoticeDeclinedComponent,
    UserProfileNotCompletedComponent,
  ],
  exports: [],
})
export class PageLayoutModule {}
