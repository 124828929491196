import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { FlowType, ModuleFlowService } from 'src/app/designer/preview/module-flow.service';
import { ButtonClass } from 'src/app/shared/components/ey-button/ey-button.component';
import { Page } from '../../page.model';
import { SummaryDocumentModel } from '../../summary-document.model';
import { BasePageContent } from '../base-page-content';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalResult, ModalVerticalSizeWindowClass, ModalWidth } from '../../../../shared/components/ey-modal-template/ey-modal-result.enum';
import { SubmitCollaborationResponseModalComponent } from '../submit-collaboration-response-modal/submit-collaboration-response-modal.component';
import { properties } from '../../../workflow-designer/tools/end-tool';
import { ActivatedRoute } from '@angular/router';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';
import { ResponseService } from '../../submit-response/response.service';

@Component({
  selector: 'app-end-page.d-flex.flex-fill',
  templateUrl: './end-page.component.html',
  styleUrls: ['./end-page.component.scss'],
})
export class EndPageComponent extends BasePageContent implements OnInit, OnDestroy {
  btnClass = ButtonClass;
  submitted = false;
  summary: Page[];
  docList: SummaryDocumentModel[] = [];
  body: string;
  @Input() flowType: FlowType = FlowType.preview;
  @Input() page: Page;
  @Input() disableSubmitOnReview = false;
  @Input() collaborationMode = false;

  destroy$ = new Subject<void>();

  constructor(
    flowService: ModuleFlowService,
    protected spinnerService: EyAppSpinnerService,
    sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private responseService: ResponseService,
    private route: ActivatedRoute,
  ) {
    super(flowService, spinnerService);
  }

  ngOnInit(): void {
    this.flowService.responseHeaderMobileViewChange.pipe(takeUntil(this.destroy$)).subscribe((mobileViewChange) => {
      this.isMobileBreakpoint = mobileViewChange;
    });

    this.body = this.page?.moduleFlowPage.body ? this.page?.moduleFlowPage.body : properties[0].options.defaultContent;
    this.route.params.subscribe((params) => {
      return;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onPrev(): void {
    this.flowService.doPrev(null, this.flowType).pipe(takeUntil(this.destroy$)).subscribe();
  }

  onSubmit(): void {
    if (this.flowType === FlowType.response) {
      if (this.collaborationMode) {
        this.showSubmittingCollaborationResponseModal();
      } else {
        this.initSubmitResponse();
      }
    }
  }

  initSubmitResponse(): void {
    const submitOnComplete$ = this.flowService.submitOnComplete(this.flowType);
    const flowDocuments$ = this.flowService.getDocList();

    this.flowService
      .getSummary()
      .pipe(
        takeUntil(this.destroy$),
        switchMap((summary) => forkJoin([of(summary), submitOnComplete$, flowDocuments$])),
      )
      .subscribe((response) => {
        this.responseService.isResponseSubmitted.next(true);
        this.submitted = true;
        this.summary = response[0];
        this.docList = response[2];
      });
  }

  showSubmittingCollaborationResponseModal(): void {
    if (this.collaborationMode) {
      const submitCollaborationResponseOptions: NgbModalOptions = {
        animation: false,
        backdrop: 'static',
        size: ModalWidth.default,
        windowClass: ModalVerticalSizeWindowClass.auto,
      };
      const modalRef = this.modalService.open(SubmitCollaborationResponseModalComponent, submitCollaborationResponseOptions);
      modalRef.result.then(
        (result) => {
          if (result === ModalResult.submit) {
            this.initSubmitResponse();
            return;
          } else {
            return;
          }
        },
        () => {},
      );
    }
  }

  onDone(): void {
    if (this.flowType === FlowType.response) {
      if (window.opener) {
        window.close();
      } else {
        window.open('/', '_self', '');
      }
    }
  }
}
