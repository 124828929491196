import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EyRadioGroupOptionsPosition } from '../ey-radio-group-plain/ey-radio-group-plain.component';
import { MappingField } from 'src/app/modules/version/version-mapping-data.model';

export interface ConditionGroupOperatorOption {
  id: string;
  text: string;
}

@Component({
  selector: 'ey-condition-group',
  templateUrl: './ey-condition-group.component.html',
  styleUrls: ['./ey-condition-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyConditionGroupComponent),
      multi: true,
    },
  ],
})
export class EyConditionGroupComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() sourceValues: MappingField[];
  @Input() initWithEmptyGroup = true;
  @Input() showDeleteConditionOptionOnLastCondition = false;

  optionPosition = EyRadioGroupOptionsPosition.right;
  options: ConditionGroupOperatorOption[] = [
    {
      id: '1',
      text: 'AND',
    },
    {
      id: '2',
      text: 'OR',
    },
  ];

  selectedOption: string = this.options[0].id;

  conditionGroup = this.fb.group({
    criterias: this.fb.array([]),
  });

  destroy$ = new Subject<boolean>();
  conditionGroupSub: Subscription;

  constructor(private fb: UntypedFormBuilder) {}

  onChange: (val: any) => void = () => {};
  onTouched: () => void = () => {};

  ngOnInit(): void {}

  showDeleteConditionOption(): boolean {
    if (this.showDeleteConditionOptionOnLastCondition) {
      return true;
    } else {
      return this.criterias?.controls?.length > 1;
    }
  }

  subscriveToValueChanges(): void {
    this.conditionGroupSub = this.conditionGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => this.onChange(this.valToConditionGroup(val)));
  }

  valToConditionGroup(val: any): any {
    return {
      criteria: val.criterias.map((c) => ({ ...c.criteria, isVisible: c.visible })),
      operator: this.options.find((o) => o.id === this.selectedOption).text,
    };
  }

  checkConditionChange(): void {
    this.onChange(this.valToConditionGroup(this.conditionGroup.value));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  writeValue(value: any): void {
    this.conditionGroupSub?.unsubscribe();
    this.criterias.clear();

    if (value && value.criteria?.length > 0) {
      value.criteria?.forEach((c) =>
        this.addCondition({
          criteria: [c],
          visible: [c.isVisible],
        }),
      );
      this.selectedOption = this.options.find((o) => o.text === value.operator)?.id;
    } else if (this.initWithEmptyGroup) {
      this.addCondition();
    }

    this.subscriveToValueChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  get criterias(): UntypedFormArray {
    return this.conditionGroup.controls.criterias as UntypedFormArray;
  }

  addCondition(config?: { [key: string]: any }): void {
    this.criterias.push(
      this.fb.group(
        config || {
          criteria: [null],
          visible: [true],
        },
      ),
    );
  }

  toggleConditionPanel(group: UntypedFormGroup): void {
    group.patchValue({ visible: !this.isVisible(group) });
  }

  isVisible(group: UntypedFormGroup): boolean {
    return group.controls.visible.value;
  }

  removeConditionBlock(i: number): void {
    this.criterias.removeAt(i);
  }
}
