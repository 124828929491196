import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { EyBaseFormControlComponent } from '../ey-base-form-control/ey-base-form-control';
import { ControlContainer, ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as _ from 'lodash';

export interface CheckBoxInGroupVal {
  id: string;
  text?: string;
  hint?: string;
}

@Component({
  selector: 'ey-checkbox-group-plain',
  templateUrl: './ey-checkbox-group-plain.component.html',
  styleUrls: ['./ey-checkbox-group-plain.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyCheckboxGroupPlainComponent),
      multi: true,
    },
  ],
})
export class EyCheckboxGroupPlainComponent extends EyBaseFormControlComponent implements ControlValueAccessor, OnInit {
  @Input() values: CheckBoxInGroupVal[] = [];
  ///array of id's
  @Input() restrictedCombinations: string[][] = [];
  @Output() blur = new EventEmitter<any>();
  _selectedValue: CheckBoxInGroupVal[] = [];
  constructor(
    private eRef: ElementRef,
    private controlContainer: ControlContainer,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(controlContainer);
  }

  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  onBlur(): void {
    this.onTouched(this._selectedValue);
    this.onChange(this._selectedValue);
    this.blur.emit();
  }
  ngOnInit(): void {}
  set selectedValue(val: CheckBoxInGroupVal[]) {
    this._selectedValue = val;
    this.onBlur();
  }

  get selectedValue(): CheckBoxInGroupVal[] {
    return this._selectedValue;
  }

  writeValue(initValue: CheckBoxInGroupVal[]): void {
    this._selectedValue = initValue || [];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelectionChange(val: any): void {
    this.selectedValue = val;
  }

  isSelected(val: any): boolean {
    //return JSON.stringify(this._selectedValue) === JSON.stringify(val);
    return this._selectedValue.findIndex((sv) => sv.id === val.id) !== -1;
  }
  onCheckboxChange(val: CheckBoxInGroupVal): void {
    let newVal: CheckBoxInGroupVal[];

    if (this._selectedValue.findIndex((v) => v.id === val.id) === -1) {
      newVal = [...this._selectedValue, val];
    } else {
      newVal = this._selectedValue.filter((v) => v.id !== val.id);
    }
    this.selectedValue = newVal;
  }
  isChecked(val: any): boolean {
    return val.checked;
    return this._selectedValue.findIndex((v) => v === val.id) !== -1;
  }

  isOptionDisabled(val: CheckBoxInGroupVal): boolean {
    if (this.restrictedCombinations == null) {
      return false;
    }

    const retVal = this.restrictedCombinations.findIndex((c) => this.disableBasedOnRestriction(val.id, c)) !== -1;
    return retVal;
  }

  disableBasedOnRestriction(id: string, cmbIds: string[]): boolean {
    // is selected
    if (this._selectedValue.findIndex((v) => v.id === id) !== -1) {
      return false;
    }

    const selectedValPlusId = [...this._selectedValue.map((v) => v.id), id];
    return _.intersection(selectedValPlusId, cmbIds).length === cmbIds.length;
  }
}
