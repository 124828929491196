<div *ngIf="isHidden" class="d-flex align-items-center automation-warning p-10 mt-10 mb-10">
  <i class="icon-ico_eye_off automated-icon top-message me-2"></i>
  This page was hidden from end-users.
</div>
<form class="table--holder">
  <table class="fonts-col__body-copy">
    <tr *ngFor="let row of children; index as rowIndex">
      <td
        *ngFor="let cell of row; index as colIndex"
        [ngClass]="isError(cell.formattedControlContent) ? ['table--cell-error'] : cell.style"
        [attr.title]="isError(cell.formattedControlContent) ? 'Result of formula can\'t be calculated' : null"
      >
        <span [ngSwitch]="cell.type">
          <span class="p-10" [innerHTML]="cell.formattedControlContent"></span>
        </span>
      </td>
    </tr>
  </table>
</form>
