import { Component, OnInit } from '@angular/core';
import { SummarySectionBaseComponent } from '../summary-section-base.component';

@Component({
  selector: 'app-yes-no-summary',
  templateUrl: './yes-no-summary.component.html',
  styleUrls: ['./yes-no-summary.component.scss'],
})
export class YesNoSummaryComponent extends SummarySectionBaseComponent implements OnInit {
  selectedYes = false;
  selectedNo = false;
  body: string;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.body = this.page?.moduleFlowPage.body;
    this.selectedNo = this.page?.moduleFlowPage?.properties[0].no.targetId === this.page?.moduleFlowPage.properties[0].value;
    this.selectedYes = this.page?.moduleFlowPage?.properties[0].yes.targetId === this.page?.moduleFlowPage.properties[0].value;
  }
}
