<span class="d-flex w-100 fonts-col__body-copy search tag_holder" [ngClass]="{ readonly: readonly }">
  <span
    class="input_span flex-grow-1 fonts-col__body-copy single-line"
    #spanInput
    (focusout)="onFocusOut()"
    role="textbox"
    (keyup)="onFormulaChange()"
    (keydown)="onKeyPress($event)"
    [attr.contenteditable]="!readonly"
    [innerHTML]="writeValContent"
    (paste)="onPaste($event)"
  ></span>

  <span class="insert-map-field" *ngIf="showTagInsert && isFormula()">
    <i class="emp-icon icon-data_mapping" (click)="onAddTagClick()"></i>
    <a class="pe-20 insert-field" role="button" (click)="onAddTagClick()" title="Use mapped fields from other Tools on Table calculations"
      >Insert Field</a
    >
  </span>
  <div *ngIf="values && values.length > 0" class="search__result">
    <div class="search__result__people max-dd-height">
      <a *ngFor="let v of values" (click)="addTagFromDropDown(v); $event.preventDefault()" href class="search__result__item">
        <div>
          <div class="title text-transform-none mb-2">{{ v[labelName] }}</div>
          <div *ngIf="labelName2 && labelName2.length > 0" class="desc text-transform-none">{{ v[labelName2] }}</div>
        </div>
      </a>
    </div>
  </div>
</span>
<div *ngIf="showAttachmentInfoMessage && removeOnly" class="eyfontlight mb-2 mt-20 d-flex">
  <i class="emp-icon icon-ico_info_toast"></i
  ><span class="info-text mt-1">Email generated will contain hyperlink to attachments that can only be accessed by Project team members</span>
</div>

<div class="error_holder">
  <label *ngIf="isOverCharLimit()" class="textinput-group__errmsg custom_error"> Please enter a valid subject(max {{ charLimit }} characters)</label>
</div>
