import { AfterContentChecked, ContentChildren, Directive, Input, QueryList, TemplateRef } from '@angular/core';
let nextId = 0;

/**
 * A directive to wrap tab titles that need to contain HTML markup or other directives.
 */
@Directive({ selector: 'ng-template[eyTabTitle]' })
export class EyTabTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * A directive to wrap content to be displayed in a tab.
 */
@Directive({ selector: 'ng-template[eyTabContent]' })
export class EyTabContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * A directive representing an individual tab.
 */
@Directive({ selector: 'ey-tab' })
export class EyTabDirective implements AfterContentChecked {
  /**
   * The tab identifier.
   * Must be unique for the entire document for proper accessibility support.
   */
  @Input() id = `ngb-tab-${nextId++}`;

  /**
   * The tab title.
   */
  @Input() title: string;

  /**
   * If `true`, the current tab is disabled and can't be toggled.
   */
  @Input() disabled = false;

  titleTpl: EyTabTitleDirective | null;
  contentTpl: EyTabContentDirective | null;

  @ContentChildren(EyTabTitleDirective, { descendants: false }) titleTpls: QueryList<EyTabTitleDirective>;
  @ContentChildren(EyTabContentDirective, { descendants: false }) contentTpls: QueryList<EyTabContentDirective>;

  ngAfterContentChecked(): void {
    this.titleTpl = this.titleTpls.first;
    this.contentTpl = this.contentTpls.first;
  }
}
