<div
  class="flex-fill overflow-x-auto"
  [class]="{
    mobile: selectedViewType === viewTypes.mobile,
    tablet: selectedViewType === viewTypes.tablet,
    'd-flex': selectedViewType === viewTypes.desktop,
  }"
>
  <div #templateContainer class="template-container responsive-padding d-flex flex-fill flex-column overflow-x-auto" [class]="{ hint: hintPresent }">
    <div>
      <ng-content select="[appPageContent]"></ng-content>
    </div>
    <div *ngIf="pageFooterVisible" class="py-20">
      <ng-content select="[appPageFooter]"></ng-content>
    </div>
  </div>
</div>
