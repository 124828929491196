import { Component, EventEmitter, forwardRef, Input, OnInit, Optional, Output } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EyBaseFormControlComponent } from '../ey-base-form-control/ey-base-form-control';
import { NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ey-date-picker',
  templateUrl: './ey-date-picker.component.html',
  styleUrls: ['./ey-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyDatePickerComponent),
      multi: true,
    },
  ],
})
export class EyDatePickerComponent extends EyBaseFormControlComponent implements ControlValueAccessor {
  _selectedValue: NgbDateStruct;
  @Input() showToolTipHolder = true;
  @Input() placeholder = 'yyyy-mm-dd';
  @Input() darkMode = false;
  isPickerOpen = false;
  constructor(@Optional() controlContainer: ControlContainer) {
    super(controlContainer);
  }

  @Output() blur = new EventEmitter<void>();

  onChange = (value: NgbDateStruct) => {};
  onTouched = (value: NgbDateStruct) => {};

  onBlur(): void {
    this.onTouched(this.selectedValue);
    this.blur.emit();
  }

  onInput(): void {
    this.onChange(this.selectedValue);
  }

  onDateSelect(va: any): void {
    this.selectedValue = va;
    this.onChange(va);
    this.onBlur();
  }

  set selectedValue(val) {
    this._selectedValue = val;
  }

  get selectedValue(): any {
    return this._selectedValue;
  }

  writeValue(initValue: any): void {
    this._selectedValue = initValue || [];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  togglePicker(d: any): void {
    this.isPickerOpen = !this.isPickerOpen;
    d.toggle();
  }
}
