import { MgrProjectTabs, PROJECT_TAB_QUERY_PARAM_NAME } from '../manage-project/manage-project.component';
import { Injectable, OnDestroy } from '@angular/core';
import { DEF_TEXTS, EyInfoDialogService } from '../../shared/components/ey-info-dialog/ey-info-dialog.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { InfoDialogTypes } from '../../shared/components/ey-info-dialog/info-dialog-types.enum';
import { ModalResult, ModalVerticalSizeWindowClass, ModalWidth } from '../../shared/components/ey-modal-template/ey-modal-result.enum';
import { BulkUploadModalComponent } from './bulk-upload-modal.component';
import { BulkUploadStatus, IBulkUploadResponse } from './bulk-upload.model';
import { BulkUploadService } from './bulk-upload.service';
import { Subject } from 'rxjs/internal/Subject';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';

export const UPLOADS_QTY_TOKEN = '#UPLOADS_QTY';
export const PROJ_ID_TOKEN = '#PROJID';
export const PARENT_TAB_ID_TOKEN = '#PARENT_TAB_ID_TOKEN';
export const DRAFT_RESPONSES_HTML = `Draft response are not allowed in Project settings because of that, you can't upload bulk responses.`;
export const BULK_UPLOAD_TITLE = 'Bulk Upload Responses';
export const BULK_UPLOAD_COMPLETED_TITLE = 'Upload Your Data';
export const EXISTING_UPLOADS_HTML = `There are ${UPLOADS_QTY_TOKEN} existing Uploads for this project. <br /> You can review those in <a href="/projects/manage/${PROJ_ID_TOKEN}?${PROJECT_TAB_QUERY_PARAM_NAME}=${MgrProjectTabs.BulkUploadResponses}&patid=${PARENT_TAB_ID_TOKEN}">Project Details/Bulk Upload Responses tab</a>`;
export const UPLOAD_COMPLETED_HTML = ` Your upload is in progress. You can find and manage all uploaded responses in <a href="/projects/manage/${PROJ_ID_TOKEN}?${PROJECT_TAB_QUERY_PARAM_NAME}=${MgrProjectTabs.BulkUploadResponses}&patid=${PARENT_TAB_ID_TOKEN}">Project Details/Bulk Upload Responses tab</a>`;

@Injectable({
  providedIn: 'root',
})
export class BulkUploadModalService implements OnDestroy {
  checkForDataMappingErrors = new Subject<boolean>();
  dataPurpose = new Subject<any>();
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private dialogService: EyInfoDialogService,
    private modalService: NgbModal,
    private router: Router,
    private bulkUploadService: BulkUploadService,
    private spinnerService: EyAppSpinnerService,
  ) {}

  start(projectId: string, parenTabId: number, projName: string, skipUploadExistWarning: boolean = false): void {
    this.spinnerService.withLoadingIndicator(this.bulkUploadService.getBulkUploadStatus(projectId), this.destroy$).subscribe((res) => {
      switch (res.status) {
        case BulkUploadStatus.DraftNotAllowed:
          this.openDraftProjectInfoDialog();
          break;
        case BulkUploadStatus.UploadFilesExist:
          if (skipUploadExistWarning) {
            this.startUploadProcess(projectId, projName, res, parenTabId);
          } else {
            this.openExistingUploadsInfoDialog(res.uploadedResponsesCount, projectId, parenTabId, projName, res);
          }
          break;
        default:
          this.startUploadProcess(projectId, projName, res, parenTabId);
      }
    });
  }

  openDraftProjectInfoDialog(): void {
    this.dialogService.openInfoDialog(DRAFT_RESPONSES_HTML, BULK_UPLOAD_TITLE, InfoDialogTypes.dismiss);
  }

  openExistingUploadsInfoDialog(
    uploadedFilesQty: number,
    projId: string,
    parenTabId: number,
    projName: string,
    bulkUploadResponse: IBulkUploadResponse,
  ): void {
    const dialogBody = EXISTING_UPLOADS_HTML.replace(UPLOADS_QTY_TOKEN, uploadedFilesQty.toString())
      .replace(PROJ_ID_TOKEN, projId)
      .replace(PARENT_TAB_ID_TOKEN, parenTabId.toString());

    const defText = { ...DEF_TEXTS, yesText: 'Start New Upload', noText: 'Cancel' };

    this.dialogService.openInfoDialog(dialogBody, BULK_UPLOAD_TITLE, InfoDialogTypes.yesNo, defText).subscribe((mr) => {
      if (mr === ModalResult.submit) {
        this.startUploadProcess(projId, projName, bulkUploadResponse, parenTabId);
      }
    });
  }

  openBulkUploadCompletedInfoDialog(projId: string, parenTabId: string): void {
    const dialogBody = UPLOAD_COMPLETED_HTML.replace(PROJ_ID_TOKEN, projId).replace(PARENT_TAB_ID_TOKEN, parenTabId);

    const defText = { ...DEF_TEXTS, yesText: 'See Your Upload', noText: 'Cancel' };

    this.dialogService.openInfoDialog(dialogBody, BULK_UPLOAD_COMPLETED_TITLE, InfoDialogTypes.yesNo, defText).subscribe((mr) => {
      if (mr === ModalResult.submit) {
        this.router.navigate(['projects', 'manage', projId], { queryParams: { activeTab: MgrProjectTabs.BulkUploadResponses } });
      }
    });
  }

  startUploadProcess(projectId: string, projectName: string, bulkUploadResponses: IBulkUploadResponse, parentTabId): void {
    const modalOptions: NgbModalOptions = {
      animation: false,
      backdrop: 'static',
      size: ModalWidth.Large,
      windowClass: ModalVerticalSizeWindowClass.contentHeavyDoubleHeader,
    };

    const modalRef = this.modalService.open(BulkUploadModalComponent, modalOptions);
    modalRef.componentInstance.projectID = projectId;
    modalRef.componentInstance.projectName = projectName;
    modalRef.componentInstance.isCompleteResponseAllowed = bulkUploadResponses.isCompleteResponseAllowed;
    modalRef.componentInstance.isPartialResponseAllowed = bulkUploadResponses.isPartialResponseAllowed;

    modalRef.result.then(
      (result) => {
        if (result === ModalResult.dismiss) {
          return;
        }
        this.bulkUploadService.postBulkUpload(result, projectId).subscribe(() => {
          this.openBulkUploadCompletedInfoDialog(projectId, parentTabId);
        });
      },
      () => {},
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
