import { Component, OnInit } from '@angular/core';
import { SummarySectionBaseComponent } from '../summary-section-base.component';

@Component({
  selector: 'app-end-summary',
  templateUrl: './end-summary.component.html',
  styleUrls: ['./end-summary.component.scss'],
})
export class EndSummaryComponent extends SummarySectionBaseComponent implements OnInit {
  body: string;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.body = this.page?.moduleFlowPage.body;
  }
}
