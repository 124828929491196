import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IRowExpandStatus {
  index: number;
  visible: boolean;
}

@Component({
  selector: 'ey-row-expand',
  templateUrl: './ey-row-expand.component.html',
  styleUrls: ['./ey-row-expand.component.scss'],
})
export class EyRowExpandComponent implements OnInit {
  @Input() index = 0;
  expanded = false;
  @Input() toolTipText: string;
  @Output() updateExpandStatus: EventEmitter<IRowExpandStatus> = new EventEmitter<IRowExpandStatus>();

  constructor() {}

  ngOnInit(): void {}

  toggle(): void {
    this.expanded = !this.expanded;
    this.updateExpandStatus.emit({ index: this.index, visible: this.expanded });
  }
  collapse(): void {
    this.expanded = false;
  }
}
