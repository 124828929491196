import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalResult } from '../../../../../shared/components/ey-modal-template/ey-modal-result.enum';

@Component({
  selector: 'app-table-modal',
  templateUrl: './table-modal.component.html',
  styleUrls: ['./table-modal.component.scss'],
})
export class TableModalComponent implements OnInit {
  @Input() page;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  close(): void {
    this.activeModal.close(ModalResult.dismiss);
  }
}
