<span class="d-flex" style="width: 600px">
  <span *ngFor="let part of selectedValue; index as i" class="d-flex" [class]="{ 'flex-grow-1': selectedValue.length - 1 === i }">
    <!--input [style]="{width: ((test.value.length)) + 'ch'}"
           #test  (focusout)="onFocusOut(test, i)"
           *ngIf="part.type === tagEditorPartType.text"
           type="text"
           [class]="{'flex-grow-1': selectedValue.length -1  === i}"
           [(ngModel)]="part.content"
           [ngModelOptions]="{isStandalone: true}"/-->
    <span
      class="input_span"
      #spanInput
      (focusout)="onFocusOut(spanInput, i)"
      (keydown)="onKeyPress(spanInput, i, $event)"
      *ngIf="part.type === tagEditorPartType.text"
      [class]="{ 'flex-grow-1': selectedValue.length - 1 === i }"
      role="textbox"
      [textContent]="part.content"
      (input)="part.content = $event.target.textContent"
      [attr.partId]="part.id"
      contenteditable
    >
      <ey-pill [standard]="false" [pillType]="pillType">{{ part?.content }}</ey-pill>
    </span>
    <ey-pill *ngIf="part.type === tagEditorPartType.tag" [standard]="false" [pillType]="pillType">{{ part?.content.name }}</ey-pill>
  </span>
  <span class=""><a role="button" (click)="addTag()">Insert Field</a></span>
</span>
