<form [formGroup]="form">
  <ey-drop-down
    [noInfo]="true"
    class="d-block mb-20"
    labelName="name"
    [values]="mapFields"
    formControlName="source"
    [meta]="meta.source"
  ></ey-drop-down>
</form>
<app-number *ngIf="isNumber" [(ngModel)]="numberFormatting" [showFormulaSection]="false" (ngModelChange)="triggerUpdate()"></app-number>
<app-date *ngIf="isDate" [(ngModel)]="dateFormatting" [showFormulaSection]="false" (ngModelChange)="triggerUpdate()"></app-date>
