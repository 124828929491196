import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// regex to supprot arabic and japanese digits
const VALID_NUMBER_REGEXP = /^(-|ー)?([0-9]|[０-９])*((\.|\．)([0-9]|[０-９]){0,100})?$/;

export function numberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isNumberValid = VALID_NUMBER_REGEXP.test(control.value);
    if (isNumberValid) {
      return null;
    }
    return { notValid: true };
  };
}
