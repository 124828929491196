import { Component, OnInit } from '@angular/core';
import { SummarySectionBaseComponent } from '../summary-section-base.component';

@Component({
  selector: 'app-start-summary',
  templateUrl: './start-summary.component.html',
  styleUrls: ['./start-summary.component.scss'],
})
export class StartSummaryComponent extends SummarySectionBaseComponent implements OnInit {
  body: string;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.body = this.page?.moduleFlowPage.body;
  }
}
