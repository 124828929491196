import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalResult } from '../../../shared/components/ey-modal-template/ey-modal-result.enum';
import { UserService } from 'src/app/core/services/user-service';
import { CollaborationModeResponsesService } from '../../../core/services/collaboration-mode-responses.service';
import { forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-collaboration-mode-start-message-modal',
  templateUrl: './collaboration-mode-start-message-modal.component.html',
  styleUrls: ['./collaboration-mode-start-message-modal.component.scss'],
})
export class CollaborationModeStartMessageModalComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() taskId: string;
  @Input() responseId: string;
  isInitialized = false;
  isLockedByAnotherUser = false;
  userAlreadyHasEdited: string = null;
  lockedBy: string = null;

  constructor(
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private collaborationModeService: CollaborationModeResponsesService,
  ) {}

  ngOnInit(): void {
    const getInitData$ = forkJoin([
      this.userService.getCurrentUser(),
      this.collaborationModeService.getResponseLastModifiedBy(this.taskId, this.responseId),
      this.collaborationModeService.getResponseLockInfo(this.taskId, this.responseId),
    ]);
    getInitData$.pipe(takeUntil(this.destroy$)).subscribe({
      next: ([currentUser, lastModifiedBy, responseLockInfo]) => {
        if (lastModifiedBy && lastModifiedBy.id !== currentUser.id) {
          this.userAlreadyHasEdited = lastModifiedBy.name;
        }

        this.isLockedByAnotherUser = responseLockInfo.isLockedByAnotherUser;
        this.lockedBy = responseLockInfo.lockedBy;

        this.isInitialized = true;
      },
    });
  }

  onDismiss(): void {
    this.activeModal.close(ModalResult.dismiss);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
