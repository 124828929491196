import { Page, PageValidationResult } from './../../page.model';
import { ElementRef } from '@angular/core';
import { AfterViewInit, Component, DestroyRef, OnInit, ViewChild, inject, AfterContentChecked } from '@angular/core';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ButtonClass } from 'src/app/shared/components/ey-button/ey-button.component';
import { FlowType, ModuleFlowService } from '../../module-flow.service';
import { SaveNotificationServiceEndUserForm } from '../../save-notification-service-end-user-form.service';
import { BasePageContent } from '../base-page-content';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';
import { FileDescriptor, FileUploadResult, FileUploadService } from 'src/app/core/file-upload.service';
import { EyFileUploadComponent, FileListItem, FileUploadStatus } from 'src/app/shared/components/ey-file-upload/ey-file-upload.component';
import { ExtensionTypes, EyFileUploadService, FileTypes } from 'src/app/shared/components/ey-file-upload/ey-file-upload.service';
import { IUploadedFileItem } from 'src/app/designer/workflow-designer/specific-parts/specific-part';
import { PropertyInput } from '../../property-input.model';
import { IExcelAIPage } from '../../page.model';
import { saveAs as importedSaveAs } from 'file-saver';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { FileDownloadService } from 'src/app/core/services/file-download.service';
import { of } from 'rxjs';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IFieldMeta } from 'src/app/shared/components/ey-base-form-control/field-meta.model';

const HIDDEN_MESSAGE = 'This [EXCELAI] page will be hidden from end-users.';
const DRAG_AND_DROP_TITLE = 'Drag and drop excel file (xls, xlsx, xlsm, xlsb) or';
const DRAG_AND_DROP_SUB_TITLE = 'One file allowed.';
const UPLOADED_FILES_PROPERTY_NAME = 'UploadedFiles';
const SKIP_WARNINGS_CONSENT_PROPERTY_NAME = 'SkipWarningsConsent';
const WARNING_NOTE = 'Note: Overlooking current warning may lead to data inconsistenles in subsequent Pages.';
const WARNING_MESSAGE = 'Warning will be hidden from end-users. However errors will be shown.';

@Component({
  selector: 'app-excelai.d-flex.flex-fill',
  templateUrl: './excelai-page.component.html',
  styleUrls: ['./excelai-page.component.scss'],
})
export class ExcelAIPageComponent extends BasePageContent implements OnInit, AfterViewInit, AfterContentChecked {
  @ViewChild(EyFileUploadComponent) fileUploadCmp: EyFileUploadComponent;
  @ViewChild('fileTemplate') fileTemplate: ElementRef;
  destroyRef = inject(DestroyRef);
  btnClass = ButtonClass;
  warningNote = WARNING_NOTE;
  warningMessage = WARNING_MESSAGE;
  fileExtension = [FileTypes.excel, FileTypes.excel_addons];
  docType: ExtensionTypes = ExtensionTypes.excelAIFile;
  uploadedFiles: FileUploadResult[] = [];
  files: FileListItem[] = [];
  items: IUploadedFileItem[] = [];
  isRequired = false;

  hasError = false;
  throwError = false;

  dragAndDropTitle = DRAG_AND_DROP_TITLE;
  dragAndDropSubTitle = DRAG_AND_DROP_SUB_TITLE;

  flowPage: IExcelAIPage;

  hiddenMessage: string;

  checkCustomWidth: boolean;
  private _offsetWidth: number;

  excelAIPageErrors: string[] = [];
  excelAIpageWarnings: string[] = [];

  hasAnyErrors = false;
  hasAnyWarnings = false;
  hasOnlyWarnings = false;

  form: FormGroup<{
    warningAknowledgeCheckbox: FormControl<boolean>;
  }>;
  private warningAknowledgeCheckbox: FormControl<boolean>;
  warningAknowledgeCheckboxMeta: IFieldMeta = {
    title: 'I acknowledge all warnings and understand the implications of proceeding with the current data.',
    errorMsg: 'Please select to proceed',
    required: true,
    hideRequiredMarker: true,
  };

  get warningAknowledgeCheckboxControl(): AbstractControl {
    return this.form.get('warningAknowledgeCheckbox');
  }

  constructor(
    flowService: ModuleFlowService,
    protected spinnerService: EyAppSpinnerService,
    private eyFileUploadService: EyFileUploadService,
    private fileUploadService: FileUploadService,
    private datePipe: DatePipe,
    private saveNotification: SaveNotificationServiceEndUserForm,
    private fileDownloadService: FileDownloadService,
    private formBuilder: FormBuilder,
  ) {
    super(flowService, spinnerService);

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
    });
  }

  ngOnInit(): void {
    this.flowService.responseHeaderMobileViewChange.pipe(takeUntil(this.destroy$)).subscribe((mobileViewChange) => {
      this.isMobileBreakpoint = mobileViewChange;
    });

    // TODO SO: refactor this quick fix to a normal operation
    if (this.flowType === FlowType.response && this.saveNotification.saveCurrentProperties$.observers.length === 0) {
      this.saveNotification.saveCurrentProperties$
        .pipe(
          switchMap(() => this.flowService.saveCurrent(this.page.moduleFlowPage.id)),
          takeUntil(this.destroy$),
        )
        .subscribe((response) => {
          this.flowService.transformCurrentPageData(response);
          this.page = response;
        });
    }
    this.initPage();
  }

  initPage(): void {
    this.flowPage = this.page.moduleFlowPage as IExcelAIPage;
    this.hiddenMessage = HIDDEN_MESSAGE.replace('[EXCELAI]', this.flowPage.name);

    this.setExcelAIErrosAndWarnings(this.page.errors);

    this.warningAknowledgeCheckbox = this.formBuilder.control({
      value: true,
      disabled: !this.hasOnlyWarnings,
    });
    this.form = this.formBuilder.group({ warningAknowledgeCheckbox: this.warningAknowledgeCheckbox });

    const pageProps = this.page.moduleFlowPage?.properties as Array<any>;
    const itemsProp = pageProps.find((p) => p.name === UPLOADED_FILES_PROPERTY_NAME);
    this.items = itemsProp ? itemsProp?.uploadedFiles : [];
    if (this.items === null) {
      this.items = [];
    }

    if (Array.isArray(this.items)) {
      this.items.forEach((item) => {
        this.files.push({
          file: {
            name: item.name,
            lastModified: 0,
            size: 0,
            type: null,
            arrayBuffer: null,
            slice: null,
            stream: null,
            text: null,
            webkitRelativePath: '',
          },
          fileId: item.tempId,
          status: FileUploadStatus.uploaded,
          fileExtension: this.eyFileUploadService.getFileExtension(item.name, this.docType),
        });
        this.uploadedFiles.push({
          fileId: item.fileId,
          tempId: item.tempId,
          fileDescriptor: item.fileDescriptor,
          visible: true,
        });
      });
    }
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);
    this._offsetWidth = this.fileTemplate?.nativeElement?.parentElement?.offsetWidth;
  }

  ngAfterContentChecked(): void {
    this.checkCustomWidth = this._offsetWidth < 700 ? true : false;
  }

  onValueChange(): void {
    this.saveNotification.dispatchNotSaved();
    if (this.flowType === FlowType.response && this.isAutoSave) {
      this.saveNotification.saveCurrentProperties$.next();
    }
    this.warningAknowledgeCheckbox.reset();
    this.warningAknowledgeCheckbox.disable();
    this.warningAknowledgeCheckbox.clearValidators();
    this.warningAknowledgeCheckbox.updateValueAndValidity();
    this.cleanPageValidatiionResults();
  }

  getDescriptor(flowType: FlowType): string {
    return flowType === FlowType.response ? FileDescriptor.ExcelAIResponse : FileDescriptor.ExcelAIPreview;
  }

  upload(file: FileListItem): void {
    this.throwError = false;
    const fd = this.fileUploadService.createFormData(file.file, file.fileId, this.getDescriptor(this.flowType), this.page.sessionId);

    this.fileUploadService
      .postFormData(fd)
      .pipe(takeUntil(this.destroy$))
      .subscribe((fupResult) => {
        fupResult.visible = true;
        this.uploadedFiles.push(fupResult);
        this.fileUploadCmp.markFileAsUploaded(fupResult);
        this.items.push({
          fileId: fupResult.fileId,
          fileDescriptor: fupResult.fileDescriptor,
          name: file.file.name,
          type: file.file.type,
          tempId: fupResult.tempId,
        });
        this.onValueChange();
      });
  }

  deleteFile(file: any): void {
    const deletedItem = this.uploadedFiles.find((f) => f.tempId === file.fileId);
    this.items = this.items.filter((f) => f.tempId !== file.fileId);
    this.uploadedFiles = this.uploadedFiles.filter((f) => f.tempId !== file.fileId);
    this.fileUploadCmp.delete(file);
    if (deletedItem) {
      this.fileUploadService
        .removeFile(deletedItem.fileId, this.getDescriptor(this.flowType))
        .pipe(takeUntil(this.destroy$))
        .subscribe((_) => {
          this.onValueChange();
        });
    }
  }

  downloadFile(file: FileListItem): void {
    const fileId = file.uploadResult?.fileId ?? file.fileId;
    const fileName = file.uploadResult?.fileName ?? file.file.name;
    const fileDescriptor = file.uploadResult?.fileDescriptor ?? this.getDescriptor(this.flowType);
    this.spinnerService
      .withLoadingIndicator(this.fileDownloadService.getFile(fileId, fileDescriptor), this.destroy$)
      .pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        importedSaveAs(x.body, fileName);
      });
  }

  downloadOutFile() {
    const pageWithFileId = this.flowPage.connectedInputPageId || this.flowPage.id;

    this.spinnerService
      .withLoadingIndicator(
        this.flowService.downloadPopulatedExcelFile(this.flowType, this.page.sessionId, this.flowPage.id, pageWithFileId),
        this.destroy$,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: HttpResponse<Blob>) => {
        const headers: HttpHeaders = response.headers;

        const contentDisposition = headers.get('content-disposition');

        let fileName = 'populated.xlsx';
        if (contentDisposition) {
          fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].replace(/"/g, '').trim();
          const currentDate = this.datePipe.transform(new Date(), 'ddMMyyyy');
          fileName = fileName.replace(/(.*)\./, `$1_${currentDate}.`);
        }

        importedSaveAs(response.body, fileName);
      });
  }

  getProperties(): Array<PropertyInput> {
    const props: Array<PropertyInput> = [{ name: UPLOADED_FILES_PROPERTY_NAME, value: this.items ? JSON.stringify(this.items) : null }];
    const skipWarningsConsent = !this.warningAknowledgeCheckbox.disabled && this.warningAknowledgeCheckbox.value;
    const skipWarningsConsentValue = this.flowType === FlowType.preview && skipWarningsConsent ? 'Y' : null;
    props.push({ name: SKIP_WARNINGS_CONSENT_PROPERTY_NAME, value: skipWarningsConsentValue });
    return props;
  }

  onPrev(): void {
    const loadingResult$ = this.spinnerService.withLoadingIndicator(this.flowService.doPrev(this.getProperties(), this.flowType), this.destroy$);
    loadingResult$.pipe(takeUntil(this.destroy$)).subscribe();
  }

  onNext(): void {
    this.warningAknowledgeCheckbox.setValidators(this.hasOnlyWarnings ? [Validators.requiredTrue] : []);
    this.warningAknowledgeCheckbox.updateValueAndValidity();
    if (this.warningAknowledgeCheckbox.invalid) {
      return;
    }

    if (this.page.moduleFlowPage.isUploadRequired && this.uploadedFiles.length === 0) {
      this.throwError = true;
      return;
    }

    const properties = this.getProperties();
    const request$ = this.flowService.doNext(properties, this.flowType, 300);

    const loadingResult$ = this.spinnerService.withLoadingIndicator(request$, this.destroy$);
    loadingResult$.pipe(takeUntil(this.destroy$)).subscribe();
  }

  setExcelAIErrosAndWarnings(pageValidationResults: PageValidationResult[]): void {
    if (!pageValidationResults) {
      return;
    }

    const currentPageId = this.page.moduleFlowPage.id;
    const excelAIInPageErrors = pageValidationResults
      .filter((r) => r.pageId === currentPageId)
      .reduce((acc, r) => acc.concat(r.propertyValidationErrors), [])
      .map((e) => e.message);
    const excelAIInPageWarnings = pageValidationResults
      .filter((r) => r.pageId === currentPageId)
      .reduce((acc, r) => acc.concat(r.propertyValidationWarnings), [])
      .map((e) => e.message);
    const excelAIOutPageErrors = pageValidationResults
      .filter((r) => r.pageId !== currentPageId)
      .reduce((acc, r) => acc.concat(r.propertyValidationErrors), [])
      .map((e) => e.message);
    const excelAIOutPageWarnings = pageValidationResults
      .filter((r) => r.pageId !== currentPageId)
      .reduce((acc, r) => acc.concat(r.propertyValidationWarnings), [])
      .map((e) => e.message);

    this.excelAIPageErrors = excelAIInPageErrors.concat(excelAIOutPageErrors);
    this.excelAIpageWarnings = excelAIInPageWarnings.concat(excelAIOutPageWarnings);

    this.hasAnyErrors = this.excelAIPageErrors.length > 0;
    this.hasAnyWarnings = this.excelAIpageWarnings.length > 0;
    this.hasOnlyWarnings = this.hasAnyWarnings && !this.hasAnyErrors;
  }

  private cleanPageValidatiionResults(): void {
    this.excelAIPageErrors = [];
    this.excelAIpageWarnings = [];
    this.hasAnyErrors = false;
    this.hasAnyWarnings = false;
    this.hasOnlyWarnings = false;
  }
}
