<app-sticky-footer-template
  [selectedViewType]="selectedViewType"
  class="d-flex flex-fill"
  [removePaddingBottomOnReviewDateDisplay]="lastTechReviewDate && !isMobileBreakpoint"
  *ngIf="!submitted"
>
  <ng-container appPageContent>
    <ey-froala-content-wrapper [editorContent]="body"></ey-froala-content-wrapper>
  </ng-container>
  <ng-container appPageFooter>
    <div>
      <div class="d-flex">
        <ey-button class="ms-auto me-20" [btnClass]="btnClass.btnPrev" text="Prev" (click)="onPrev()"></ey-button>
        <ey-button [disabled]="disableSubmitOnReview" class="m-0" [btnClass]="btnClass.primary" text="Submit" (click)="onSubmit()"></ey-button>
      </div>
      <div *ngIf="lastTechReviewDate && !isMobileBreakpoint">
        <span class="reviewDate spacing">Last Technical Review: {{ lastTechReviewDate }}</span>
      </div>
    </div>
  </ng-container>
</app-sticky-footer-template>
<app-sticky-footer-template [selectedViewType]="selectedViewType" class="d-flex flex-fill" *ngIf="submitted">
  <ng-container appPageContent>
    <app-response-summary [summary]="summary" [docList]="docList"></app-response-summary>
  </ng-container>
  <ng-container appPageFooter>
    <div class="d-flex justify-content-between">
      <div>
        <ey-button class="m-0" [btnClass]="btnClass.primary" text="Close" (click)="onDone()"></ey-button>
      </div>
      <div *ngIf="lastTechReviewDate && !isMobileBreakpoint" class="mt-2">
        <span class="reviewDate remove-position-from-top">Last Technical Review: {{ lastTechReviewDate }}</span>
      </div>
    </div>
  </ng-container>
</app-sticky-footer-template>
