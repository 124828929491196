import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ButtonClass } from 'src/app/shared/components/ey-button/ey-button.component';
import { FlowType, ModuleFlowService } from '../../module-flow.service';
import { BasePageContent } from '../base-page-content';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalVerticalSizeWindowClass, ModalWidth } from '../../../../shared/components/ey-modal-template/ey-modal-result.enum';
import { TableModalComponent } from './table-modal/table-modal.component';
import { SaveNotificationServiceEndUserForm } from '../../save-notification-service-end-user-form.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';

@Component({
  selector: 'app-table-page.d-flex.flex-fill.overflow-x-auto',
  templateUrl: './table-page.component.html',
  styleUrls: ['./table-page.component.scss'],
})
export class TablePageComponent extends BasePageContent implements OnInit, AfterViewInit {
  btnClass = ButtonClass;

  constructor(
    flowService: ModuleFlowService,
    protected spinnerService: EyAppSpinnerService,
    private modalService: NgbModal,
    private saveNotification: SaveNotificationServiceEndUserForm,
  ) {
    super(flowService, spinnerService);
  }

  ngOnInit(): void {
    this.flowService.responseHeaderMobileViewChange.pipe(takeUntil(this.destroy$)).subscribe((mobileViewChange) => {
      this.isMobileBreakpoint = mobileViewChange;
    });

    // TODO SO: refactor this quick fix to a normal operation
    if (this.flowType === FlowType.response && this.saveNotification.saveCurrentProperties$.observers.length === 0) {
      this.saveNotification.saveCurrentProperties$
        .pipe(
          switchMap(() => this.flowService.saveCurrent(this.page.moduleFlowPage.id)),
          takeUntil(this.destroy$),
        )
        .subscribe((response) => {
          this.flowService.transformCurrentPageData(response);
          this.page = response;
        });
    }
  }

  ngAfterViewInit(): void {
    if (!this.flowService.disableScrollToTopOnToolValueChanges) {
      window.scroll(0, 0);
    }
  }

  openModal(): void {
    const modalOptions: NgbModalOptions = {
      animation: false,
      backdrop: 'static',
      size: ModalWidth.fullscreen,
      windowClass: ModalVerticalSizeWindowClass.contentHeavy,
    };

    const modal = this.modalService.open(TableModalComponent, modalOptions);
    modal.componentInstance.page = this.page;
  }
}
