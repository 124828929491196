import { Component, ElementRef } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { delay } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'ey-form-base',
  template: '',
})
export class EyFormBaseComponent {
  focusOnError(form: UntypedFormGroup, elRef: ElementRef): void {
    for (const key of Object.keys(form.controls)) {
      if (form.controls[key].invalid) {
        const invalidControl = elRef.nativeElement.querySelector('[formcontrolname="' + key + '"]  .focusable');
        invalidControl.focus();
        return;
      }
    }
  }

  getFormValidationErrorsDIA(form: UntypedFormGroup): void {
    Object.keys(form.controls).forEach((key) => {
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          // NOTE: This was for debugging but lets not remove it. Commenting it for now.
          // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  constructor() {}

  setFocusFirstFormElement(form: UntypedFormGroup, elRef: ElementRef): void {
    for (const key of Object.keys(form.controls)) {
      const firstControl = elRef.nativeElement.querySelector('[formcontrolname="' + key + '"]  .focusable');
      of(firstControl)
        .pipe(delay(100))
        .subscribe((fc) => {
          if (fc) {
            fc.focus();
            form.markAsPristine();
            form.markAsUntouched();
          }
        });
      return;
    }
  }
}
