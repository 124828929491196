import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { EyInfoDialogService } from '../../shared/components/ey-info-dialog/ey-info-dialog.service';
import { environment } from '../../../environments/environment';
import { InfoDialogTypes } from '../../shared/components/ey-info-dialog/info-dialog-types.enum';
import { AppInsightsService } from './appinsights.service';

export const ADDITIONAL_TEXT = '<br /><br /> Please open browser console take a screenshot and log as a bug.';
@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  private appInsightsService: AppInsightsService;

  constructor(
    private injector: Injector,
    private zone: NgZone,
  ) {
    super();
  }

  handleError(error: any): void {
    super.handleError(error);

    if (!this.appInsightsService) {
      this.appInsightsService = this.injector.get(AppInsightsService);
    }

    this.appInsightsService?.logException(error);

    this.zone.run(() => {
      if (environment?.showErrorDialog) {
        const infoDialogService = this.injector.get(EyInfoDialogService);
        infoDialogService.openInfoDialog(
          (error.message || 'Undefined client error') + ADDITIONAL_TEXT,
          error?.status || 'Undefined client error',
          InfoDialogTypes.dismiss,
        );
      }
    });
  }
}
