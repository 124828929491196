import { VersionStatus } from '../../designer/workflow-designer/actions/actions.meta';

export const SAVE_DISABLED_STATUSES = [
  VersionStatus.DraftInPublishing,
  VersionStatus.Test,
  VersionStatus.TestInPublishing,
  VersionStatus.Live,
  VersionStatus.DraftInReview,
  VersionStatus.DraftReviewed,
  VersionStatus.TestInReview,
  VersionStatus.TestReviewed,
  VersionStatus.TestInReview,
  VersionStatus.TestReviewed,
  VersionStatus.LiveInRenewal,
  VersionStatus.Expired,
  VersionStatus.ExpiredInRenewal,
];
