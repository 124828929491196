import { AfterViewChecked, Component, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EyFroalaContentWrapperComponent } from 'src/app/shared/components/ey-froala-content-wrapper/ey-froala-content-wrapper.component';
import { ViewTypes } from '../page.model';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
})
export class HintComponent implements AfterViewChecked {
  @Input() hintHtml: string;
  @Input() selectedViewType = ViewTypes.desktop;

  @ViewChild(EyFroalaContentWrapperComponent) wrapper: EyFroalaContentWrapperComponent;

  constructor(private sanitizer: DomSanitizer) {}

  ngAfterViewChecked(): void {
    this.wrapper?.resizeFroalaContent();
  }

  get hintSafeHtml(): SafeHtml {
    return this.hintHtml ? this.sanitizer.bypassSecurityTrustHtml(this.hintHtml) : null;
  }
}
