import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PrivacyNoticeService {
  private baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) {}

  getPrivacyNotice(): Observable<string> {
    return this.httpClient.get<{ name: string; text: string }>(`${this.baseUrl}/textcontent/data-privacy-notice`).pipe(map((p) => p.text));
  }

  updatePrivacyNotice(textContent: any): Observable<any> {
    return this.httpClient.patch<any>(`${this.baseUrl}/textcontent/update`, textContent);
  }

  acceptPrivacyNotice(): Observable<void> {
    return this.httpClient
      .patch(`${this.baseUrl}/user/me/privacy-notice-acceptance`, {
        IsPrivacyNoticeAccepted: true,
      })
      .pipe(switchMap(() => of(void 0)));
  }

  getDataModuleNotice(): Observable<string> {
    return this.httpClient.get<{ name: string; text: string }>(`${this.baseUrl}/textcontent/module-terms-and-conditions`).pipe(map((p) => p.text));
  }
}
