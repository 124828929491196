import { Injectable } from '@angular/core';
import { TableBuilderControlTypes } from '../../../../../builders/table-builder/controls/controls.meta';

export const DROP_DOWN_EMPTY = '- not selected -';
export const INPUT_EMPTY = '- not entered -';

@Injectable({
  providedIn: 'root',
})
export class TableSummaryService {
  constructor() {}

  emptyStateHandler(children: any): any {
    return children.map((ca) =>
      ca.map((c) => {
        switch (c.type) {
          case TableBuilderControlTypes.dropdown:
            const dropDownContent = c.formattedControlContent?.name ? c.formattedControlContent?.name : DROP_DOWN_EMPTY;
            return { ...c, formattedControlContent: dropDownContent };
            break;
          case TableBuilderControlTypes.numberInput:
            const numberInput = c.formattedControlContent && String(c.value).length > 0 ? c.formattedControlContent : INPUT_EMPTY;
            return { ...c, formattedControlContent: numberInput };
            break;
          case TableBuilderControlTypes.number:
            const numberType = c.formattedControlContent && String(c.value).length > 0 ? c.formattedControlContent : INPUT_EMPTY;
            return { ...c, formattedControlContent: numberType };
            break;
          case TableBuilderControlTypes.textInput:
            const content = c.formattedControlContent ? c.formattedControlContent : INPUT_EMPTY;
            return { ...c, formattedControlContent: content };
            break;
          default:
            return c;
        }
        return c;
      }),
    );
  }
}
