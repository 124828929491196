import { TableBuilderControlTypes } from './controls/controls.meta';
import { DATE_FORMATS, ERROR_DATE, MappingFieldTypes } from './controls/controls.const';
import { TableBuilderControl } from './table-builder.state';
import { NumberFormatService } from './table/number-format.service';
import { DatePipe } from '@angular/common';
import { isMatch, parse } from 'date-fns';
import { getTagHtmlfn, TagEditorPartType } from './formula-editor/ey-tag-editor-table-builder/ey-tag-editor-table-builder';
import { MappingField } from 'src/app/modules/version/version-mapping-data.model';
import { getDateFormatPattern } from 'src/app/shared/utils/date.functions';

export function getEmptyControl(initValue: string = ''): TableBuilderControl {
  return {
    controlId: '0',
    type: TableBuilderControlTypes.label,
    properties: null,
    value: initValue,
    style: [],
    name: '',
    formattedControlContent: '',
  };
}

export function isCalcFormula(value: string): TableBuilderControlTypes {
  try {
    if (value == null) {
      return null;
    }

    if (value.toLowerCase().startsWith('=dateadd') || value.toLowerCase().startsWith('=datesub')) {
      return TableBuilderControlTypes.date;
    }

    if (value[0] === '=') {
      return TableBuilderControlTypes.number;
    }
  } catch (ex) {
    console.log('is calc formula detection failed', ex, value);
  }

  return null;
}

export function formatCellContent(cell, numberFormatService: NumberFormatService, datePipe: DatePipe, formatMapField = false): any {
  let retVal: any;

  const isFormatableFormula =
    formatMapField &&
    (cell.type === TableBuilderControlTypes.number ||
      cell.type === TableBuilderControlTypes.numberInput ||
      cell.type === TableBuilderControlTypes.date);

  if (cell?.properties?.isFormula && !isFormatableFormula) {
    return cell.value;
  }

  try {
    if (formatMapField && cell.type === TableBuilderControlTypes.mapField) {
      cell.type = getMappingFieldCellType((cell.properties.source as MappingField).type as MappingFieldTypes);
    }
  } catch (ex) {
    console.log(ex, cell);
  }

  switch (cell.type) {
    case TableBuilderControlTypes.label:
      retVal = cell.value;
      break;
    case TableBuilderControlTypes.mapField:
      const cnt = { name: cell?.properties?.source?.name, id: 0, content: cell.formattedControlContent };
      if (cnt.name != null) {
        return getTagHtmlfn({ content: cnt, id: '0', type: TagEditorPartType.tag });
      } else {
        return '';
      }
    case TableBuilderControlTypes.number:
    case TableBuilderControlTypes.numberInput:
      retVal = numberFormatService.transform(cell.value, cell.type, cell.properties);
      break;
    case TableBuilderControlTypes.date:
      if (formatMapField) {
        retVal = getFormattedDateTablePreview(cell, datePipe);
      } else {
        retVal = getFormattedDate(cell, datePipe);
      }

      break;
    default:
      retVal = cell.value;
  }

  return retVal;
}
export function getMappingFieldCellType(type: MappingFieldTypes): TableBuilderControlTypes {
  switch (type) {
    case MappingFieldTypes.date:
      return TableBuilderControlTypes.date;
    case MappingFieldTypes.number:
      return TableBuilderControlTypes.number;
    default:
      return TableBuilderControlTypes.label;
  }
}
export function getFormattedDate(cell: any, datePipe: any): string {
  try {
    if (!cell.value) {
      return '';
    }

    if (isCalcFormula(cell.value) != null) {
      return cell.value;
    }

    const currentFormat = getDateFormatPattern('yyyy', 'MM', 'dd');
    if (!isMatch(cell.value, currentFormat)) {
      return ERROR_DATE;
    }

    const parsedDate = parse(cell.value, currentFormat, new Date());
    if (parsedDate > new Date(9999, 12, 31)) {
      return ERROR_DATE;
    }

    const transformedDate = datePipe.transform(
      parsedDate,
      cell?.properties?.dateFormat == null ? DATE_FORMATS[0].value : cell?.properties?.dateFormat,
    );
    return transformedDate;
  } catch (ex) {
    return '';
  }
}

export function getFormattedDateTablePreview(cell: any, datePipe: any): string {
  try {
    if (cell.value == null || cell.value === '' || cell.value === undefined) {
      return '';
    }

    if (isCalcFormula(cell.value) != null) {
      return cell.value;
    }

    if ('Invalid Date' === new Date(cell.value).toString()) {
      return ERROR_DATE;
    }
    if (new Date(cell.value) > new Date(9999, 12, 31)) {
      return ERROR_DATE;
    }
    return datePipe.transform(cell.value, cell?.properties?.dateFormat == null ? DATE_FORMATS[0].value : cell?.properties?.dateFormat);
  } catch (ex) {
    return '';
  }
}
