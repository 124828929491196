import { PillType } from '../../shared/components/ey-pill/ey-pill.component';

export const IN_PROGRESS_STATUS_ID = 1;
export const DRAFT_STATUS_ID = 2;
export const COMPLETED_STATUS_ID = 3;

export const RESPONSE_DUPLICATION_FORBIDDEN = 0;
export const RESPONSE_DUPLICATION_ALLOWED = 1;
export const RESPONSE_DUPLICATION_ALLOWED_FOR_TEAMMEMBER = 2;

export enum ResponseStatus {
  InProgress = 'In Progress',
  Draft = 'Draft',
  Completed = 'Completed',
  Unknown = 'Unknown',
}

export interface IResponseStatus {
  pillType: string;
  label: ResponseStatus;
  id: number;
}

export const UNKNOWN_RESPONSE_STATUS = { color: PillType.red, label: ResponseStatus.Unknown, id: 0 };

export const ResponseStatuses: IResponseStatus[] = [
  { pillType: PillType.draft, label: ResponseStatus.InProgress, id: IN_PROGRESS_STATUS_ID },
  { pillType: PillType.orange, label: ResponseStatus.Draft, id: DRAFT_STATUS_ID },
  { pillType: PillType.green, label: ResponseStatus.Completed, id: COMPLETED_STATUS_ID },
];
