import { createReducer, on } from '@ngrx/store';
import { addShapeToSelection, removeShapeFromSelection, clearSelection } from './actions';

export const selectedShapesReducers = createReducer(
  [],
  on(addShapeToSelection, (state, action) => (state.includes(action.id) ? state : [...state, action.id])),
  on(removeShapeFromSelection, (state, action) => {
    const index = state.findIndex((id) => id === action.id);

    const newState = [...state];
    newState.splice(index, 1);

    return newState;
  }),
  on(clearSelection, () => []),
);
