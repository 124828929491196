import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-unsaved-changes',
  templateUrl: './unsaved-changes.component.html',
  styleUrls: ['./unsaved-changes.component.scss'],
})
export class UnsavedChangesComponent {
  constructor(private activeModal: NgbActiveModal) {}

  onGoBack(): void {
    this.activeModal.close();
  }

  onCancel(): void {
    this.activeModal.dismiss();
  }
}
