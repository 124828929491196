<span class="d-flex w-100 fonts-col__body-copy" style="height: 100%">
  <span
    class="input_span flex-grow-1 fonts-col__body-copy single-line"
    #spanInput
    (focusout)="onFocusOut()"
    role="textbox"
    (keyup)="onFormulaChange($event)"
    (keydown)="onKeyPress($event)"
    [attr.contenteditable]="!readonly"
    [innerHTML]="writeValContent"
    (paste)="onPaste($event)"
  ></span>
  <span class="insert-map-field" *ngIf="showTagInsert && isFormula()">
    <i class="emp-icon icon-data_mapping" (click)="onAddTagClick()"></i>
    <a class="pe-20" role="button" (click)="onAddTagClick()" title="Use mapped fields from other Tools on Table calculations">Insert Field</a>
  </span>
</span>
