<div class="d-flex justify-content-start">
  <div class="d-inline-flex flex-column text-left">
    <div *ngFor="let val of values; index as i">
      <label class="checkbox mb-10" [ngClass]="{ 'checkbox--disabled': isOptionDisabled(val) }" [for]="'chkbox_' + ctrl_id + '_' + i">
        <input
          class="checkbox__input"
          type="checkbox"
          [class]="{ error: showError }"
          [id]="'chkbox_' + ctrl_id + '_' + i"
          [value]="val"
          (change)="onCheckboxChange(val)"
          [checked]="isSelected(val)"
          [disabled]="isOptionDisabled(val)"
        />
        <span class="checkbox__checkmark"></span>
        <p class="checkbox__label checkbox-question">{{ val.text }}</p>
        <p [innerHtml]="val.hint" class="ms-10 eyfontlight checkbox-hint mb-0"></p>
      </label>
    </div>
  </div>
</div>
<label *ngIf="showError" class="textinput_checkbox__errmsg mb-20">
  <i class="err_icon icon-ico_error_toast"></i>
  <span class="">{{ meta.errorMsg }}</span>
</label>
