import { Component, EventEmitter, forwardRef, Input, Optional, Output } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { EyBaseFormControlComponent } from '../ey-base-form-control/ey-base-form-control';
import { MODULE_NAME_MUST_BE_VALID } from '../../../modules/options/meta';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const DEFAULT_INPUT_TYPE = 'text';

@Component({
  selector: 'ey-input',
  templateUrl: './ey-input.component.html',
  styleUrls: ['./ey-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyInputComponent),
      multi: true,
    },
  ],
})
export class EyInputComponent extends EyBaseFormControlComponent implements ControlValueAccessor {
  @Input() type: string = DEFAULT_INPUT_TYPE;
  @Input() disableErrorEvaluation = false;
  @Input() readOnly = false;
  @Input() background: 'grey' | 'white' | null = 'grey';
  @Input() warning = false;
  @Input() extra: 'tooltip' | 'clipboard-copy' | 'none' = 'tooltip';
  @Input() showSearchIcon = false;
  @Input() maxlength = null;
  @Input() noErrorLabel = false;
  @Input() isDisabled = false;
  // tslint:disable-next-line: no-output-native
  @Output() blur = new EventEmitter<void>();
  @Output() focus = new EventEmitter<void>();
  @Input() list: string;
  @Input() changeErrorDisplayPositionForLongMessages = false;
  _selectedValue: any;

  constructor(@Optional() controlContainer: ControlContainer) {
    super(controlContainer);
  }

  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  onBlur(): void {
    this.onTouched(this.selectedValue);
    this.blur.emit();
  }

  onInput(): void {
    this.onChange(this.selectedValue);
  }

  set selectedValue(val) {
    this._selectedValue = val;
  }

  get selectedValue(): any {
    return this._selectedValue;
  }

  get isWhite(): boolean {
    return this.background === 'white';
  }

  writeValue(initValue: any): void {
    if (typeof initValue === 'number' && this.type === 'number') {
      this._selectedValue = initValue;
      return;
    }

    this._selectedValue = initValue || [];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
