<ey-modal-template>
  <div header>
    <h2 class="modal__title" i18n="List of Respondents Modal| Header Text">List of Respondents</h2>
  </div>
  <div body>
    <h4 class="fonts-col__subtitle-2 mb-3">User collaborating on this response</h4>
    <ey-grid
      [tableBordersNone]="true"
      [lightTable]="true"
      [getData]="getData()"
      [showSearch]="false"
      [useCustomFiltering]="false"
      [gridState]="state"
      [hasSubTable]="false"
      [enablePaging]="false"
    >
      <ng-container header>
        <tr>
          <th scope="col"><span class="eyfontbold">Respondent Name</span></th>
          <th scope="col"><span class="eyfontbold">Email address</span></th>
          <th scope="col"><span class="eyfontbold">Last collaboration action</span></th>
          <th scope="col"><span class="eyfontbold">Locked for editing?</span></th>
        </tr>
      </ng-container>
      <ng-template eyTemplate="body" let-data>
        <td>
          <span class="eyfontlight">{{ data?.user.name }}</span>
        </td>
        <td>
          <span class="eyfontlight">{{ data.user.email }}</span>
        </td>
        <td>
          <span *ngIf="data.dateModified; else noChange" class="eyfontlight">Saved changes on {{ data.dateModified | date: 'dd.MM.yyyy' }}</span>
        </td>
        <ng-template #noChange>
          <span class="eyfontlight">-</span>
        </ng-template>
        <td>
          <span *ngIf="data.isLocked; else unlockedResponse" class="eyfontlight locked"><strong class="locked">YES</strong></span>
        </td>
        <ng-template #unlockedResponse>
          <span class="eyfontlight">-</span>
        </ng-template>
      </ng-template>
    </ey-grid>
  </div>
  <div footer class="d-flex w-100">
    <div class="d-flex w-100 justify-content-center">
      <button class="btn me-20 btn--secondary" type="button" (click)="onDismiss()">Dismiss</button>
    </div>
  </div>
</ey-modal-template>
