<div class="navigation-bar-stack-container">
  <div class="page-header">
    <div class="header-group">
      <a aria-label="dashboard" href [routerLink]="['/']"
        ><img alt="EY Logo" class="header-group__image ms-20 my-10" src="../../assets/images/ey-logo.svg"
      /></a>
      <div class="header-group__labels-container">
        <span *ngIf="!isMobileBreakpoint" class="header-group__labels_custom">Empowerment</span>
        <span class="header-group__sub-label">Powered by Microsoft<sup>®</sup></span>
      </div>
    </div>
    <!-- div class="header-group__aux-responsive">
            <button aria-label="Options" class="header-group__show-options"></button>
            <button aria-label="Menu" class="header-group__show-menu"></button>
        </div -->
    <app-user-profile [isMobileBreakpoint]="isMobileBreakpoint" class="d-flex"></app-user-profile>
  </div>
</div>
