import { PillType } from '../../shared/components/ey-pill/ey-pill.component';
import { COMPLETED_RESPONSE_STATUS, DRAFT_RESPONSE_STATUS, INPROGRESS_RESPONSE_STATUS } from '../../projects/project.const';

export interface ResponseCard {
  createdBy: string;
  id: string;
  projectName: string;
  projectId: string;
  responseDate: string;
  responseId: string;
  respondentName: string;
  responseStatus: number;
  versionId: string;
  userPermissions: Array<string>;
  projectStatus: number;
  excelCount: number;
  wordCount: number;
  powerPointCount: number;
  uploadId?: string;
  uploadResponseId?: string;
  isCollaborationLocked?: boolean;
  isCurrentUserCanUnlock?: boolean;
  isRespondent?: boolean;
  isDuplicatable?: number;
}

export interface IResponseStatus {
  pillType: string;
  label: ResponseStatus;
  labelText?: string;
  toolTip: string;
  id: number;
}

export interface ICollaborationResponseStatus {
  pillType: string;
  label: CollaborationResponseStatus;
  labelText?: string;
  toolTip: string;
  isLocked?: boolean;
}

export enum ResponseStatus {
  InProgress = 'In Progress',
  Draft = 'Draft',
  Completed = 'Completed',
  Unknown = 'Unknown',
}

export enum CollaborationResponseStatus {
  Locked = 'Locked',
  Unlocked = 'Unlocked',
}

export const CollaborationResponseStatuses: ICollaborationResponseStatus[] = [
  {
    pillType: PillType.orange,
    label: CollaborationResponseStatus.Locked,
    labelText: 'Locked',
    toolTip: '',
    isLocked: true,
  },
  {
    pillType: PillType.green,
    label: CollaborationResponseStatus.Unlocked,
    labelText: 'Unlocked',
    toolTip: '',
    isLocked: false,
  },
];

export const ResponseStatuses: IResponseStatus[] = [
  {
    pillType: PillType.orange,
    label: ResponseStatus.Draft,
    labelText: 'Draft',
    toolTip: '',
    id: DRAFT_RESPONSE_STATUS,
  },
  {
    pillType: PillType.draft,
    label: ResponseStatus.InProgress,
    labelText: 'In Progress',
    toolTip: '',
    id: INPROGRESS_RESPONSE_STATUS,
  },
  {
    pillType: PillType.green,
    label: ResponseStatus.Completed,
    labelText: 'Completed',
    toolTip: '',
    id: COMPLETED_RESPONSE_STATUS,
  },
];

export enum TemplateFileTypes {
  Unknown = 0,
  Word = 1,
  Excel = 2,
  PowerPoint = 3,
}

export const ReadResponsesFiles = 'responsesfiles.read';
export const AllReadResponsesFiles = 'allresponsesfiles.read';
export const ExcelFileExtension = 'xlsx';
export const WordFileExtension = 'docx';
export const PptFileExtension = 'pptx';
