<label class="switch switch--round mb-0" [class.d-flex]="displayCustomLabels">
  <input
    [attr.aria-label]="ariaLabel"
    [id]="id"
    class="switch__input"
    [checked]="selectedValue"
    (change)="onChange()"
    type="checkbox"
    [attr.disabled]="isDisabled || null"
  />
  <span class="switch__slider"></span>

  <ng-container *ngIf="slim; then slimIndicator; else fullIndicator"></ng-container>
  <ng-template #slimIndicator>
    <span *ngIf="!selectedValue" aria-label="{{ labelOff }}" class="switch-label no--off" [class.margin-top-adjust]="displayCustomLabels">{{
      labelOff
    }}</span>
    <span *ngIf="selectedValue" aria-label="{{ labelOn }}" class="switch-label no--off" [class.margin-top-adjust]="displayCustomLabels">{{
      labelOn
    }}</span>
  </ng-template>
  <ng-template #fullIndicator>
    <span class="switch-label" aria-label="On">{{ labelOn }}</span>
  </ng-template>
</label>
