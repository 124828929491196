export enum ProgressStates {
  new,
  inProgress,
  completed,
}

export class ProgressBarStep {
  id: number;
  title: string;
  active: boolean;
  state: ProgressStates;
  data?: any;
  subTitle?: string;
  formName?: string;
  componentName?: string;
}
