import { Pipe, PipeTransform } from '@angular/core';
import { ActionTypes as VersionActionTypes } from '../../modules/version/version-action.dictionary';
import { ActionTypes } from '../../modules/module-actions.dictionary';
import {
  SHOW_CANVAS_FLOW_TEXT,
  SHOW_PREVIEW_TEXT,
  SHOW_REVIEW_TEXT,
  SHOW_VIEW_TEXT,
  UNKNOWN_ACTION_TYPE_TEXT,
} from '../../modules/grid-actions/grid-actions.component';

@Pipe({
  name: 'gridActionLabel',
})
export class GridActionLabelPipe implements PipeTransform {
  transform(action: string): string {
    switch (action) {
      case VersionActionTypes.ShowCanvasFlow:
        return SHOW_CANVAS_FLOW_TEXT;
      case VersionActionTypes.ShowPreview:
        return SHOW_PREVIEW_TEXT;
      case ActionTypes.view:
        return SHOW_VIEW_TEXT;
      case ActionTypes.review:
        return SHOW_REVIEW_TEXT;
      default:
        return UNKNOWN_ACTION_TYPE_TEXT;
    }
  }
}
