import { AfterViewChecked, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Page, ViewTypes } from '../page.model';
import { Subject } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { FlowType, ModuleFlowService } from '../module-flow.service';
import { EyFroalaContentWrapperComponent } from 'src/app/shared/components/ey-froala-content-wrapper/ey-froala-content-wrapper.component';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';
import { UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'ey-base-form-control',
  template: '',
})
export abstract class BasePageContent implements OnDestroy, AfterViewChecked {
  public destroy$ = new Subject<void>();
  @Input() lastTechReviewDate: string = null;
  @Input() isMobileBreakpoint = false;
  @Input() flowType: FlowType = FlowType.preview;
  @Input() isAutoSave = false;
  @ViewChild(EyFroalaContentWrapperComponent) wrapper: EyFroalaContentWrapperComponent;

  @Input() page: Page;
  private selectedViewTypeInternal = ViewTypes.desktop;

  @Input() set selectedViewType(val: ViewTypes) {
    this.selectedViewTypeInternal = val;
  }

  get selectedViewType(): ViewTypes {
    return this.selectedViewTypeInternal;
  }

  viewTypes = ViewTypes;

  get html(): string {
    return this.page?.moduleFlowPage.body;
  }

  get isHidden(): boolean {
    return !!this.page?.moduleFlowPage.isHidden;
  }

  constructor(
    protected flowService: ModuleFlowService,
    protected spinnerService: EyAppSpinnerService,
  ) {}

  ngAfterViewChecked(): void {
    this.wrapper?.resizeFroalaContent();
  }

  onNext(collaborationStart = false): void {
    this.flowService.disableScrollToTopOnToolValueChanges = false;
    const doNext$ = this.flowService.doNext(null, this.flowType).pipe(
      map((x) => {
        // BE starts collaboration in /next handler, need to wait until page loads to awoid race condition
        if (collaborationStart) {
          this.flowService.collaborationResponseStatusLocked.next(true);
          this.flowService.showLockedResponseMessageOnStartFlow();
        }
      }),
    );

    let destroy$ = this.destroy$;
    if (collaborationStart) {
      const delayedDestroy$ = new Subject<void>();
      this.destroy$.pipe(delay(1000)).subscribe(() => {
        delayedDestroy$.next();
      });

      destroy$ = delayedDestroy$;
    }

    const loadingResult$ = this.spinnerService.withLoadingIndicator(doNext$, destroy$);
    loadingResult$.subscribe();
  }

  onPrev(): void {
    this.flowService.disableScrollToTopOnToolValueChanges = false;
    const loadingResult$ = this.spinnerService.withLoadingIndicator(this.flowService.doPrev(null, this.flowType), this.destroy$);
    loadingResult$.subscribe();
  }

  onStart(collaborationStart = false): void {
    this.onNext(collaborationStart);
  }

  focusOnError(formArray: UntypedFormArray, domElRef: ElementRef): void {
    setTimeout(() => {
      for (const key of Object.keys(formArray.controls)) {
        if (formArray.controls[key].invalid) {
          const invalidControl = domElRef.nativeElement.querySelector('input.error, textarea.error');
          invalidControl.focus();
          return;
        }
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
