<div class="p-20 download-documents d-flex flex-fill flex-column">
  <h2>Download Documents</h2>

  <div *ngIf="docList.length < 2; else elseBlock">
    <p>There is one document for you to download with this response.</p>
  </div>
  <ng-template #elseBlock>
    <p>There are {{ docList.length }} documents for you to download with this response.</p>
  </ng-template>

  <div class="d-flex flex-column">
    <div *ngFor="let file of docList" class="d-flex flex-row file-list-item p-10">
      <div class="flex-grow-1">
        <label class="checkbox checkbox-margin mb-0">
          <input class="checkbox__input" type="checkbox" (click)="fileSelectionCounter(file.isSelected)" [(ngModel)]="file.isSelected" />
          <span class="checkbox__checkmark checkbox-background"></span>
          <p class="checkbox-question ms-20 mb-0">{{ file.name }}.{{ file.extension }}</p>
        </label>
      </div>
      <div><i (click)="downloadFile(file)" class="emp-icon icon-ico_download"></i></div>
    </div>
  </div>
  <ey-button
    class="mt-20"
    icon="icon-ico_download"
    text="Download Selected Documents"
    [disabled]="fileSelectCount === 0"
    [btnClass]="btnClass.primary"
    (click)="fileSelectCount === 0 ? $event.preventDefault() : downloadSelected()"
  ></ey-button>
</div>
