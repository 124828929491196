import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IFieldMeta } from '../../../shared/components/ey-base-form-control/field-meta.model';

@Component({
  selector: 'app-multiselect-filter',
  templateUrl: './multiselect-filter.component.html',
  styleUrls: ['./multiselect-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectFilterComponent),
      multi: true,
    },
  ],
})
export class MultiselectFilterComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() getValuesFn: (search: string) => Observable<any[]> = null;
  @Input() meta: IFieldMeta;

  showSpinner = false;
  selectedValues = [];
  values = [];

  private destroy$ = new Subject<void>();

  constructor() {}

  onChange: (val: any) => void = () => {};
  onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.selectedValues = val;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onModelChange(val: any[]): void {
    this.selectedValues = val;
    this.onChange(val);
    this.onTouched();
  }

  onSearch(searchText: string): void {
    if (searchText && searchText.length >= 2) {
      this.showSpinner = true;

      this.getValuesFn(searchText)
        .pipe(takeUntil(this.destroy$))
        .subscribe((val) => {
          this.values = val;
          this.showSpinner = false;
        });
    } else {
      this.selectedValues = [];
    }
  }
}
