import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NumberNormalizationService {
  normalizeNumberString(inputString: string) {
    const result = this.filterJapaneseToArabic(inputString);
    return result;
  }

  filterJapaneseToArabic(inputString: string): string {
    const japaneseDigits = 'ー．０１２３４５６７８９'.split('');
    const arabicDigits = '-.0123456789'.split('');
    const result = [];
    for (let i = 0; i < inputString.length; i++) {
      const index = japaneseDigits.indexOf(inputString[i]);
      if (index != -1) {
        result.push(arabicDigits[index]);
      } else {
        result.push(inputString[i]);
      }
    }
    return result.join('');
  }
}
