import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IFieldMeta } from '../ey-base-form-control/field-meta.model';
import {
  Condition,
  ConditionDropdownItem,
  Conditions,
  CONDITIONS_REQUIRING_THAN_TITLE,
  ConditionTarget,
  CONDITION_META,
  CONDITION_OPTIONS,
  CUSTOM_DATE,
  CUSTOM_NUMBER,
  CUSTOM_TEXT,
  CUSTOM_VALUE_META,
  TARGET_HIDDEN_CONDITIONS,
  ToolNames,
  IS_SELECTED,
} from './ey-condition.meta';
import { NgbDateCustomParserFormatter } from '../ey-date-picker/date.formatter';
import { manageConditionFieldsExceptions, manageTargetFieldsExceptions } from './fields-exceptions';
import { TABLE_TOOL } from '../../../designer/workflow-designer/constants';
import { MappingField, MappingFieldType } from '../../../modules/version/version-mapping-data.model';

const MAX_CUSTOM_VALUE_LENGTH = 100;

@Component({
  selector: 'ey-condition',
  templateUrl: './ey-condition.component.html',
  styleUrls: ['./ey-condition.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyConditionComponent),
      multi: true,
    },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class EyConditionComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private _sourceValues: MappingField[];
  @Input()
  set sourceValues(values: MappingField[]) {
    this._sourceValues = values;
  }

  get sourceValues(): MappingField[] {
    return this._sourceValues;
  }

  meta = CONDITION_META;

  condition = this.fb.group({
    sourceControl: [null],
    condition: [null],
    targetControl: [null],
    customValue: [null, { updateOn: 'blur' }],
    customDate: [null, [this.dateValidator()]],
  });

  conditions: Array<ConditionDropdownItem> = [];
  target: Array<MappingField> = [];

  selectedSource: MappingField = null;
  selectedCondition: { name: Conditions } = null;
  selectedTarget = null;

  customValueVisible = false;
  customDateVisible = false;
  targetVisible = true;

  destroy$ = new Subject<boolean>();

  constructor(private fb: UntypedFormBuilder) {}

  get targetMeta(): IFieldMeta {
    if (!this.selectedCondition) {
      return this.meta.target.than;
    }

    return CONDITIONS_REQUIRING_THAN_TITLE.includes(this.selectedCondition.name) ? this.meta.target.than : this.meta.target.to;
  }

  get fieldsDisabled(): boolean {
    return !!!this.selectedSource;
  }

  get customValueMeta(): IFieldMeta {
    switch (this.selectedTarget) {
      case CUSTOM_TEXT:
        return CUSTOM_VALUE_META.text;
      case CUSTOM_NUMBER:
        return CUSTOM_VALUE_META.number;
      default:
        return CUSTOM_VALUE_META.text;
    }
  }

  get customDateMeta(): IFieldMeta {
    return CUSTOM_VALUE_META.date;
  }

  get customValueType(): string {
    switch (this.selectedTarget) {
      case CUSTOM_TEXT:
        return 'text';
      case CUSTOM_NUMBER:
        return 'number';
      default:
        return null;
    }
  }

  get customValueControl(): UntypedFormControl {
    return this.condition.controls.customValue as UntypedFormControl;
  }

  onChange: (val: any) => void = () => {};
  onTouched: () => void = () => {};

  writeValue(value: Condition): void {
    if (value) {
      this.customValueVisible =
        value.target === ConditionTarget.Custom &&
        (value.targetControlType === MappingFieldType.Text || value.targetControlType === MappingFieldType.Number);

      this.customDateVisible = value.target === ConditionTarget.Custom && value.targetControlType === MappingFieldType.Date;
      const valueToPatch = {
        sourceControl: this.sourceValues.find((s) => s.id === value.sourceControl),
        condition: value.condition ? { name: value.condition } : null,
        targetControl: this.targetControlFromCondition(value),
        customValue: this.customValueVisible ? value.targetControl : null,
        customDate: this.customDateVisible ? this.toDateObj(value.targetControl) : null,
      };

      this.sourceChange(valueToPatch.sourceControl);
      this.conditionChange(valueToPatch.condition as { name: Conditions });
      this.targetChange(valueToPatch.targetControl);

      this.condition.patchValue(valueToPatch, { emitEvent: false });
    }
  }

  toDateObj(date: string): NgbDate {
    if (!date) {
      return null;
    }

    const dateParts = date.split('-');

    return new NgbDate(Number(dateParts[0]), Number(dateParts[1]), Number(dateParts[2]));
  }

  targetControlFromCondition(condition: Condition): MappingField {
    if (condition.target === ConditionTarget.Custom) {
      switch (condition.targetControlType) {
        case MappingFieldType.Number:
          return CUSTOM_NUMBER;
        case MappingFieldType.Text:
          return CUSTOM_TEXT;
        case MappingFieldType.Date:
          return CUSTOM_DATE;
      }
    }

    return this.sourceValues.find((s) => s.id === condition.targetControl);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.condition.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.customValueVisible = val.targetControl === CUSTOM_TEXT || val.targetControl === CUSTOM_NUMBER;

      this.customDateVisible = val.targetControl === CUSTOM_DATE;
      this.onChange(this.formValueToReturnObject(val));
    });

    this.customValueControl.valueChanges.subscribe((cv) => {
      if (cv.length > MAX_CUSTOM_VALUE_LENGTH && this.customValueMeta.title === CUSTOM_VALUE_META.number.title) {
        this.condition.patchValue({ customValue: cv.slice(0, MAX_CUSTOM_VALUE_LENGTH) });
      }
    });
  }

  formValueToReturnObject(val: any): Condition {
    if (!val.sourceControl) {
      return {
        condition: null,
        sourceControl: null,
        target: null,
        targetControl: null,
        targetControlType: null,
      };
    }

    if (!val.condition) {
      return {
        condition: null,
        sourceControl: val.sourceControl?.id,
        target: null,
        targetControl: null,
        targetControlType: null,
      };
    }

    return {
      condition: val.condition?.name,
      sourceControl: val.sourceControl?.id,
      target: this.customValueVisible || this.customDateVisible ? ConditionTarget.Custom : ConditionTarget.Flow,
      targetControl: this.valueToTargetControl(val),
      targetControlType: val.targetControl?.type,
    };
  }

  valueToTargetControl(val: any): string {
    if (this.customDateVisible && !val?.customDate) {
      return null;
    }

    if (this.customDateVisible) {
      const date = val.customDate;
      if (date?.year) {
        return `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`;
      } else {
        return null;
      }
    }

    if (this.customValueVisible) {
      return val.customValue;
    }

    return val.targetControl?.id;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  sourceChange(item: MappingField): void {
    if (item instanceof Array) {
      this.selectedSource = null;
    } else {
      this.selectedSource = item;
    }

    if (!item) {
      this.condition.patchValue(
        {
          condition: null,
          targetControl: null,
          customValue: null,
          customDate: null,
        },
        { emitEvent: false },
      );
    }

    switch (item?.type) {
      case MappingFieldType.Date:
      case MappingFieldType.Number:
        this.conditions = CONDITION_OPTIONS.conditionGeneric;
        break;
      case MappingFieldType.Text:
        this.conditions = manageConditionFieldsExceptions(item);
        break;
      default:
        this.conditions = [];
    }

    this.prepareTargetValues();
  }

  prepareTargetValues(): void {
    const toolType = this.selectedSource?.toolType;
    const toolValueType = this.selectedSource?.type;
    switch (this.selectedSource?.type) {
      case MappingFieldType.Text:
        this.target = this.sourceValues.filter((s) => s.type === MappingFieldType.Text);
        const newTargetValuesForTextTypeFields = manageTargetFieldsExceptions(
          toolType,
          toolValueType,
          this.target,
          this.sourceValues,
          this.selectedSource,
        );
        if (newTargetValuesForTextTypeFields && newTargetValuesForTextTypeFields.length > 0) {
          this.target = newTargetValuesForTextTypeFields;
        }
        if (this.selectedSource?.toolType === TABLE_TOOL) {
          this.target.unshift(CUSTOM_NUMBER);
        }
        this.target.unshift(CUSTOM_TEXT);
        if (toolType === ToolNames.Form && this.selectedSource.descriptor.includes(IS_SELECTED)) {
          this.target.shift();
        }
        break;
      case MappingFieldType.Date:
        this.target = this.sourceValues.filter((s) => s.type === MappingFieldType.Date);
        const newTargetValuesForDateTypeFields = manageTargetFieldsExceptions(
          toolType,
          toolValueType,
          this.target,
          this.sourceValues,
          this.selectedSource,
        );
        if (newTargetValuesForDateTypeFields && newTargetValuesForDateTypeFields.length > 0) {
          this.target = newTargetValuesForDateTypeFields;
        }
        this.target.unshift(CUSTOM_DATE);
        break;
      case MappingFieldType.Number:
        this.target = this.sourceValues.filter((s) => s.type === MappingFieldType.Number);
        const newTargetValuesForNumberTypeFields = manageTargetFieldsExceptions(
          toolType,
          toolValueType,
          this.target,
          this.sourceValues,
          this.selectedSource,
        );
        if (newTargetValuesForNumberTypeFields && newTargetValuesForNumberTypeFields.length > 0) {
          this.target = newTargetValuesForNumberTypeFields;
        }
        this.target.unshift(CUSTOM_NUMBER);
        break;
      default:
        this.target = [];
    }
  }

  conditionChange(condition: { name: Conditions }): void {
    this.selectedCondition = condition;

    if (!condition) {
      this.condition.patchValue(
        {
          targetControl: null,
          customValue: null,
          customDate: null,
        },
        { emitEvent: false },
      );
    }

    this.targetVisible = !TARGET_HIDDEN_CONDITIONS.includes(condition?.name);
  }

  targetChange(target: MappingField): void {
    this.selectedTarget = target;

    this.condition.patchValue(
      {
        customValue: null,
        customDate: null,
      },
      { emitEvent: false },
    );
  }

  dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateVal: NgbDateStruct = control.value;
      if (dateVal == null) {
        return null;
      }

      if (dateVal.day === undefined) {
        return { invalidDate: { value: control.value } };
      }

      return null;
    };
  }
}
