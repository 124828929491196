import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';
import { map, tap } from 'rxjs/operators';
import { UserPermissions } from './user-permissions.model';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService {
  private baseUrl = environment.baseUrl;
  private rawPermissions: string[] = [];

  public userPermissions = new UserPermissions();

  constructor(private http: HttpClient) {}

  private isInPermissionList(permission: string): boolean {
    return _.isEmpty(this.rawPermissions) ? false : this.rawPermissions.find((t) => t === permission) !== undefined;
  }

  private getUserPermissions(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + '/user/my-permissions');
  }

  public getRawPermissions(): Observable<string[]> {
    if (_.isEmpty(this.rawPermissions)) {
      return this.getUserPermissions().pipe(tap((p) => (this.rawPermissions = p)));
    } else {
      return of(this.rawPermissions);
    }
  }

  public getPermissions(): Observable<UserPermissions> {
    if (_.isEmpty(this.rawPermissions)) {
      return this.getUserPermissions().pipe(
        map((p) => {
          this.rawPermissions = p;
          this.resetProcessedPermissions();

          return this.userPermissions;
        }),
      );
    }

    this.resetProcessedPermissions();
    return of(this.userPermissions);
  }

  private resetProcessedPermissions() {
    this.userPermissions = new UserPermissions();

    this.userPermissions.module = this.populatePermissions(this.userPermissions.module);
    this.userPermissions.project = this.populatePermissions(this.userPermissions.project);
    this.userPermissions.other = this.populatePermissions(this.userPermissions.other);
  }

  private populatePermissions(permissionsObject: any): any {
    for (const key of Object.keys(permissionsObject)) {
      permissionsObject[key] = this.isInPermissionList(permissionsObject[key]);
    }
    return permissionsObject;
  }
}
