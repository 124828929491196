<div [ngClass]="{ show: expanded, light: light }" class="nav--holder">
  <a
    title="More Actions"
    #link
    aria-expanded="false"
    (click)="getPosition($event); $event.preventDefault()"
    aria-haspopup="true"
    class="dropdown-toggle overflow-menu-toggle dropdown-menu-end"
    data-flip="false"
    data-toggle="dropdown"
    href
    id="dropdownOverflowMenu_v"
    role="button"
  >
    <i class="icon-ico_more emp-icon"></i>
  </a>
  <div
    aria-labelledby="dropdownOverflowMenu_v"
    class="dropdown-menu overflow-menu overflow-menu--vertical"
    [ngClass]="{ show: expanded, 'dropdown-menu__fixed': !relativePositioning }"
    [ngStyle]="styles"
    #menuHolder
  >
    <ng-content select="[dropDownItems]"></ng-content>
  </div>
</div>
